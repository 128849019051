import { FormatResponseMessage } from '../Util/FormatResponseMessage';

export async function ReadSoilHealthGlossary({ jwtToken }) {
  const apiUrl = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/soilhealthglossary`;

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((result) => result);
  return FormatResponseMessage(response);
}

export async function CreateSoilHealthGlossary({ file, jwtToken }) {
  const formData = new FormData();
  formData.append('soilhealthglossary', file, file.name);

  const apiUrl = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/soilhealthglossary`;

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    body: formData,
  }).then((result) => result);
  return FormatResponseMessage(response);
}
