import React, { useState } from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { useFarmData } from './FarmData-Context';
import '../Styles/InfoBoxModal.css';

function InfoBoxModal() {
  const { markerGlobal, mapGlobal, SetMarkerClickState } = useSoilHealthContext();
  const { PostMarker } = useFarmData();
  const [markerTextInput, setMarkerTextInput] = useState('');

  return (
    <div
      className="modal fade"
      id="info-box-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="infoboxmodal"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="infoboxmodal">
              Marker Info
            </h5>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition((position) => {
                    const googleLatLng = new window.google.maps.LatLng(
                      position.coords.latitude,
                      position.coords.longitude,
                    );
                    markerGlobal.setPosition(googleLatLng);
                    mapGlobal.panTo(googleLatLng);
                  }, (err) => {
                    console.log(`error: ${err.message}`);
                  }, {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 1000000,
                  });
                } else {
                  alert('Geolocation is not supported by this browser.');
                }
              }}
            >
              Move Marker To Current location
            </button>
          </div>
          <div id="info-box-modal-body" className="modal-body">
            <textarea
              autoFocus
              className="info-box-text-area"
              maxLength="200"
              placeholder="Enter your marker text here..."
              value={markerTextInput}
              onChange={(e) => {
                setMarkerTextInput(e.target.value);
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                markerGlobal.setMap(null);
                SetMarkerClickState(false);
                setMarkerTextInput('');
              }}
            >
              Cancel
            </button>
            <a
              className="btn btn-success btn-ok"
              data-dismiss="modal"
              onClick={(e) => {
                const lat = markerGlobal.getPosition().lat();
                const lng = markerGlobal.getPosition().lng();

                PostMarker(document.getElementById('farm-select').value,
                  document.getElementById('field-select').value,
                  markerTextInput,
                  lat,
                  lng);

                markerGlobal.setMap(null);
                SetMarkerClickState(false);
                setMarkerTextInput('');
              }}
            >
              Save
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoBoxModal;
