import React from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { useFarmData } from './FarmData-Context';

function InfoBoxModal() {
  const { markerGlobal } = useSoilHealthContext();
  const { DeleteMarker } = useFarmData();

  return (
    <div
      className="modal fade"
      id="delete-marker-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="deletemarkermodal"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deletemarkermodal">
              Delete Marker
            </h5>
          </div>
          <div id="delete-marker-modal-body" className="modal-body">
            <p>Are you sure you want to delete the selected marker?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <a
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                DeleteMarker(markerGlobal.id);
                markerGlobal.setMap(null);
              }}
            >
              Delete
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoBoxModal;
