import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/SoilHealthPanel.css';
import { useAuth0 } from '../Auth/Auth0-Context';
import { useFarmData } from './FarmData-Context';
import { useUsers } from './Users-Context.js';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { RateColours } from './SoilHealthMap';
import Loader from './Loader';
import { ExtractGeoJsonProperties } from '../Util/ExtractGeoJsonProperties';

function SoilHealthPanel({ selectedAnalysis, selectedAnalysisType }) {
  const [rates, setRates] = useState({ i: 'placeholder' });
  const [initial, setInitial] = useState(true);
  const [infoWindow, setInfoWindow] = useState('');
  const [invalidEntry, setInvalidEntry] = useState('');
  const [farmFieldChanged, setFarmFieldChanged] = useState(false);
  const [inputTriggered, setInputTriggered] = useState(false);
  const { user, role } = useAuth0();
  const { GetUsers, Users } = useUsers();
  const {
    mapGlobal,
    SetFarmState,
    SetFieldState,
    SetAnalysisState,
    SetAnalysisTypeState,
    SetYearState,
    selectedField,
    selectedYear,
  } = useSoilHealthContext();
  const {
    Farms,
    Fields,
    SetCurrentUserState,
    SetCurrentFieldState,
    SetCurrentFarmState,
    SetCurrentSeasonState,
    SetSoilHealthReportsState,
    SetTriggerSoilHealthReportsUpdateState,
    UpdateFarmFieldData,
    UpdateAnalysisData,
    FieldAnalysis,
    CurrentUser,
    SetFieldsState,
    UpdateFieldAnalysisState,
    DownloadFiles,
    isLoading,
    GCIStations,
    GetMarkers,
    GetMeasures,
    GetGCIStations,
    GetGCIStationLinks,
  } = useFarmData();
  var d = new Date();

  let propertiesListArray = [
    {
      properties: '',
    },
  ];
  const ratesListArray = [];

  useEffect(() => {
    if (Farms.length > 0 && Fields.length > 0 && initial) {
      GetMarkers(
        document.getElementById('farm-select').value,
        document.getElementById('field-select').value,
      );
      setInitial(false);
    }

    if (GCIStations.length === 0) {
      GetGCIStations();
    }

    for (let i = 0; i < GCIStations.length; i++) {
      if (window.google !== undefined && mapGlobal.length !== 0) {
        const myLatLng = {
          lat: parseFloat(GCIStations[i].latitude),
          lng: parseFloat(GCIStations[i].longitude),
        };

        const icon = {
          url: 'Images/Weather-Station-Icon.png',
          scaledSize: new window.google.maps.Size(30, 30), // scaled size
          origin: new window.google.maps.Point(0, 0), // origin
          anchor: new window.google.maps.Point(15, 15), // anchor
        };

        const marker = new window.google.maps.Marker({
          position: myLatLng,
          map: mapGlobal,
          title: GCIStations[i].station_Name,
          icon,
        });

        window.google.maps.event.addListener(marker, 'click', function () {
          // modal trigger logic here
          GetGCIStationLinks(this.title);
          document.getElementById('gci-graph-modal-trigger').click();
          document.getElementById('station-link-select').selectedIndex = '0';
        });
      }
    }

    const farmOptions = document.getElementById('farm-select').options;

    if (Fields[0] !== undefined) {
      for (let i = 0; i < farmOptions.length; i++) {
        if (farmOptions[i].value === Fields[0].farmkey) {
          farmOptions[i].selected = true;
        }
      }
    }
  }, [GetMarkers, GetGCIStations, GCIStations, mapGlobal]);

  if (
    FieldAnalysis.length > 0
    && document.getElementById('analysis-select') !== null
  ) {
    const selectedAnalysis = document.getElementById('analysis-select').value;

    for (let i = 0; i < FieldAnalysis.length; i++) {
      if (
        FieldAnalysis[i].name === selectedAnalysis
        && FieldAnalysis[i].year === selectedYear
      ) {
        propertiesListArray = ExtractGeoJsonProperties(FieldAnalysis[i]);
      }
    }

    for (let i = 0; i < propertiesListArray.length; i++) {
      for (let j = 0; j < propertiesListArray[i].properties.length; j++) {
        if (
          propertiesListArray[i].properties[j].name === selectedAnalysisType
          && propertiesListArray[i].properties[j].analysis === selectedAnalysis
        ) {
          ratesListArray[i] = {
            rate: i,
            featureId: propertiesListArray[i].featureId,
            value: propertiesListArray[i].properties[j].value,
            type: propertiesListArray[i].properties[j].type,
            unitofmeasure: propertiesListArray[i].properties[j].unitofmeasure,
          };
        }
      }
    }
  }

  const gciStationList = GCIStations.length > 0
    && GCIStations.map((item, i) => {
      return (
        <option key={i} value={item.station_Name}>
          {item.station_Name}
        </option>
      );
    }, this);

  const selectedUser = Users.length > 0
    && Users.map((item, i) => {
      if (CurrentUser === item.user_id) {
        return (
          <input
            key={i}
            type="text"
            className="form-control"
            aria-describedby="button-addon"
            value={item.name}
            readOnly
          />
        );
      }
    }, this);

  let fieldHeactares = 0;
  let totalSumRatePerZone = 0;

  if (document.getElementById('field-select')) {
    for (let i = 0; i < FieldAnalysis.length; i++) {
      if (
        FieldAnalysis[i].name === selectedAnalysis
        && FieldAnalysis[i].year === selectedYear
        && FieldAnalysis[i].fieldid === selectedField
        && ratesListArray.length > 0
      ) {
        const geoJsonObject = JSON.parse(FieldAnalysis[i].geojson);

        for (let j = 0; j < ratesListArray.length; j++) {
          for (let k = 0; k < geoJsonObject.features.length; k++) {
            if (
              ratesListArray[j].featureId
              === geoJsonObject.features[k].properties.Feature_Id
            ) {
              const fieldBoundary = geoJsonObject.features[k].geometry;
              const fieldPolygonArray = [];

              if (fieldBoundary !== null) {
                for (let m = 0; m < fieldBoundary.coordinates.length; m++) {
                  const fieldCoords = [];
                  for (
                    let l = 0;
                    l < fieldBoundary.coordinates[m].length;
                    l++
                  ) {
                    const latLong = fieldBoundary.coordinates[m][l];
                    fieldCoords.push({ lat: latLong[1], lng: latLong[0] });
                  }
                  const fieldPolygon = new window.google.maps.Polygon({
                    paths: fieldCoords,
                  });

                  fieldPolygonArray.push(fieldPolygon);
                }

                if (window.google.maps.geometry) {
                  let fieldHeactaresList = [];

                  for (let l = 0; l < fieldPolygonArray.length; l++) {
                    const fieldArea = window.google.maps.geometry.spherical.computeArea(
                      fieldPolygonArray[l].getPath(),
                    ) / 10000;

                    fieldHeactares = fieldArea.toFixed(2);

                    fieldHeactaresList.push(parseFloat(fieldHeactares));
                  }

                  fieldHeactaresList = fieldHeactaresList.sort((a, b) => {
                    return b - a;
                  });

                  let largestValue = fieldHeactaresList[0];

                  for (let l = 1; l < fieldHeactaresList.length; l++) {
                    largestValue -= fieldHeactaresList[l];
                  }

                  const totalZoneRate = parseFloat(ratesListArray[j].value)
                    * parseFloat(largestValue);
                  totalSumRatePerZone += totalZoneRate;
                }
              }
            }
          }
        }
      }
    }

    for (let i = 0; i < Fields.length; i++) {
      if (document.getElementById('field-select').value === Fields[i].key) {
        const fieldBoundary = JSON.parse(Fields[i].fieldCoords);
        const fieldCoords = [];

        if (fieldBoundary !== null) {
          for (let j = 0; j < fieldBoundary.coordinates[0].length; j++) {
            const latLong = fieldBoundary.coordinates[0][j];
            fieldCoords.push({ lat: latLong[1], lng: latLong[0] });
          }
          const fieldPolygon = new window.google.maps.Polygon({
            paths: fieldCoords,
          });

          if (window.google.maps.geometry) {
            const fieldArea = window.google.maps.geometry.spherical.computeArea(
              fieldPolygon.getPath(),
            ) / 10000;

            fieldHeactares = fieldArea.toFixed(2);
          }
        }
      }
    }
  }

  const farmList = Farms.length > 0
    && Farms.map((item, i) => {
      return (
        <option key={i} value={item.key}>
          {item.name}
        </option>
      );
    }, this);

  const fieldList = Fields.length > 0
    && Fields.map((item, i) => {
      return (
        <option key={i} id={item.key} value={item.key}>
          {item.name}
        </option>
      );
    }, this);

  const analysisList = FieldAnalysis.length > 0
    && FieldAnalysis.map((item, i) => {
      if (item.year === selectedYear) {
        return (
          <option key={i} value={item.name}>
            {item.name}
          </option>
        );
      }
    }, this);

  const analysisTypeList = propertiesListArray[0].properties.length > 0
    && propertiesListArray[0].properties.map((item, i) => {
      return (
        <option key={i} value={item.name}>
          {item.mapping}
        </option>
      );
    }, this);

  const HandleRatesMouseOver = async (id) => {
    mapGlobal.data.forEach((feature) => {
      const featureId = feature.getProperty('Feature_Id');

      if (featureId == id) {
        const bounds = new window.google.maps.LatLngBounds();
        const features = feature.getGeometry().getArray();

        for (let i = 0; i < features.length; i++) {
          for (let j = 0; j < features[i].getLength(); j++) {
            bounds.extend(features[i].getAt(j));
          }
        }

        try {
          infoWindow.close();
        } catch (error) {}

        const infowindow = new window.google.maps.InfoWindow();
        infowindow.setContent(
          feature.getProperty(selectedAnalysisType).toString(),
        );
        infowindow.setPosition(bounds.getCenter());
        infowindow.open(mapGlobal);

        setInfoWindow(infowindow);
        mapGlobal.data.overrideStyle(feature, { fillOpacity: 1 });
      }
    });
  };

  const HandleRatesMouseLeave = async (id) => {
    mapGlobal.data.forEach((feature) => {
      const featureId = feature.getProperty('Feature_Id');

      if (featureId == id) {
        mapGlobal.data.overrideStyle(feature, { fillOpacity: 0.75 });
        infoWindow.close();
      }
    });
  };

  const HandleRatesInput = async (id) => {
    mapGlobal.data.forEach((feature) => {
      const featureId = feature.getProperty('Feature_Id');

      if (featureId == id) {
        const selectedField = document.getElementById('field-select').value;
        const selectedAnalysis = document.getElementById('analysis-select').value;
        const selectedYear = document.getElementById('year-select').value;
        const selectedAnalysisType = document.getElementById(
          'analysis-type-select',
        ).value;

        feature.setProperty(
          selectedAnalysisType,
          document.getElementById(id).value,
        );

        for (let i = 0; i < FieldAnalysis.length; i++) {
          if (
            FieldAnalysis[i].name === selectedAnalysis
            && FieldAnalysis[i].year === selectedYear
            && FieldAnalysis[i].fieldid === selectedField
          ) {
            const geoJsonObject = JSON.parse(FieldAnalysis[i].geojson);

            for (let j = 0; j < geoJsonObject.features.length; j++) {
              if (geoJsonObject.features[j].properties.Feature_Id == id) {
                geoJsonObject.features[j].properties[selectedAnalysisType] = parseFloat(document.getElementById(id).value);
              }
            }

            const geoJsonString = JSON.stringify(geoJsonObject);
            FieldAnalysis[i].geojson = geoJsonString;
            UpdateFieldAnalysisState(FieldAnalysis);
          }
        }

        const bounds = new window.google.maps.LatLngBounds();
        const features = feature.getGeometry().getArray();

        for (let i = 0; i < features.length; i++) {
          for (let j = 0; j < features[i].getLength(); j++) {
            bounds.extend(features[i].getAt(j));
          }
        }

        infoWindow.close();

        const infowindow = new window.google.maps.InfoWindow();
        infowindow.setContent(
          feature.getProperty(selectedAnalysisType).toString(),
        );
        infowindow.setPosition(bounds.getCenter());
        infowindow.open(mapGlobal);

        setInfoWindow(infowindow);
      }
    });

    mapGlobal.data.setStyle((feature) => {
      const selectedAnalysisType = document.getElementById(
        'analysis-type-select',
      ).value;

      const featureProperty = feature.getProperty(selectedAnalysisType);

      return {
        fillColor: RateColours(featureProperty, mapGlobal, ratesListArray),
        strokeWeight: 1,
        fillOpacity: 0.75,
      };
    });
  };

  const HandleRatesBlur = async (id, value) => {
    mapGlobal.data.forEach((feature) => {
      const featureId = feature.getProperty('Feature_Id');

      if (featureId == id) {
        const selectedField = document.getElementById('field-select').value;
        const selectedAnalysis = document.getElementById('analysis-select').value;
        const selectedYear = document.getElementById('year-select').value;
        const selectedAnalysisType = document.getElementById(
          'analysis-type-select',
        ).value;

        feature.setProperty(selectedAnalysisType, value);

        for (let i = 0; i < FieldAnalysis.length; i++) {
          if (
            FieldAnalysis[i].name === selectedAnalysis
            && FieldAnalysis[i].year === selectedYear
            && FieldAnalysis[i].fieldid === selectedField
          ) {
            const geoJsonObject = JSON.parse(FieldAnalysis[i].geojson);

            for (let j = 0; j < geoJsonObject.features.length; j++) {
              if (geoJsonObject.features[j].properties.Feature_Id == id) {
                geoJsonObject.features[j].properties[selectedAnalysisType] = value;
              }
            }

            const geoJsonString = JSON.stringify(geoJsonObject);
            FieldAnalysis[i].geojson = geoJsonString;
            UpdateFieldAnalysisState(FieldAnalysis);
          }
        }

        const bounds = new window.google.maps.LatLngBounds();
        const features = feature.getGeometry().getArray();

        for (let i = 0; i < features.length; i++) {
          for (let j = 0; j < features[i].getLength(); j++) {
            bounds.extend(features[i].getAt(j));
          }
        }

        infoWindow.close();

        const infowindow = new window.google.maps.InfoWindow();
        infowindow.setContent(
          feature.getProperty(selectedAnalysisType).toString(),
        );
        infowindow.setPosition(bounds.getCenter());
        infowindow.open(mapGlobal);

        setInfoWindow(infowindow);
      }
    });

    mapGlobal.data.setStyle((feature) => {
      const selectedAnalysisType = document.getElementById(
        'analysis-type-select',
      ).value;

      const featureProperty = feature.getProperty(selectedAnalysisType);

      return {
        fillColor: RateColours(featureProperty, mapGlobal, ratesListArray),
        strokeWeight: 1,
        fillOpacity: 0.75,
      };
    });
  };

  const ratesList = ratesListArray.length > 0 && ratesListArray.length < 100
    ? ratesListArray.map((item, i) => {
      return (
        <div key={i} className="input-group mb-3 soilhealth-filters">
          <div className="rates-input-validation">
            <i>{invalidEntry[i]}</i>
          </div>
          <div className="input-group-prepend">
            <span
              className="input-group-text zone-name"
              id="basic-addon3"
              style={{
                backgroundColor: RateColours(
                  initial
                    ? item.value
                    : rates[i] !== undefined
                      ? rates[i]
                      : document.getElementById(item.featureId).value,
                  mapGlobal,
                  ratesListArray,
                ),
              }}
            >
              Zone {item.rate}
            </span>
          </div>
          {item.type === 'Non-Editable' ? (
            <input
              type="text"
              id={item.featureId}
              onMouseOver={(e) => {
                HandleRatesMouseOver(e.target.id);
              }}
              onMouseLeave={(e) => {
                HandleRatesMouseLeave(e.target.id);
              }}
              className="form-control"
              aria-describedby="basic-addon3"
              autoComplete="off"
              value={
                    initial
                      ? item.value
                      : rates[i] !== undefined
                        ? rates[i]
                        : document.getElementById(item.featureId).value
                  }
              readOnly
            />
          ) : (
            <input
              type="text"
              id={item.featureId}
              onMouseOver={(e) => {
                HandleRatesMouseOver(e.target.id);
              }}
              onMouseLeave={(e) => {
                HandleRatesMouseLeave(e.target.id);
              }}
              onChange={(e) => {
                const patt = /^\d+(\.\d{1,2})?\.?$/;
                const result = patt.test(e.target.value);
                if (result || e.target.value === '') {
                  // setInitial(false);
                  setRates({ [i]: e.target.value });
                  setInvalidEntry('');
                } else {
                  setInvalidEntry({
                    [i]: 'The value must be a number to max 2 decimal places.',
                  });
                }
              }}
              onInput={(e) => {
                const patt = /^\d+(\.\d{1,2})?\.?$/;
                const result = patt.test(e.target.value);
                if (result || e.target.value === '') {
                  setInputTriggered(true);
                  HandleRatesInput(e.target.id);
                  setInvalidEntry('');
                } else {
                  setInvalidEntry({
                    [i]: 'The value must be a number to max 2 decimal places.',
                  });
                }
              }}
              onBlur={(e) => {
                if (!inputTriggered) {
                  const patt = /^\d+(\.\d{1,2})?$/;
                  const result = patt.test(e.target.value);
                  if (!result && e.target.value !== '') {
                    const removedDot = e.target.value.substring(
                      0,
                      e.target.value.length - 1,
                    );
                    HandleRatesBlur(e.target.id, removedDot);
                    setRates({ [i]: removedDot });
                  } else if (e.target.value === '') {
                    HandleRatesBlur(e.target.id, '0');
                    setRates({ [i]: '0' });
                  }

                  setInvalidEntry('');
                } else {
                  document.getElementById(e.target.id).focus();
                  setInputTriggered(false);
                }
              }}
              className="form-control"
              aria-describedby="basic-addon3"
              autoComplete="off"
              value={
                    initial
                      ? item.value
                      : rates[i] !== undefined
                        ? rates[i]
                        : document.getElementById(item.featureId).value
                  }
            />
          )}
        </div>
      );
    }, this)
    : ratesListArray.length > 99
      ? 'Note:- Rate lists only populate with zone maps that contain less than 100 zones. '
        + 'Values can be viewed from the rate key and by hovering the mouse over the map.'
      : '';

  const years = [];
  var d = new Date();
  const currentYear = d.getFullYear().toString();
  let currentYearExists = false;

  for (let i = 0; i < FieldAnalysis.length; i++) {
    let yearExists = false;

    if (FieldAnalysis[i].year === currentYear) {
      currentYearExists = true;
    }

    for (let j = 0; j < years.length; j++) {
      if (FieldAnalysis[i].year === years[j]) {
        yearExists = true;
      }
    }
    if (!yearExists) {
      years.push(FieldAnalysis[i].year);
    }
  }

  years.sort((a, b) => {
    return b - a;
  });

  if (!currentYearExists && selectedYear === currentYear && years.length > 0) {
    SetYearState(years[0]);
  }

  if (farmFieldChanged && years.length > 0 && selectedYear !== years[0]) {
    SetYearState(years[0]);
    setFarmFieldChanged(false);
  }

  const yearList = years.length > 0
    && years.map((item, i) => {
      const nextYear = parseInt(item) + 1;

      return (
        <option key={i} value={item}>
          {`${item}/${nextYear.toString()}`}
        </option>
      );
    }, this);

  const HandleGCIStationChange = async (e) => {
    for (let i = 0; i < GCIStations.length; i++) {
      if (GCIStations[i].station_Name == e.target.value) {
        const myLatLng = {
          lat: parseFloat(GCIStations[i].latitude),
          lng: parseFloat(GCIStations[i].longitude),
        };
        mapGlobal.setCenter(myLatLng);
        mapGlobal.setZoom(16);
      }
    }
  };

  const HandleFarmChange = async () => {
    setFarmFieldChanged(true);
    SetFieldsState();
    UpdateFarmFieldData(
      document.getElementById('farm-select').value,
      CurrentUser,
    );

    setInitial(true);

    SetFarmState();
  };

  const HandleFieldChange = async (e) => {
    setFarmFieldChanged(true);
    const d = new Date();
    let yearSelected = d.getFullYear().toString();

    if (
      document.getElementById('year-select') !== null
      && document.getElementById('year-select').value.length > 0
    ) {
      yearSelected = document.getElementById('year-select').value;
    }

    UpdateAnalysisData(
      document.getElementById('farm-select').value,
      document.getElementById('field-select').value,
      yearSelected,
    );

    GetMeasures(
      document.getElementById('farm-select').value,
      document.getElementById('field-select').value,
    );

    setInitial(true);

    SetFieldState(e.target.value);
  };

  const HandleYearChange = async (e) => {
    setFarmFieldChanged(false);
    UpdateAnalysisData(
      document.getElementById('farm-select').value,
      document.getElementById('field-select').value,
      document.getElementById('year-select').value,
    );

    SetYearState(e.target.value);
  };

  const HandleAnalysisChange = async (e) => {
    setFarmFieldChanged(false);
    setInvalidEntry('');
    SetFieldState(document.getElementById('field-select').value);
    SetAnalysisState(e.target.value);
  };

  const HandleAnalysisTypeChange = async (e) => {
    setFarmFieldChanged(false);
    setInitial(true);
    setRates({ i: 'placeholder' });
    SetAnalysisTypeState(e.target.value);
  };

  const HandleDownloadButtonClick = async (e) => {
    const type = e.target.id;
    if (FieldAnalysis.length > 0) {
      const selectedAnalysis = document.getElementById('analysis-select').value;

      for (let i = 0; i < FieldAnalysis.length; i++) {
        if (FieldAnalysis[i].name === selectedAnalysis) {
          DownloadFiles(
            FieldAnalysis[i].geojson,
            document.getElementById('farm-select').value,
            document.getElementById('field-select').value,
            document.getElementById('year-select').value,
            document.getElementById('analysis-select').value,
            type,
          );
        }
      }
    }
  };

  return (
    <>
      <div className="soilhealthpanel">
        {isLoading ? <Loader /> : ''}
        <form className="soilhealth-form">
          {GCIStations.length > 0 ? (
            <div className="gcistationlistselect">
              <label>
                <b className="select-title">
                  <i>GCI Stations</i>
                </b>
              </label>
              <br />
              <select
                id="GCI-select"
                className="soilhealth-filters custom-select"
                onChange={(e) => {
                  HandleGCIStationChange(e);
                }}
              >
                <option value="">Select a GCI Station...</option>
                {gciStationList}
              </select>
            </div>
          ) : (
            ''
          )}
          <label>
            <b className="select-title">User</b>
          </label>
          <br />
          <div id="username" className="form-group input-with-button">
            <div className="input-group mb-3">
              {CurrentUser != null ? (
                selectedUser
              ) : (
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="button-addon"
                  value={user.name}
                  readOnly
                />
              )}
              {role !== 'Customer' ? (
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon"
                    onClick={() => {
                      HandleFarmChange();
                      setFarmFieldChanged(true);
                      GetUsers('', '', 0);
                      document.getElementById('user-search-trigger').click();
                    }}
                  >
                    Search
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <label>
            <b className="select-title">Farm</b>
          </label>
          <br />
          <div className="form-group input-with-button">
            <div className="input-group mb-3">
              <select
                id="farm-select"
                className="soilhealth-filters custom-select"
                onChange={HandleFarmChange}
              >
                {farmList}
              </select>
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon"
                  onClick={() => {
                    document.getElementById('add-farm-trigger').click();
                  }}
                >
                  New
                </button>
                {role === 'Admin' ? (
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon"
                    onClick={() => {}}
                    data-target="#delete-farm"
                    data-toggle="modal"
                  >
                    Delete
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <label>
            <b className="select-title">Field</b>
          </label>
          <br />
          <div className="form-group input-with-button">
            <div className="input-group mb-3">
              <select
                id="field-select"
                className="soilhealth-filters custom-select"
                onChange={(e) => {
                  HandleFieldChange(e);
                }}
              >
                <option value="all fields">All Fields</option>
                {fieldList}
              </select>
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="new-field-button"
                  onClick={() => {
                    document.getElementById('add-field-trigger').click();
                  }}
                >
                  New
                </button>
                {role === 'Admin' && selectedField !== 'all fields' ? (
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon"
                    onClick={(e) => {}}
                    data-target="#delete-field"
                    data-toggle="modal"
                  >
                    Delete
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {selectedField === 'all fields' ? (
            ''
          ) : (
            <>
              {' '}
              <label>
                <b className="select-title">Season</b>
              </label>
              <br />
              <select
                id="year-select"
                className="soilhealth-filters custom-select"
                onChange={(e) => {
                  HandleYearChange(e);
                }}
              >
                {yearList}
              </select>
              <br />
              <label>
                <span className="select-title">
                  <b>Data type </b>
                  <span
                    data-toggle="tooltip"
                    data-placement="right"
                    title="e.g. seed rate / nutrient / soil type"
                  >
                    <i className="infobox-help">
                      (<u>what's this?</u>)
                    </i>
                  </span>
                </span>
              </label>
              <br />
              <select
                id="analysis-select"
                className="soilhealth-filters custom-select"
                value={selectedAnalysis}
                onChange={(e) => {
                  HandleAnalysisChange(e);
                }}
                onMouseDown={(e) => {
                  let fieldCoordsEmpty = false;

                  for (let i = 0; i < Fields.length; i++) {
                    if (
                      Fields[i].key
                      === document.getElementById('field-select').value
                    ) {
                      if (
                        Fields[i].fieldCoords === null
                        || Fields[i].fieldCoords.length < 5
                      ) {
                        document
                          .getElementById('field-boundary-detection-trigger')
                          .click();

                        fieldCoordsEmpty = true;
                        e.preventDefault();
                      }
                    }
                  }
                }}
                readOnly
              >
                {analysisList ? <option value="">Select Analysis</option> : ''}
                {analysisList}
              </select>
              <br />
              <label>
                <span className="select-title">
                  <b>Map layer </b>
                  <span
                    data-toggle="tooltip"
                    data-placement="right"
                    title="e.g. Big Field VR Seed WW 2021"
                  >
                    <i className="infobox-help">
                      (<u>what's this?</u>)
                    </i>
                  </span>
                </span>
              </label>
              <br />
              <select
                id="analysis-type-select"
                className="soilhealth-filters custom-select"
                onChange={(e) => {
                  HandleAnalysisTypeChange(e);
                }}
                onMouseDown={(e) => {
                  let fieldCoordsEmpty = false;

                  for (let i = 0; i < Fields.length; i++) {
                    if (
                      Fields[i].key
                      === document.getElementById('field-select').value
                    ) {
                      if (
                        Fields[i].fieldCoords === null
                        || Fields[i].fieldCoords.length < 5
                      ) {
                        document
                          .getElementById('field-boundary-detection-trigger')
                          .click();

                        fieldCoordsEmpty = true;
                        e.preventDefault();
                      }
                    }
                  }
                }}
                value={selectedAnalysisType}
              >
                {analysisTypeList ? (
                  <option value="">Select Analysis Type</option>
                ) : (
                  ''
                )}
                {analysisTypeList}
              </select>
              <br />
            </>
          )}

          <label htmlFor="basic-url">
            {ratesListArray.length > 0 && ratesListArray.length < 100 ? (
              <div>
                <b>Rates ({ratesListArray[0].unitofmeasure})</b> <br />
                <b>Total (Zone Rate * Zone Area Totalled):</b>{' '}
                <i>{totalSumRatePerZone.toFixed(2)}</i>
              </div>
            ) : (
              ''
            )}
          </label>
          {ratesList}
        </form>
        {ratesListArray.length > 0 ? (
          <div id="download-buttons">
            <button
              id="Shapefiles"
              className="download-zip-button"
              type="button"
              onClick={(e) => {
                HandleDownloadButtonClick(e);
              }}
            >
              Download Files
            </button>
            <Link to="/soilhealth">
              <button
                id="Reports"
                className="download-zip-button"
                type="button"
                onClick={() => {
                  SetCurrentFieldState('all fields');
                  SetCurrentFarmState(null);
                  SetCurrentSeasonState(new Date().getFullYear());
                  SetSoilHealthReportsState([]);
                  SetCurrentUserState();
                  UpdateFarmFieldData();
                  SetTriggerSoilHealthReportsUpdateState(true);
                }}
              >
                View Reports
              </button>
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
      <a
        id="field-boundary-detection-trigger"
        data-target="#field-boundary-detection"
        data-toggle="modal"
      />
      <a
        id="save-field-boundary-trigger"
        data-target="#save-field-boundary"
        data-toggle="modal"
      />
      <a
        id="user-search-trigger"
        data-target="#user-search"
        data-toggle="modal"
      />
      <a
        id="add-farm-trigger"
        data-target="#add-farm"
        data-toggle="modal"
      />
      <a
        id="add-field-trigger"
        data-target="#add-field"
        data-toggle="modal"
      />
      <a
        id="gci-graph-modal-trigger"
        data-target="#gci-graph-modal"
        data-toggle="modal"
      />
      <a
        id="select-measure-modal-trigger"
        data-target="#select-measure-modal"
        data-toggle="modal"
      />
      <a
        id="save-measure-modal-trigger"
        data-target="#save-measure-modal"
        data-toggle="modal"
      />
      <a
        id="save-zones-trigger"
        data-target="#save-zones-modal"
        data-toggle="modal"
      />
      <a
        id="delete-measure-modal-trigger"
        data-target="#delete-measure-modal"
        data-toggle="modal"
      />
    </>
  );
}

export default SoilHealthPanel;
