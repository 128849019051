import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { startCase } from 'lodash';
import { useFarmData } from '../Components/FarmData-Context';
import { useAuth0 } from '../Auth/Auth0-Context';
import ReportBody from '../Components/Common/ReportBody';
import { GenerateReportsList } from '../Util/ExtractReports';
import { findSoilHealthReportSection } from '../Util/FindSoilHealthReportSection';

function DeleteSoilHealthReportsModal({ sectionTitle, showModalCallback, showModal }) {
  const [reportList, setReportList] = useState([]);
  const { user } = useAuth0();
  const {
    SoilHealthReports,
    CurrentFarm,
    CurrentField,
    CurrentUser,
  } = useFarmData();

  const handleClose = () => showModalCallback();

  useEffect(async () => {
    const farm = CurrentFarm;
    const field = CurrentField !== 'all fields' ? CurrentField : null;
    const selectedUser = user !== CurrentUser ? CurrentUser : null;

    const updatedReportsList = findSoilHealthReportSection({
      reports: SoilHealthReports, section: sectionTitle, farm, field, selectedUser,
    });

    if (updatedReportsList) {
      setReportList(updatedReportsList.reportsList);
    }
  }, [SoilHealthReports]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete{' '}{startCase(sectionTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReportBody reportList={reportList} sectionTitle={sectionTitle} showModalCallback={showModalCallback} deleteReportBody />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteSoilHealthReportsModal;
