import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Pagination from 'react-bootstrap/Pagination';
import { useUsers } from '../Components/Users-Context.js';
import { useFarmData } from '../Components/FarmData-Context';
import '../Styles/Modals/SelectUserModal.css';

function SelectUserModal() {
  const [accountName, setAccountName] = useState(null);
  const [accountEmail, setAccountEmail] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const { Users = [], GetUsers } = useUsers();
  const {
    UpdateFarmFieldData,
    SetSelectUserModalState,
    SelectUserModal,
    SetSoilHealthReportsState,
    SetCurrentFieldState,
    SetCurrentFarmState,
    SetTriggerSoilHealthReportsUpdateState,
  } = useFarmData();

  useEffect(() => {
    GetUsers(null, null, 0);
  }, []);

  const usersTableRows = Users.map(
    (
      {
        user_id,
        glgmnumber,
        ifsnumber,
        name,
        email,
        farmname,
        tradingname,
        phonenumber,
        mobilenumber,
        county,
        postcode,
      },
      i,
    ) => (
      <tr
        key={i}
        id={user_id}
        onClick={(e) => {
          SetSoilHealthReportsState([]);
          UpdateFarmFieldData(null, e.currentTarget.id);
          SetSelectUserModalState(false);
          SetCurrentFieldState('all fields');
          SetCurrentFarmState(null);
          SetTriggerSoilHealthReportsUpdateState(true);
        }}
      >
        <td>{glgmnumber.length > 0 ? 'true' : 'false'}</td>
        <td>{ifsnumber}</td>
        <td>{name}</td>
        <td>{email}</td>
        <td>{farmname}</td>
        <td>{tradingname}</td>
        <td>{phonenumber}</td>
        <td>{mobilenumber}</td>
        <td>{county}</td>
        <td>{postcode}</td>
      </tr>
    ),
  );

  const handleAccountNameInput = ({ target: { value } }) => {
    setAccountName(value);
    GetUsers(value, accountEmail, pageNumber);
  };

  const handleAccountEmailInput = ({ target: { value } }) => {
    setAccountEmail(value);
    GetUsers(accountName, value, pageNumber);
  };

  const handlePaginationItemClick = (pageNumber, movePageNumber) => {
    setPageNumber(pageNumber + movePageNumber);
    GetUsers(accountName, accountEmail, pageNumber + movePageNumber);
  };

  const handleClose = () => SetSelectUserModalState(false);

  return (
    <Modal
      show={SelectUserModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable
      size="xl"
      centered
    >
      <Modal.Header>
        <Modal.Title>Select User</Modal.Title>
        <div className="inputheadermodaldiv">
          <Form>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Account Name"
                onInput={handleAccountNameInput}
              />
              <FormControl
                placeholder="Account Email"
                onInput={handleAccountEmailInput}
              />
            </InputGroup>
          </Form>
        </div>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-striped admin-data-table">
          <thead>
            <tr>
              <th>GLGM Linked</th>
              <th>IFS No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Farm Name</th>
              <th>Trading Name</th>
              <th>Phone No</th>
              <th>Mobile No</th>
              <th>County</th>
              <th>Postcode</th>
            </tr>
          </thead>
          <tbody>{usersTableRows}</tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <div className="paginationmodalfooterdiv">
          <Pagination className="justify-content-center">
            <Pagination.Prev
              onClick={() => handlePaginationItemClick(pageNumber, -1)}
              disabled={pageNumber === 0}
            />
            <Pagination.Item
              onClick={() => handlePaginationItemClick(pageNumber, -1)}
              disabled={pageNumber === 0}
            >
              {pageNumber}
            </Pagination.Item>
            <Pagination.Item active>{pageNumber + 1} </Pagination.Item>
            <Pagination.Item
              onClick={() => handlePaginationItemClick(pageNumber, +1)}
              disabled={Users.length === 0}
            >
              {pageNumber + 2}
            </Pagination.Item>
            <Pagination.Next
              onClick={() => handlePaginationItemClick(pageNumber, +1)}
              disabled={Users.length === 0}
            />
          </Pagination>
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectUserModal;
