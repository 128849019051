import React, {
  useEffect, useState, createContext, useContext,
} from 'react';
import '../Styles/SoilHealth.css';
import SoilHealthMap from '../Components/SoilHealthMap';
import SoilHealthPanel from '../Components/SoilHealthPanel';
import { useFarmData } from '../Components/FarmData-Context';
import { useUsers } from '../Components/Users-Context.js';
import SoilHealthPanelUsersModel from '../Components/SoilHealthPanelUsersModel';
import { useAuth0 } from '../Auth/Auth0-Context';
import AddFarmModal from '../Components/AddFarmModal';
import AddFieldModal from '../Components/AddFieldModal';
import GCIGraphModal from '../Components/GCIGraphModal';
import InfoBoxModal from '../Components/InfoBoxModal';
import DeleteMarkerModal from '../Components/DeleteMarkerModal';
import NoBoundaryDetectedModal from '../Components/NoBoundaryDetectedModal';
import SaveFieldBoundary from '../Components/SaveFieldBoundary';
import DeleteFieldModal from '../Components/DeleteFieldModal';
import DeleteFarmModal from '../Components/DeleteFarmModal';
import LineIntersectsMessageModal from '../Components/LineIntersectsMessageModal';
import SelectMeasureModal from '../Components/SelectMeasureModal';
import SaveMeasureModal from '../Components/SaveMeasureModal';
import SaveZonesModal from '../Components/SaveZonesModal';
import DeleteMeasureModal from '../Components/DeleteMeasureModal';
import listIconImage from '../Images/listicon.svg';
import mapIconImage from '../Images/mapicon.svg';

export const SoilHealthContext = createContext();
export const useSoilHealthContext = () => useContext(SoilHealthContext);

function SoilHealth() {
  const { role, SetRoleState } = useAuth0();
  const { Fields } = useFarmData();
  const [measureClick, setMeasureClick] = useState(false);
  const [editBoundaryClick, setEditBoundaryClick] = useState(false);
  const [createZonesClick, setCreateZonesClick] = useState(false);
  const [markerClick, setMarkerClick] = useState(false);
  const [trackLocationClick, setTrackLocationClick] = useState(false);
  const [currentMeasurements, setCurrentMeasurements] = useState({});
  const [measureGlobal, setMeasureGlobal] = useState('');
  const [selectedField, setSelectedField] = useState('all fields');
  const [selectedAnalysis, setSelectedAnalysis] = useState('');
  const [selectedAnalysisType, setSelectedAnalysisType] = useState('');
  const [selectedYear, setSelectedYear] = useState(null);
  const [mapGlobal, setMapGlobal] = useState('');
  const [fieldPolygonGlobal, setFieldPolygonGlobal] = useState('');
  const [markerGlobal, setMarkerGlobal] = useState('');
  const [polygonZonesGlobal, setPolygonZonesGlobal] = useState([]);
  const [fieldBoundaryDrawingManager, setFieldBoundaryDrawingManager] = useState('');
  const [rateKeyGlobal, setRateKeyGlobal] = useState('');
  const { Users } = useUsers();
  const [infoWindowGlobal, setInfoWindowGlobal] = useState('');

  useEffect(() => {
    if (selectedYear === null) {
      const date = new Date();
      const currentYear = date.getFullYear().toString();
      setSelectedYear(currentYear);
    }

    window.addEventListener(
      'resize',
      () => {
        let windowWidth = '';

        if (window.navigator.standalone) {
          windowWidth = window.outerWidth;
        } else {
          windowWidth = window.innerWidth;
        }

        if (
          windowWidth > 550
          && windowWidth < 1200
          && window.location.pathname === '/precisionmapping'
        ) {
          document.getElementsByClassName('soilhealthpanel')[0].style.width = '35%';

          document.getElementById('maptoolboxdiv').style.display = 'block';
          document.getElementById('info-box').style.display = 'block';
          document.getElementById('map').style.width = '65%';
        } else if (
          windowWidth > 550
          && window.location.pathname === '/precisionmapping'
        ) {
          document.getElementsByClassName('soilhealthpanel')[0].style.width = '25%';

          document.getElementById('maptoolboxdiv').style.display = 'block';
          document.getElementById('info-box').style.display = 'block';
          document.getElementById('map').style.width = '75%';
        } else if (window.location.pathname === '/precisionmapping') {
          document.getElementsByClassName('soilhealthpanel')[0].style.width = '100%';

          document.getElementById('info-box').style.display = 'none';
          document.getElementById('maptoolboxdiv').style.display = 'none';
          document.getElementById('map').style.width = '0%';
          document.getElementById('mobilelisticon').style.backgroundColor = '#e8e8e8';
          document.getElementById('mobilemapicon').style.backgroundColor = 'white';
        }
      },
      true,
    );
  }, []);

  const SetFarmState = async () => {
    setSelectedAnalysis('');
    setSelectedField('all fields');
    setSelectedAnalysisType('');
  };

  const SetFieldState = async (field) => {
    setSelectedField(field);
    setSelectedAnalysis('');
    setSelectedAnalysisType('');
  };

  const SetAnalysisState = async (analysis) => {
    setSelectedAnalysis(analysis);
    setSelectedAnalysisType('');
  };

  const SetAnalysisTypeState = async (analysistype) => {
    setSelectedAnalysisType(analysistype);
  };

  const SetYearState = async (year) => {
    setSelectedYear(year);
    setSelectedAnalysis('');
    setSelectedAnalysisType('');
  };

  const SetMapState = async (map) => {
    setMapGlobal(map);
  };

  const SetFieldBoundaryDrawingManagerState = async (
    fieldBoundaryDrawingManager,
  ) => {
    setFieldBoundaryDrawingManager(fieldBoundaryDrawingManager);
  };

  const SetFieldPolygonGlobalState = async (polygon) => {
    setFieldPolygonGlobal(polygon);
  };

  const SetInfoWindowState = async (infowindow) => {
    setInfoWindowGlobal(infowindow);
  };

  const SetRateKeyState = async (ratekeylist) => {
    setRateKeyGlobal(ratekeylist);
  };

  const SetMarkerState = async (marker) => {
    setMarkerGlobal(marker);
  };

  const SetMeasureState = async (measure) => {
    setMeasureGlobal(measure);
  };

  const SetMeasureClickState = async (clicked) => {
    setMeasureClick(clicked);
  };

  const SetEditBoundaryClickState = async (clicked) => {
    setEditBoundaryClick(clicked);
  };

  const SetCreateZonesClickState = async (clicked) => {
    setCreateZonesClick(clicked);
  };

  const SetMarkerClickState = async (clicked) => {
    setMarkerClick(clicked);
  };

  const SetTrackLocationClickState = async (clicked) => {
    setTrackLocationClick(clicked);
  };

  const SetCurrentMeasurementsState = async (measurements) => {
    setCurrentMeasurements(measurements);
  };

  const SetPolygonZonesState = async (polygons) => {
    setPolygonZonesGlobal(polygons);
  };

  const SetMapBounds = async () => {
    for (let i = 0; i < Fields.length; i++) {
      let currentField = '';
      if (selectedField.length === 0) {
        currentField = document.getElementById('field-select').value;
      } else {
        currentField = selectedField;
      }
      if (Fields[i].key === currentField) {
        const fieldCoords = [];
        const bounds = new window.google.maps.LatLngBounds();
        let fieldBoundary = JSON.parse(Fields[i].fieldCoords);

        if (typeof fieldBoundary === 'string') {
          if (fieldBoundary.length > 0) {
            fieldBoundary = JSON.parse(fieldBoundary);
          } else {
            fieldBoundary = null;
          }
        }

        if (fieldBoundary !== null) {
          for (let j = 0; j < fieldBoundary.coordinates[0].length; j++) {
            const latLong = fieldBoundary.coordinates[0][j];
            fieldCoords.push({ lat: latLong[1], lng: latLong[0] });
            const googleLatLng = new window.google.maps.LatLng(
              latLong[1],
              latLong[0],
            );
            bounds.extend(googleLatLng);
          }

          mapGlobal.fitBounds(bounds);
        }
      }
    }
  };

  const configObject = {
    mapGlobal,
    fieldPolygonGlobal,
    markerGlobal,
    measureGlobal,
    polygonZonesGlobal,
    fieldBoundaryDrawingManager,
    selectedAnalysis,
    infoWindowGlobal,
    rateKeyGlobal,
    selectedField,
    selectedYear,
    measureClick,
    editBoundaryClick,
    createZonesClick,
    markerClick,
    trackLocationClick,
    currentMeasurements,
    SetFieldBoundaryDrawingManagerState,
    SetFieldPolygonGlobalState,
    SetMapState,
    SetFarmState,
    SetFieldState,
    SetAnalysisState,
    SetAnalysisTypeState,
    SetYearState,
    SetInfoWindowState,
    SetRateKeyState,
    SetMarkerState,
    SetPolygonZonesState,
    SetMeasureClickState,
    SetEditBoundaryClickState,
    SetCreateZonesClickState,
    SetTrackLocationClickState,
    SetMarkerClickState,
    SetMeasureState,
    SetCurrentMeasurementsState,
  };

  return (
    <SoilHealthContext.Provider value={configObject}>
      <div className="soil-mainworkspace">
        <SoilHealthPanel
          selectedAnalysis={selectedAnalysis}
          selectedAnalysisType={selectedAnalysisType}
        />
        <SoilHealthMap
          selectedField={selectedField}
          selectedAnalysis={selectedAnalysis}
          selectedAnalysisType={selectedAnalysisType}
          selectedYear={selectedYear}
        />
        <div id="mobilenav">
          <span
            id="mobilelisticon"
            onClick={() => {
              document.getElementsByClassName(
                'soilhealthpanel',
              )[0].style.width = '100%';
              document.getElementById('maptoolboxdiv').style.display = 'none';
              document.getElementById('info-box').style.display = 'none';
              document.getElementById('map').style.width = '0%';
              document.getElementById('mobilelisticon').style.backgroundColor = '#e8e8e8';
              document.getElementById('mobilemapicon').style.backgroundColor = 'white';
            }}
          >
            <img src={listIconImage} alt="List Icon" />
          </span>
          <span
            id="mobilemapicon"
            onClick={() => {
              document.getElementsByClassName(
                'soilhealthpanel',
              )[0].style.width = '0%';

              document.getElementById('maptoolboxdiv').style.display = 'block';
              document.getElementById('info-box').style.display = 'block';
              document.getElementById('map').style.width = '100%';
              document.getElementById('mobilelisticon').style.backgroundColor = 'white';
              document.getElementById('mobilemapicon').style.backgroundColor = '#e8e8e8';

              SetMapBounds();
            }}
          >
            <img src={mapIconImage} alt="Map Icon" />
          </span>
        </div>
      </div>
      <SoilHealthPanelUsersModel users={Users} />
      <GCIGraphModal />
      <InfoBoxModal />
      <DeleteMarkerModal />
      <DeleteMeasureModal />
      <AddFarmModal />
      <AddFieldModal />
      <DeleteFieldModal />
      <DeleteFarmModal />
      <NoBoundaryDetectedModal />
      <LineIntersectsMessageModal />
      <SelectMeasureModal />
      <SaveZonesModal />
      <SaveMeasureModal />
      <SaveFieldBoundary />
    </SoilHealthContext.Provider>
  );
}

export default SoilHealth;
