import React from 'react';

function GLGMUpdatesModal() {
  return (
    <div
      className="modal fade"
      id="glgm-updates"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">Updates</div>
          <div className="modal-body">
            GLGM updates have been detected and applied
          </div>
          <div className="modal-footer">
            <a
              className="btn btn-success btn-ok"
              data-dismiss="modal"
              onClick={() => {
                window.location.replace(process.env.REACT_APP_REDIRECT);
              }}
            >
              Refresh
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GLGMUpdatesModal;
