import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../Auth/Auth0-Context';
import '../Styles/Header.css';
import insightLogo from '../Images/Insight_Logo.png';

export default function Header() {
  const {
    isLoading,
    isAccountLinked,
    isEmailVerified,
    hasAcceptedAuthRequest,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light nav-header">
        <Link className="navbar-brand mb-0 h1 header-link" to="/">
          <img
            className="navbar-brand-main-logo"
            src={insightLogo}
            alt="Insight Logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto" />
          <div className="navbar-end">
            {/* if there is no user. show the login button */}
            {!isLoading
              && !user
              && isAccountLinked
              && isEmailVerified
              && hasAcceptedAuthRequest
              && window.location.pathname !== '/signup' && (
                <button onClick={loginWithRedirect} className="navbar-item">
                  Login
                </button>
            )}

            {/* if account isn't linked or email not verified show a basic logout */}
            {!isLoading && !user && (isAccountLinked || isEmailVerified) && (
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="navbar-item"
              >
                Logout
              </button>
            )}

            {/* if there is a user. show user name and logout button */}
            {!isLoading && user && (
              <>
                <span>
                  <span className="font-italic font-weight-light signedin-user-title">
                    {user.name} -
                  </span>
                </span>
                <button
                  onClick={() => logout({ returnTo: window.location.origin })}
                  className="navbar-item header-link"
                >
                  Logout
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}
