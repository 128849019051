import React, { useEffect, useState } from 'react';
import { useUsers } from './Users-Context.js';
import { useFarmData } from './FarmData-Context';
import '../Styles/UserMaintenanceModal.css';
import { useIFS } from './IFSData-Context.js';
import Loader from './Loader.js';

function UserMaintenanceModal(props) {
  const {
    Roles, DeleteAuth0Users, PutUsers, SelectedGLGMFarm,
  } = useUsers();
  const { GetIFSCustomers, GetIFSUsers } = useIFS();
  const {
    ConvertShapeFiles,
    UploadAnalysis,
    GetAnalysis,
    GetGLGMFarms,
    DeleteAnalysis,
    DownloadFiles,
    SetAnalysisUploadsState,
    SetExistingUploadsState,
    AnalysisUploads,
    ExistingUploads,
    Farms,
    PutFarm,
    CurrentUser,
    UpdateFarmFieldData,
    Fields,
    isLoading,
    SetCurrentFarmState,
    CurrentFarm,
    SetAnalysisToDeleteState,
    AnalysisToDelete,
    GLGMChanged,
    SetGLGMChangedState,
  } = useFarmData();
  const [ifsNumber, setIfsNumber] = useState('');
  const [glgmNumber, setGlgmNumber] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [personCode, setPersonCode] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [resetRadioButton, setResetRadioButton] = useState(false);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [mappingOtherSelected, setMappingOtherSelected] = useState([]);
  const [mappingOptionInput, setMappingOptionInput] = useState('');
  const [updatedAnalysisName, setUpdatedAnalysisName] = useState('');
  const [existingUploadChecked, setExistingUploadChecked] = useState(false);
  const [cursorXPos, setCursorXPos] = useState(0);
  const [cursorDown, setCursorDown] = useState(false);
  const [gciAccess, setGciAccess] = useState(false);

  useEffect(() => {
    if (resetRadioButton) {
      setExistingUploadChecked(false);
      setResetRadioButton(false);
    }

    if (
      CurrentUser !== null
      && CurrentUser.split('|')[1] === props.user.user_id.split('|')[1]
    ) {
      setIfsNumber(props.user.ifsnumber);
      setPersonCode(props.user.personcode);
      setSelectedRoleName(props.user.role);
      setSelectedRole(props.user.roleid);

      if (props.user.gci_access == 'true') {
        setGciAccess(true);
      }

      if (
        CurrentUser !== null
        && document.getElementById(CurrentUser.split('|')[1]) !== null
        && !GLGMChanged
      ) {
        const { selectedIndex } = document.getElementById(
          CurrentUser.split('|')[1],
        );

        SetCurrentFarmState(
          document.getElementById(CurrentUser.split('|')[1]).options[
            selectedIndex
          ].value,
        );
      }

      if (
        CurrentUser !== null
        && document.getElementById(CurrentUser.split('|')[1]) !== null
        && !GLGMChanged
      ) {
        const { selectedIndex } = document.getElementById(
          CurrentUser.split('|')[1],
        );
        const selectedGLGMRef = document.getElementById(CurrentUser.split('|')[1])
          .options[selectedIndex].id;

        setGlgmNumber(selectedGLGMRef);
      } else if (
        CurrentUser !== null
        && CurrentFarm !== null
        && document.getElementById(CurrentUser.split('|')[1]) !== null
      ) {
        const { options } = document.getElementById(
          CurrentUser.split('|')[1],
        );

        for (let i = 0; i < options.length; i++) {
          if (options[i].value === CurrentFarm) {
            setGlgmNumber(options[i].id);
          }
        }
      }
    }
  }, [
    resetRadioButton,
    props.user.ifsnumber,
    props.user.personcode,
    props.user.role,
    props.user.roleid,
    props.user.gci_access,
    gciAccess,
    CurrentUser,
    Farms,
    GLGMChanged,
    CurrentFarm,
    SelectedGLGMFarm,
  ]);

  const d = new Date();
  const Years = [
    d.getFullYear(),
    d.getFullYear() - 1,
    d.getFullYear() - 2,
    d.getFullYear() - 3,
    d.getFullYear() - 4,
  ];

  const farmList = Farms.length > 0
    && Farms.map((item, i) => {
      return (
        <option key={i} id={item.glgmref} value={item.key}>
          {item.name}
        </option>
      );
    }, this);

  const fieldList = Fields.length > 0
    && Fields.map((item, i) => {
      return (
        <option key={i} id={item.name} value={item.key}>
          {item.name}
        </option>
      );
    }, this);

  const yearList = Years.map((item, i) => {
    const nextYear = parseInt(item) + 1;

    return (
      <option key={i} value={item}>
        {`${item}/${nextYear.toString()}`}
      </option>
    );
  }, this);

  const ExistingUploadsList = ExistingUploads.length > 0
    && ExistingUploads.map((item, i) => {
      const properties = [];
      const currentAnalysis = item;
      const nextYear = parseInt(item.year) + 1;
      const propertyNames = Object.getOwnPropertyNames(
        item.geojson.features[0].properties,
      );

      for (let i = 0; i < propertyNames.length; i++) {
        for (let j = 0; j < currentAnalysis.mapping.length; j++) {
          if (currentAnalysis.mapping[j].Property_Name === propertyNames[i]) {
            const property = {
              name: propertyNames[i],
              mapping: currentAnalysis.mapping[j].Mapping,
              value: item.geojson.features[0].properties[propertyNames[i]],
            };

            properties.push(property);
          }
        }
      }

      const propertyList = properties.length > 0
        && properties.map((item, i) => {
          if (item.mapping !== 'Hidden') {
            return (
              <tr key={i}>
                <td>{item.mapping}</td>
                <td>{item.value}</td>
              </tr>
            );
          }
        });

      const analysisField = Fields.length > 0
        && Fields.map((field, i) => {
          if (field.key === item.fieldid) {
            return (
              <option key={i} id={field.name} value={field.key}>
                {field.name}
              </option>
            );
          }
        }, this);

      return (
        <tr key={i}>
          <td>
            <div className="input-group">
              <div>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id={item.directorypath}
                  data-toggle="modal"
                  data-target="#confirm-analysis-delete"
                  onClick={(e) => {
                    for (let i = 0; i < ExistingUploads.length; i++) {
                      if (ExistingUploads[i].directorypath === e.target.id) {
                        SetAnalysisToDeleteState(e.target.id);
                      }
                    }
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </td>
          <td>
            <div className="input-group">
              <div>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id={item.directorypath}
                  onClick={(e) => {
                    for (let i = 0; i < ExistingUploads.length; i++) {
                      if (ExistingUploads[i].directorypath === e.target.id) {
                        DownloadFiles(
                          JSON.stringify(ExistingUploads[i].geojson),
                          ExistingUploads[i].farmid,
                          ExistingUploads[i].fieldid,
                          ExistingUploads[i].year,
                          ExistingUploads[i].name,
                          'Shapefiles',
                        );
                      }
                    }
                  }}
                >
                  Download Existing Shapefiles
                </button>
              </div>
            </div>
          </td>
          <td>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>{propertyList}</tbody>
            </table>
          </td>
          <td>
            <i>Overwrite Reports</i>
            <div>
              <div className="form-group">
                <input
                  type="file"
                  className="form-control-file analysis-report-upload"
                  id={item.directorypath}
                  onChange={(e) => {
                    let { files } = e.target;
                    const fileExtensions = [];
                    let itemExists = false;
                    let index = -1;

                    for (let i = 0; i < files.length; i++) {
                      let extension = files[i].name.split('.');
                      extension = files[i].name.split('.')[extension.length - 1];

                      fileExtensions.push(extension);
                    }

                    for (let i = 0; i < fileExtensions.length; i++) {
                      let pdfMatch = false;

                      if (fileExtensions[i] === 'pdf') {
                        pdfMatch = true;
                      }

                      if (!pdfMatch) {
                        e.target.value = '';
                        alert('You must supply .pdf files only.');
                        files = null;
                      }
                    }

                    for (let i = 0; i < analysis.length; i++) {
                      if (analysis[i][e.target.id] !== undefined) {
                        index = i;
                        itemExists = true;
                      }
                    }

                    if (!itemExists) {
                      setAnalysis([
                        ...analysis,
                        {
                          [item.directorypath]: { Reports: files },
                        },
                      ]);
                    } else {
                      analysis[index][e.target.id].Reports = files;

                      setAnalysis([...analysis]);
                    }
                  }}
                  multiple
                />
              </div>
            </div>
            <div className="input-group">
              <div>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id={item.directorypath}
                  onClick={(e) => {
                    for (let i = 0; i < ExistingUploads.length; i++) {
                      if (ExistingUploads[i].directorypath === e.target.id) {
                        DownloadFiles(
                          JSON.stringify(ExistingUploads[i].geojson),
                          ExistingUploads[i].farmid,
                          ExistingUploads[i].fieldid,
                          ExistingUploads[i].year,
                          ExistingUploads[i].name,
                          'Reports',
                        );
                      }
                    }
                  }}
                >
                  Download Existing Reports
                </button>
              </div>
            </div>
          </td>
          <td>
            <select
              className="custom-select analysis-field-select"
              id={item.directorypath}
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.directorypath]: { SelectedField: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].SelectedField = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
              required
            >
              {analysisField}
              {fieldList}
            </select>
          </td>
          <td>
            <select
              id={item.directorypath}
              className="custom-select analysis-year-select"
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.directorypath]: { SelectedYear: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].SelectedYear = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
            >
              <option value={item.year}>
                {`${item.year}/${nextYear.toString()}`}
              </option>
              {yearList}
            </select>
          </td>
          <td>
            <select
              id={item.directorypath}
              className="custom-select analysis-type-select"
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.directorypath]: {
                        SelectedAnalysisType: e.target.value,
                      },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].SelectedAnalysisType = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
              required
            >
              <option value={item.type}>{item.type}</option>
              <option value="Editable">Editable</option>
              <option value="Non-Editable">Non-Editable</option>
            </select>
          </td>
          <td>
            <select
              id={item.name}
              className="custom-select um-type-select"
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.directorypath]: { UnitOfMeasure: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].UnitOfMeasure = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
              required
            >
              <option value={item.unitofmeasure}>{item.unitofmeasure}</option>
              <option value="Kg/Ha">Kg/Ha</option>
              <option value="Index">Index</option>
              <option value="M/S">M/S</option>
              <option value=" " />
            </select>
          </td>
          <td>
            <input
              type="text"
              placeholder={item.name}
              className="form-control analysis-name-input"
              id={item.directorypath}
              autoComplete="off"
              value={updatedAnalysisName[i]}
              onChange={(e) => {
                setUpdatedAnalysisName({ [i]: e.target.value });
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.directorypath]: { AnalysisName: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].AnalysisName = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
            />
            <div className="valid-tooltip">Looks good!</div>
          </td>
        </tr>
      );
    }, this);

  const AnalysisUploadsList = AnalysisUploads.length > 0
    && ExistingUploads.length === 0
    && AnalysisUploads.map((item, i) => {
      const properties = [];
      const currentAnalysis = item;
      const currentAnalysisName = item.name;
      const propertyNames = Object.getOwnPropertyNames(
        item.geojson.features[0].properties,
      );

      for (let i = 0; i < propertyNames.length; i++) {
        const property = {
          name: propertyNames[i],
          value: item.geojson.features[0].properties[propertyNames[i]],
        };

        properties.push(property);
      }

      const propertyList = properties.length > 0
        && properties.map((item, i) => {
          let mapping = null;

          for (let i = 0; i < analysis.length; i++) {
            if (analysis[i][currentAnalysis.name] !== undefined) {
              for (
                let j = 0;
                j < analysis[i][currentAnalysis.name].Mapping.length;
                j++
              ) {
                if (
                  analysis[i][currentAnalysis.name].Mapping[j].Property_Name
                  === item.name
                ) {
                  mapping = (
                    <option
                      value={
                        analysis[i][currentAnalysis.name].Mapping[j].Mapping
                      }
                    >
                      {analysis[i][currentAnalysis.name].Mapping[j].Mapping}
                    </option>
                  );
                }
              }
            }
          }

          return (
            <table key={i} className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Mapping</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item.name}</td>
                  <td>
                    {mappingOtherSelected[item.name + currentAnalysisName] ? (
                      <>
                        <select
                          className="custom-select mapping-analysis-select"
                          id={`${item.name + currentAnalysisName}/${i}`}
                          onChange={(e) => {
                            const select_id = document.getElementById(
                              e.target.id,
                            );
                            let otherSelected = false;
                            const classList = document.getElementsByClassName(
                              e.target.className,
                            );

                            for (let j = 0; j < classList.length; j++) {
                              if (
                                select_id.options[classList[j].selectedIndex]
                                  .value === 'Other'
                              ) {
                                otherSelected = true;
                              }
                            }
                            if (otherSelected) {
                              setMappingOtherSelected({ [e.target.id]: true });
                            } else {
                              setMappingOtherSelected({ [e.target.id]: false });
                            }
                          }}
                          required
                        >
                          <option value="">Enter Mapping</option>
                        </select>
                        <input
                          type="text"
                          className="form-control analysis-mapping-input"
                          id={`${item.name}/${currentAnalysisName}`}
                          autoComplete="off"
                          value={mappingOptionInput}
                          onInput={(e) => {
                            setMappingOptionInput(e.target.value);
                          }}
                          onBlur={(e) => {
                            const propertyAnalysisName = e.target.id.split('/');
                            const mappingValue = e.target.value;
                            let itemExists = false;
                            let iIndex = -1;
                            let jIndex = -1;

                            for (let i = 0; i < analysis.length; i++) {
                              if (
                                analysis[i][propertyAnalysisName[1]]
                                !== undefined
                              ) {
                                iIndex = i;

                                for (
                                  let j = 0;
                                  j
                                  < analysis[i][propertyAnalysisName[1]].Mapping
                                    .length;
                                  j++
                                ) {
                                  if (
                                    analysis[i][propertyAnalysisName[1]]
                                      .Mapping[j].Property_Name
                                    === propertyAnalysisName[0]
                                  ) {
                                    jIndex = j;
                                    itemExists = true;
                                  }
                                }
                              }
                            }

                            if (!itemExists) {
                              analysis[iIndex][
                                propertyAnalysisName[1]
                              ].Mapping.push({
                                Property_Name: propertyAnalysisName[0],
                                Mapping: mappingValue,
                              });

                              setAnalysis([...analysis]);
                            } else {
                              analysis[iIndex][propertyAnalysisName[1]].Mapping[
                                jIndex
                              ].Mapping = mappingValue;

                              setAnalysis([...analysis]);
                            }

                            setMappingOtherSelected({
                              [propertyAnalysisName[0]
                              + propertyAnalysisName[1]]: false,
                            });
                            setMappingOptionInput('');
                          }}
                        />
                      </>
                    ) : (
                      <select
                        className="custom-select mapping-analysis-select"
                        id={`${item.name}/${currentAnalysisName}/${i}`}
                        onChange={(e) => {
                          const select_id = document.getElementById(e.target.id);
                          let otherSelected = false;
                          const classList = document.getElementsByClassName(
                            e.target.className,
                          );

                          for (let j = 0; j < classList.length; j++) {
                            if (
                              select_id.options[classList[j].selectedIndex]
                                .value === 'Other'
                            ) {
                              otherSelected = true;
                            }
                          }

                          if (otherSelected) {
                            setMappingOtherSelected({
                              [e.target.id.split('/')[0]
                              + e.target.id.split('/')[1]]: true,
                            });
                          } else {
                            const propertyAnalysisName = e.target.id.split('/');
                            const mappingValue = e.target.value;
                            let itemExists = false;
                            let iIndex = -1;
                            let jIndex = -1;

                            for (let i = 0; i < analysis.length; i++) {
                              if (
                                analysis[i][propertyAnalysisName[1]]
                                !== undefined
                              ) {
                                iIndex = i;

                                for (
                                  let j = 0;
                                  j
                                  < analysis[i][propertyAnalysisName[1]].Mapping
                                    .length;
                                  j++
                                ) {
                                  if (
                                    analysis[i][propertyAnalysisName[1]]
                                      .Mapping[j].Property_Name
                                    === propertyAnalysisName[0]
                                  ) {
                                    jIndex = j;
                                    itemExists = true;
                                  }
                                }
                              }
                            }

                            if (!itemExists) {
                              analysis[iIndex][
                                propertyAnalysisName[1]
                              ].Mapping.push({
                                Property_Name: propertyAnalysisName[0],
                                Mapping: mappingValue,
                              });

                              setAnalysis([...analysis]);
                            } else {
                              analysis[iIndex][propertyAnalysisName[1]].Mapping[
                                jIndex
                              ].Mapping = mappingValue;

                              setAnalysis([...analysis]);
                            }

                            setMappingOtherSelected({
                              [propertyAnalysisName[0]
                              + propertyAnalysisName[1]]: false,
                            });
                            setMappingOptionInput('');
                            setMappingOtherSelected({ [e.target.id]: false });
                          }
                        }}
                        required
                      >
                        {mapping}
                        <option value="">Select</option>
                        <option value="Hidden">Hidden</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          );
        });

      let itemExists = false;
      let index = -1;
      const d = new Date();
      const currentYear = d.getFullYear().toString();

      for (let i = 0; i < analysis.length; i++) {
        if (analysis[i][item.name] !== undefined) {
          index = i;
          itemExists = true;
        }
      }

      if (!itemExists) {
        setAnalysis([
          ...analysis,
          {
            [item.name]: {
              SelectedYear: currentYear,
              AnalysisName:
                item.mapping.length === 1 ? item.mapping[0].Mapping : item.name,
              SelectedField:
                fieldList[0] !== undefined ? fieldList[0].props.value : '',
              SelectedFarm:
                selectedFarm.length === 0
                  ? farmList[0].props.value
                  : selectedFarm,
              SelectedAnalysisType: 'Editable',
              UnitOfMeasure: 'Kg/Ha',
              Mapping: currentAnalysis.mapping,
            },
          },
        ]);
      }

      return (
        <tr key={i}>
          <td>{item.name}</td>
          <td>{propertyList}</td>
          <td>
            <div>
              <div className="form-group">
                <input
                  type="file"
                  className="form-control-file analysis-report-upload"
                  id={item.name}
                  onChange={(e) => {
                    let { files } = e.target;
                    const fileExtensions = [];
                    let itemExists = false;
                    let index = -1;

                    for (let i = 0; i < files.length; i++) {
                      let extension = files[i].name.split('.');
                      extension = files[i].name.split('.')[extension.length - 1];

                      fileExtensions.push(extension);
                    }

                    for (let i = 0; i < fileExtensions.length; i++) {
                      let pdfMatch = false;

                      if (fileExtensions[i] === 'pdf') {
                        pdfMatch = true;
                      }

                      if (!pdfMatch) {
                        e.target.value = '';
                        alert('You must supply .pdf files only.');
                        files = null;
                      }
                    }

                    for (let i = 0; i < analysis.length; i++) {
                      if (analysis[i][e.target.id] !== undefined) {
                        index = i;
                        itemExists = true;
                      }
                    }

                    if (!itemExists) {
                      setAnalysis([
                        ...analysis,
                        {
                          [item.name]: { Reports: files },
                        },
                      ]);
                    } else {
                      analysis[index][e.target.id].Reports = files;

                      setAnalysis([...analysis]);
                    }
                  }}
                  multiple
                />
              </div>
            </div>
          </td>
          <td>
            <select
              className="custom-select analysis-field-select"
              id={item.name}
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.name]: { SelectedField: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].SelectedField = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
              required
            >
              {fieldList}
            </select>
          </td>
          <td>
            <select
              id={item.name}
              className="custom-select analysis-year-select"
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.name]: { SelectedYear: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].SelectedYear = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
            >
              {yearList}
            </select>
          </td>
          <td>
            <select
              id={item.name}
              className="custom-select analysis-type-select"
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.name]: { SelectedAnalysisType: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].SelectedAnalysisType = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
              required
            >
              <option value="Editable">Editable</option>
              <option value="Non-Editable">Non-Editable</option>
            </select>
          </td>
          <td>
            <select
              id={item.name}
              className="custom-select um-type-select"
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.name]: { UnitOfMeasure: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].UnitOfMeasure = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
              required
            >
              <option value="Kg/Ha">Kg/Ha</option>
              <option value="Index">Index</option>
              <option value="M/S">M/S</option>
              <option value=" " />
            </select>
          </td>
          <td>
            <input
              type="text"
              placeholder="Data type"
              className="form-control analysis-name-input"
              id={item.name}
              autoComplete="off"
              defaultValue={
                item.mapping.length === 1 ? item.mapping[0].Mapping : item.name
              }
              onChange={(e) => {
                let itemExists = false;
                let index = -1;

                for (let i = 0; i < analysis.length; i++) {
                  if (analysis[i][e.target.id] !== undefined) {
                    index = i;
                    itemExists = true;
                  }
                }

                if (!itemExists) {
                  setAnalysis([
                    ...analysis,
                    {
                      [item.name]: { AnalysisName: e.target.value },
                    },
                  ]);
                } else {
                  analysis[index][e.target.id].AnalysisName = e.target.value;

                  setAnalysis([...analysis]);
                }
              }}
              required
            />
            <div className="valid-tooltip">Looks good!</div>
          </td>
        </tr>
      );
    }, this);

  const roleList = Roles.length > 0
    && Roles.map((item, i) => {
      return (
        <option key={i} name={item.name} id={item.id} value={item.id}>
          {item.name}
        </option>
      );
    }, this);
  const HandleSaveChangesClick = async (id) => {
    if (selectedRoleName === 'Customer' && ifsNumber === '') {
      alert('You must supply an IFS number with the customer role.');
    } else if (selectedRoleName === 'Agronomist' && personCode === '') {
      alert('You must supply an person code with the agronomist role.');
    } else {
      PutFarm(Farms);

      PutUsers(
        id,
        ifsNumber,
        glgmNumber,
        personCode,
        selectedRole,
        selectedRoleName,
        gciAccess,
      );
    }
  };

  const HandleDeleteButtonClick = async (id) => {
    document.getElementsByClassName('modal-close-button')[0].click();
    document.getElementsByClassName('modal-backdrop')[0].remove();
    DeleteAuth0Users(id);
    setIfsNumber('');
    setGlgmNumber('');
  };

  return (
    <>
      <div
        className="modal fade"
        id={`modal${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                User Maintenance
              </h5>
              <button
                id={props.user.user_id}
                type="button"
                className="btn btn-primary delete-user-button"
                data-toggle="modal"
                data-target={
                  `#confirm-delete${props.user.user_id.split('|')[1]}`
                }
                data-dismiss="modal"
              >
                Delete User
              </button>
              <button
                type="button"
                id="user-maintenance-close"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body user-maintenance-modal-body">
              <form>
                <div className="form-group">
                  <h6>Account Details ({props.user.name})</h6>
                </div>
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={props.user.name}
                      checked={gciAccess}
                      onChange={(e) => {
                        setGciAccess(e.target.checked);

                        if (e.target.checked) {
                          props.user.gci_access = 'true';
                        } else {
                          props.user.gci_access = 'false';
                        }
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={props.user.name}
                    >
                      GCI Access
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    <i>
                      <b>Change Role</b>
                    </i>
                  </label>
                  <br />
                  <div className="input-group mb-3">
                    <select
                      id="role-select"
                      className="custom-select role-select"
                      onChange={(e) => {
                        props.user.role = document.getElementById(
                          e.target.value,
                        ).textContent;
                        props.user.roleid = e.target.value;
                        setSelectedRoleName(
                          document.getElementById(e.target.value).textContent,
                        );
                        setSelectedRole(e.target.value);
                      }}
                    >
                      <option id="none" value="none" />
                      {roleList}
                    </select>
                  </div>
                </div>
                {selectedRoleName === 'Customer' ? (
                  <>
                    <div className="form-group">
                      <label>
                        <i>
                          <b>IFS Number</b>
                        </i>
                      </label>
                      <br />
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control usermaint-ifsno-readonly"
                          placeholder="IFS Account Number"
                          aria-label="IFS Account Number"
                          aria-describedby="button-addon"
                          value={ifsNumber}
                          onKeyDown={() => {
                            return false;
                          }}
                          onChange={() => {}}
                          required
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon"
                            data-toggle="modal"
                            data-target="#search-ifs-customers"
                            onClick={() => {
                              GetIFSCustomers();
                            }}
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : selectedRoleName === 'Agronomist' ? (
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="IFS Person Code"
                        aria-label="IFS Person Code"
                        aria-describedby="button-addon"
                        autoComplete="off"
                        value={personCode}
                        readOnly
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          id="button-addon"
                          data-toggle="modal"
                          data-target="#search-ifs-users"
                          onClick={() => {
                            GetIFSUsers();
                          }}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {farmList ? (
                  <>
                    <div className="form-group">
                      <label>
                        <i>
                          <b>Farm</b>
                        </i>
                      </label>
                      <br />
                      <div className="input-group mb-3">
                        <select
                          id={
                            CurrentUser !== null
                              ? CurrentUser.split('|')[1]
                              : ''
                          } // "glgm-user-farm-select"
                          onChange={(e) => {
                            SetGLGMChangedState(true);
                            SetCurrentFarmState(
                              document.getElementById(CurrentUser.split('|')[1])
                                .options[e.target.selectedIndex].value,
                            );
                            const selectedGLGMRef = document.getElementById(
                              CurrentUser.split('|')[1],
                            ).options[e.target.selectedIndex].id;
                            setGlgmNumber(selectedGLGMRef);
                          }}
                          className="custom-select"
                        >
                          {farmList}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        <i>
                          <b>GLGM Number</b>
                        </i>
                      </label>
                      <br />
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="GLGM Reference"
                          aria-label="GLGM Reference"
                          aria-describedby="button-addon"
                          value={glgmNumber}
                          readOnly
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon"
                            data-toggle="modal"
                            data-target="#search-glgm-farms"
                            onClick={() => {
                              GetGLGMFarms();
                            }}
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {farmList.length > 0 ? (
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target={
                          `#upload-analysis${props.user.user_id.split('|')[1]}`
                        }
                        className="upload-analysis-button"
                      >
                        Upload Analysis
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </form>
            </div>
            {isLoading ? <Loader /> : ''}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id={props.user.user_id}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                data-toggle="modal"
                data-target={`#confirm-save${props.user.user_id.split('|')[1]}`}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`confirm-save${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">Confirm</div>
            <div className="modal-body">
              Are you sure you want to save the changes?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id={props.user.user_id}
                className="btn btn-success btn-ok"
                data-dismiss="modal"
                onClick={(e) => {
                  HandleSaveChangesClick(e.target.id);
                }}
              >
                Save
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`confirm-delete${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">Confirm</div>
            <div className="modal-body">
              Are you sure you want to delete the user?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id={props.user.user_id}
                className="btn btn-danger btn-ok"
                data-dismiss="modal"
                onClick={(e) => {
                  HandleDeleteButtonClick(e.target.id);
                }}
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const t = document.getElementsByClassName('analysis-field-select');

          for (let i = 0; i < AnalysisUploads.length; i++) {
            for (
              let j = 0;
              j < AnalysisUploads[i].geojson.features.length;
              j++
            ) {
              const propertyNames = Object.getOwnPropertyNames(
                AnalysisUploads[i].geojson.features[j].properties,
              );
              for (let k = 0; k < propertyNames.length; k++) {
                const patt = /^\d+(\.\d{1,2})?$/;
                const result = patt.test(
                  AnalysisUploads[i].geojson.features[j].properties[
                    propertyNames[k]
                  ],
                );

                let currentAnalysisEditable = false;

                for (let l = 0; l < analysis.length; l++) {
                  if (
                    analysis[l][AnalysisUploads[i].name] !== undefined
                    && analysis[l][AnalysisUploads[i].name]
                      .SelectedAnalysisType === 'Editable'
                  ) {
                    currentAnalysisEditable = true;
                  }
                }

                if (!result && currentAnalysisEditable) {
                  const updatedAnalysisUploads = [];
                  const t = analysis;

                  for (let l = 0; l < AnalysisUploads.length; l++) {
                    if (l !== i) {
                      updatedAnalysisUploads.push(AnalysisUploads[l]);
                    }
                  }

                  alert(
                    `Analysis '${
                      AnalysisUploads[i].name
                    }' contains invalid values. The values must be numbers/decimals too two decimal places.`
                      + ' Alternatively make the analysis \'Non-Editable\'.',
                  );

                  SetAnalysisUploadsState(updatedAnalysisUploads);

                  return;
                }
              }
            }
          }

          const analysisObjectList = [];

          for (let i = 0; i < analysis.length; i++) {
            const analysisObject = {
              DirectoryPath: '',
              Farm_ID: '',
              Field_ID: '',
              Year: '',
              Unit_Of_Measure: '',
              Analysis_Name: '',
              Analysis_Type: '',
              Analysis_GeoJson: '',
              Property_Mapping: '',
              Reports: null,
            };
            const propertyName = Object.getOwnPropertyNames(analysis[i]);

            if (
              analysis[i][propertyName[0]].SelectedFarm === undefined
              || analysis[i][propertyName[0]].SelectedField === undefined
              || analysis[i][propertyName[0]].SelectedYear === undefined
              || analysis[i][propertyName[0]].AnalysisName === undefined
            ) {
              analysisObject.DirectoryPath = propertyName[0];
            } else {
              analysisObject.DirectoryPath = `${analysis[i][propertyName[0]].SelectedFarm
              }/${
                analysis[i][propertyName[0]].SelectedField
              }/${
                analysis[i][propertyName[0]].SelectedYear
              }/${
                analysis[i][propertyName[0]].AnalysisName}`;
            }

            analysisObject.Farm_ID = analysis[i][propertyName[0]].SelectedFarm;
            analysisObject.Field_ID = analysis[i][propertyName[0]].SelectedField;
            analysisObject.Year = analysis[i][propertyName[0]].SelectedYear;
            analysisObject.Unit_Of_Measure = analysis[i][propertyName[0]].UnitOfMeasure;
            analysisObject.Analysis_Name = analysis[i][propertyName[0]].AnalysisName;
            analysisObject.Analysis_Type = analysis[i][propertyName[0]].SelectedAnalysisType;
            analysisObject.Property_Mapping = JSON.stringify(
              analysis[i][propertyName[0]].Mapping,
            );
            analysisObject.Reports = analysis[i][propertyName[0]].Reports;

            for (let j = 0; j < AnalysisUploads.length; j++) {
              if (AnalysisUploads[j].name === propertyName[0]) {
                analysisObject.Analysis_GeoJson = JSON.stringify(
                  AnalysisUploads[j].geojson,
                );

                analysisObjectList.push(analysisObject);
              }
            }

            if (analysisObject.DirectoryPath === propertyName[0]) {
              analysisObjectList.push(analysisObject);
            }
          }

          UploadAnalysis(analysisObjectList);
          document.getElementById('analysis-maintenance-close').click();
          document.getElementById('user-maintenance-close').click();
          document.getElementById('new-upload-radio').checked = true;
          setResetRadioButton(true);
          setExistingUploadChecked(true);
          SetAnalysisUploadsState();
          SetExistingUploadsState();
          setUpdatedAnalysisName('');
          setAnalysis('');
        }}
      >
        <div
          className="modal fade"
          id={`upload-analysis${props.user.user_id.split('|')[1]}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div
            className="modal-dialog mw-100 w-95 modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Analysis Maintenance
                </h5>
                <button
                  type="button"
                  id="analysis-maintenance-close"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div
                id="upload-analysis-body"
                className="modal-body upload-analysis-body"
                onMouseMove={(e) => {
                  if (cursorDown) {
                    document
                      .getElementById('upload-analysis-body')
                      .scrollTo(
                        document.getElementById('upload-analysis-body')
                          .scrollLeft
                          + (cursorXPos - e.pageX),
                        document.getElementById('upload-analysis-body')
                          .scrollTop,
                      );
                  }
                }}
                onMouseDown={(e) => {
                  setCursorDown(true);
                  setCursorXPos(e.pageX);
                }}
                onMouseUp={(e) => {
                  setCursorDown(false);
                }}
              >
                <div className="form-inline">
                  <label
                    className="my-1 mr-2"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Farm:
                  </label>
                  <select
                    className="custom-select my-1 mr-sm-2"
                    id="analysis-farm-select"
                    value={selectedFarm}
                    onChange={(e) => {
                      SetAnalysisUploadsState();
                      SetExistingUploadsState();

                      const select_id = document.getElementById(
                        'analysis-farm-select',
                      );

                      if (
                        !document.getElementById('new-upload-radio').checked
                      ) {
                        GetAnalysis(
                          select_id.options[e.target.selectedIndex].value,
                        );
                      }

                      UpdateFarmFieldData(
                        select_id.options[e.target.selectedIndex].value,
                        CurrentUser,
                      );

                      if (analysis.length > 0) {
                        for (let i = 0; i < analysis.length; i++) {
                          const analysisProperties = Object.getOwnPropertyNames(
                            analysis[i],
                          );

                          for (let j = 0; j < analysisProperties.length; j++) {
                            analysis[i][analysisProperties[j]].SelectedFarm = select_id.options[e.target.selectedIndex].value;

                            setSelectedFarm(
                              select_id.options[e.target.selectedIndex].value,
                            );
                            setAnalysis([...analysis]);
                          }
                        }
                      } else {
                        setSelectedFarm(
                          select_id.options[e.target.selectedIndex].value,
                        );
                      }
                    }}
                  >
                    {farmList}
                  </select>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="new-upload-radio"
                      value="option1"
                      defaultChecked
                      onClick={() => {
                        setExistingUploadChecked(false);
                        SetAnalysisUploadsState();
                        SetExistingUploadsState();
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-upload-radio"
                    >
                      New Uploads
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                      onClick={() => {
                        setExistingUploadChecked(true);
                        SetAnalysisUploadsState();
                        SetExistingUploadsState();

                        if (selectedFarm.length > 0) {
                          GetAnalysis(selectedFarm);
                        } else {
                          GetAnalysis(
                            document.getElementById('analysis-farm-select')
                              .options[0].value,
                          );
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Existing Uploads
                    </label>
                  </div>
                  {!existingUploadChecked ? (
                    <div className="custom-file shapefile-upload-maintenance">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="shapefiles"
                        onChange={(e) => {
                          const { files } = e.target;
                          const fileNames = [];

                          for (let i = 0; i < files.length; i++) {
                            const splitFiles = files[i].name.split('.');
                            const name = files[i].name.split('.')[0];
                            let match = false;

                            if (splitFiles.length > 2) {
                              alert(
                                "You must not have a '.' if the file name.",
                              );
                              return;
                            }

                            for (let j = 0; j < fileNames.length; j++) {
                              if (fileNames[j] === name) {
                                match = true;
                              }
                            }

                            if (!match) {
                              fileNames.push(name);
                            }
                          }

                          for (let i = 0; i < fileNames.length; i++) {
                            let shpMatch = false;
                            let dbfMatch = false;
                            let shxMatch = false;
                            for (let j = 0; j < files.length; j++) {
                              if (files[j].name === `${fileNames[i]}.shp`) {
                                shpMatch = true;
                              }
                              if (files[j].name === `${fileNames[i]}.dbf`) {
                                dbfMatch = true;
                              }
                              if (files[j].name === `${fileNames[i]}.shx`) {
                                shxMatch = true;
                              }
                            }

                            if (!shpMatch || !dbfMatch || !shxMatch) {
                              alert(
                                'You must supply the .shx, .shp and .dbf files.',
                              );
                              return;
                            }
                          }
                          SetAnalysisUploadsState();
                          ConvertShapeFiles(files);
                        }}
                        multiple
                      />
                      <label className="custom-file-label" htmlFor="shapeFiles">
                        Upload Shapefiles
                      </label>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {existingUploadChecked ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Delete</th>
                        <th>Shapefile</th>
                        <th>
                          Map layer{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="right"
                            title="e.g. Big Field VR Seed WW 2021"
                          >
                            <i className="infobox-help">
                              (<u>what's this?</u>)
                            </i>
                          </span>
                        </th>
                        <th>Reports</th>
                        <th>Field</th>
                        <th>Season</th>
                        <th>Type</th>
                        <th>Units</th>
                        <th>
                          Data type{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="right"
                            title="e.g. seed rate / nutrient / soil type"
                          >
                            <i className="infobox-help">
                              (<u>what's this?</u>)
                            </i>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{ExistingUploadsList}</tbody>
                  </table>
                ) : (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Shapefile</th>
                        <th>
                          Map layer{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="right"
                            title="e.g. Big Field VR Seed WW 2021"
                          >
                            <i className="infobox-help">
                              (<u>what's this?</u>)
                            </i>
                          </span>
                        </th>
                        <th>Reports</th>
                        <th>Field</th>
                        <th>Season</th>
                        <th>Type</th>
                        <th>Units</th>
                        <th>
                          Data type{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="right"
                            title="e.g. seed rate / nutrient / soil type"
                          >
                            <i className="infobox-help">
                              (<u>what's this?</u>)
                            </i>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{AnalysisUploadsList}</tbody>
                  </table>
                )}
              </div>
              {isLoading ? <Loader /> : ''}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  id={props.user.user_id}
                  className="btn btn-success btn-ok"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        className="modal fade"
        id="confirm-analysis-delete"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">Confirm</div>
            <div className="modal-body">
              Are you sure you want to delete the select analysis?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                className="btn btn-danger btn-ok"
                data-dismiss="modal"
                onClick={() => {
                  DeleteAnalysis(AnalysisToDelete);
                }}
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserMaintenanceModal;
