import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../Auth/Auth0-Context';
import '../Styles/Home.css';
import { Link } from 'react-router-dom';
import { find } from 'lodash';
import { useFarmData } from '../Components/FarmData-Context';
import AssignHomePageLinkModalTwo from '../Components/AssignHomePageLinkModalTwo';
import AssignHomePageLinkModalOne from '../Components/AssignHomePageLinkModalOne';
import AssignHomePageLinkModalThree from '../Components/AssignHomePageLinkModalThree';
import AssignHomePageLinkModalFour from '../Components/AssignHomePageLinkModalFour';
import precisionMappingImage from '../Images/AUK-Portal-Precision-Mapping.jpg';
import deliveryNotificationImage from '../Images/AUK-Portal-Delivery-Notifications-Panel.jpg';
import soilHealthImage from '../Images/AUK-Portal-Soil-Health.jpg';
import insightV2DesktopImage from '../Images/insight-v2-desktop-banner.jpg';
import insightV2MobileImage from '../Images/insight-v2-mobile-banner.jpg';
import { User } from '@auth0/auth0-spa-js';

function MainPanelLink({ imageSource, route }) {
  return (
    <div className="grid-container-main">
      <div>
        <Link className="main-panel-link" to={route}>
          <img src={imageSource} alt={route} />
        </Link>
      </div>
    </div>
  );
}

function RenderSupportedHomePageLink({
  homePagePanelLink = null, supportedHomePageLinks, panelNumber,
}) {
  const { DeleteUserProfile } = useFarmData();
  const homepageLinkObject = find(supportedHomePageLinks, ['fileDownloadName', homePagePanelLink]);
  const homepageLinkNumbers = ['one', 'two', 'three', 'four'];
  const homepageLinkNumber = homepageLinkNumbers[panelNumber - 1];

  if (!homepageLinkObject) {
    return (
      <button
        id={`homepagepanel${panelNumber}`}
        className="add-link-button"
        data-target={`#assign-homepage-link-${homepageLinkNumber}`}
        data-toggle="modal"
      >
        <span className="link-title">Link {panelNumber}</span>
      </button>
    );
  }

  return (
    <div className="supportedhomepagelinkdiv">
      <a target="_blank" href={homePagePanelLink} rel="noreferrer">
        <button
          onClick={(e) => {
            e.preventDefault();
            DeleteUserProfile(`homepagepanel${panelNumber}`);
          }}
          type="button"
          className="close delete-homepage-link"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <img
          className="supportedhomepagelink"
          src={
            `data:image/jpeg;base64,${homepageLinkObject.fileContents}`
          }
        />
      </a>
    </div>
  );
}

function Home() {
  const { role, SetRoleState } = useAuth0();
  const {
    GetHomePageLink,
    SupportedHomePageLinks,
    GetUserProfile,
    UserProfile,
  } = useFarmData();

  useEffect(() => {
    GetHomePageLink();
    GetUserProfile();

    if (role === null) {
      SetRoleState();
    }
  }, [role]);

  return (
    <>
      <div className="my-container">
        <a href="https://www.insight.agrovista.co.uk" className="desktop-banner">
          <img src={insightV2DesktopImage} />
        </a>
        <a href="https://www.insight.agrovista.co.uk" className="mobile-banner">
          <img src={insightV2MobileImage} />
        </a>
        <div className="grid-collection">
          <MainPanelLink
            imageSource={precisionMappingImage}
            route="/precisionmapping"
          />
          <MainPanelLink
            imageSource={soilHealthImage}
            route="/soilhealth"
          />
          <MainPanelLink
            imageSource={deliveryNotificationImage}
            route="/deliverynotifications"
          />
          <div className="grid-container-other">
            <RenderSupportedHomePageLink
              homePagePanelLink={UserProfile.length > 0 && UserProfile[0].homePagePanel1}
              supportedHomePageLinks={SupportedHomePageLinks}
              panelNumber={1}
            />
            <RenderSupportedHomePageLink
              homePagePanelLink={UserProfile.length > 0 && UserProfile[0].homePagePanel2}
              supportedHomePageLinks={SupportedHomePageLinks}
              panelNumber={2}
            />
            <RenderSupportedHomePageLink
              homePagePanelLink={UserProfile.length > 0 && UserProfile[0].homePagePanel3}
              supportedHomePageLinks={SupportedHomePageLinks}
              panelNumber={3}
            />
            <RenderSupportedHomePageLink
              homePagePanelLink={UserProfile.length > 0 && UserProfile[0].homePagePanel4}
              supportedHomePageLinks={SupportedHomePageLinks}
              panelNumber={4}
            />
          </div>
        </div>
      </div>
      <a
        id="assign-homepage-link2-trigger"
        data-target="#assign-homepage-link2"
        data-toggle="modal"
      />
      <AssignHomePageLinkModalOne />
      <AssignHomePageLinkModalTwo />
      <AssignHomePageLinkModalThree />
      <AssignHomePageLinkModalFour />
    </>
  );
}

export default Home;
