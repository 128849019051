import React, { useEffect, useState } from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { useFarmData } from './FarmData-Context';
import '../Styles/SaveZonesModal.css';
import * as turf from '@turf/turf';
import { UpdateFieldsArray } from '../Util/PrecisionToolBox.js';

function SaveZonesModal() {
  const [analysisGeoJson, setAnalysisGeoJson] = useState([]);
  const [analysisFeatureCollection, setAnalysisFeatureCollection] = useState(
    [],
  );
  const [zonesArrayState, setZoneArrayState] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedEdit, setSelectedEdit] = useState('');
  const [selectedUM, setSelectedUM] = useState('');
  const [selectedAnalysisName, setSelectedAnalysisName] = useState('');
  const [selectedAnalysisType, setSelectedAnalysisType] = useState('');
  const { polygonZonesGlobal, SetPolygonZonesState } = useSoilHealthContext();
  const [invalidEntry, setInvalidEntry] = useState('');
  const { UploadAnalysis } = useFarmData();

  useEffect(() => {
    const cutFeatures = [];
    const d = new Date();

    for (let i = 0; i < polygonZonesGlobal.length; i++) {
      cutFeatures.push(
        turf.polygon(polygonZonesGlobal[i].coordinates, { Feature_Id: i }),
      );
      turf.featureCollection(polygonZonesGlobal);
    }

    const currentFeatureCollection = turf.featureCollection(cutFeatures);
    setAnalysisFeatureCollection(currentFeatureCollection);
    setAnalysisGeoJson(cutFeatures);

    setSelectedYear(d.getFullYear());
    setSelectedEdit('Non-Editable');
    setSelectedUM('Kg/Ha');
    setSelectedAnalysisName('');

    if (window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      const map = new window.google.maps.Map(
        document.getElementById('zonesinfomap'),
        {
          center: { lat: 52.349206954080564, lng: 0.923879995915898 },
          zoom: 16,
          mapTypeId: window.google.maps.MapTypeId.HYBRID,
          fullscreenControl: false,
          streetViewControl: false,
        },
      );

      for (var j = 0; j < zonesArrayState.length; j++) {
        zonesArrayState[j].setMap(null);
      }

      const colours = [
        '#fc0303',
        '#fcb103',
        '#f0fc03',
        '#6bfc03',
        '#03d7fc',
        '#0b03fc',
        '#d203fc',
        '#948e91',
      ];
      const zonesArray = [];
      let coloursSelect = 0;

      for (var j = 0; j < polygonZonesGlobal.length; j++) {
        const zoneCoords = [];
        for (let k = 0; k < polygonZonesGlobal[j].coordinates[0].length; k++) {
          const latLong = polygonZonesGlobal[j].coordinates[0][k];
          const googleLatLng = new window.google.maps.LatLng(
            latLong[1],
            latLong[0],
          );

          zoneCoords.push({ lat: latLong[1], lng: latLong[0] });
          bounds.extend(googleLatLng);
        }

        const zone = new window.google.maps.Polygon({
          map,
          paths: zoneCoords,
          editable: false,
        });

        zone.setOptions({
          zIndex: 20,
          fillColor: colours[coloursSelect],
          strokeColor: colours[coloursSelect],
        });

        coloursSelect++;

        if (coloursSelect > 7) {
          coloursSelect = 0;
        }

        zonesArray.push(zone);
      }

      if (zonesArray.length > 0) {
        map.setCenter(bounds.getCenter());
        map.setZoom(16);
        setZoneArrayState(zonesArray);
      }
    }
  }, [polygonZonesGlobal]);

  const HideOtherZone = async (id) => {
    for (let j = 0; j < zonesArrayState.length; j++) {
      if (id != j) {
        zonesArrayState[j].setOptions({ visible: false });
      }
    }
  };

  const ShowAllZones = async (id) => {
    for (let j = 0; j < zonesArrayState.length; j++) {
      zonesArrayState[j].setOptions({ visible: true });
    }
  };

  const ColourHoveredZone = async (id) => {
    for (let j = 0; j < zonesArrayState.length; j++) {
      if (id == j) {
        zonesArrayState[j].setOptions({ fillOpacity: 1 });
      }
    }
  };

  const ResetColourZone = async (id) => {
    for (let j = 0; j < zonesArrayState.length; j++) {
      zonesArrayState[j].setOptions({ fillOpacity: 0.5 });
    }
  };

  const d = new Date();
  const Years = [
    d.getFullYear(),
    d.getFullYear() - 1,
    d.getFullYear() - 2,
    d.getFullYear() - 3,
    d.getFullYear() - 4,
  ];

  const yearList = Years.map((item, i) => {
    const nextYear = parseInt(item) + 1;

    return (
      <option key={i} value={item}>
        {`${item}/${nextYear.toString()}`}
      </option>
    );
  }, this);

  const polygonZonesList = analysisGeoJson.length > 0
    && analysisGeoJson.map((item, i) => {
      return (
        <tr key={i} style={{ color: zonesArrayState[i].fillColor }}>
          <td>
            <p>{item.properties.Feature_Id}</p>
          </td>
          <td>
            <div className="rates-input-validation">
              <i>{invalidEntry[item.properties.Feature_Id]}</i>
            </div>
            <input
              id={`zone${item.properties.Feature_Id}`}
              type="text"
              placeholder="Value"
              className="form-control analysis-name-input"
              autoComplete="off"
              onMouseOver={(e) => {
                ColourHoveredZone(e.target.id.substring(4));
              }}
              onMouseLeave={(e) => {
                ResetColourZone(e.target.id.substring(4));
              }}
              onFocus={(e) => {
                HideOtherZone(e.target.id.substring(4));
              }}
              onBlur={(e) => {
                ShowAllZones(e.target.id.substring(4));
              }}
              onChange={(e) => {
                const patt = /^\d+(\.\d{1,2})?\.?$/;
                const result = patt.test(e.target.value);
                if (result || e.target.value === '') {
                  setInvalidEntry('');
                } else {
                  setInvalidEntry({
                    [item.properties.Feature_Id]:
                      'The value must be a number to max 2 decimal places.',
                  });

                  document.getElementById(
                    `zone${item.properties.Feature_Id}`,
                  ).value = e.target.value.substring(
                    0,
                    e.target.value.length - 1,
                  );
                }
              }}
              required
            />
            <div className="valid-tooltip">Looks good!</div>
          </td>
        </tr>
      );
    }, this);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const selectedFarm = document.getElementById('farm-select').value;
        const selectedField = document.getElementById('field-select').value;
        const selectedDirectoryPath = `${selectedFarm
        }/${
          selectedField
        }/${
          selectedYear
        }/${
          selectedAnalysisName}`;
        const propertyMapping = [
          {
            Property_Name: selectedAnalysisType,
            Mapping: selectedAnalysisType,
          },
        ];

        for (let i = 0; i < analysisFeatureCollection.features.length; i++) {
          analysisFeatureCollection.features[i].properties[
            selectedAnalysisType
          ] = parseFloat(
            document.getElementById(
              `zone${
                analysisFeatureCollection.features[i].properties.Feature_Id}`,
            ).value,
          );
        }

        const analysisObject = {
          Analysis_GeoJson: JSON.stringify(analysisFeatureCollection),
          Analysis_Name: selectedAnalysisName,
          Analysis_Type: selectedEdit,
          DirectoryPath: selectedDirectoryPath,
          Farm_ID: selectedFarm,
          Field_ID: selectedField,
          Property_Mapping: JSON.stringify(propertyMapping),
          Reports: undefined,
          Unit_Of_Measure: selectedUM,
          Year: selectedYear,
        };

        const analysisObjectList = [];
        analysisObjectList.push(analysisObject);

        UploadAnalysis(analysisObjectList);
        document.getElementById('create-zones-close').click();
      }}
    >
      <div
        className="modal fade"
        id="save-zones-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Zones Info
              </h5>
            </div>
            <div id="info-box-modal-body" className="modal-body">
              <div id="zonemetainfo">
                <table>
                  <thead>
                    <tr>
                      <th>Season</th>
                      <th>
                        Data type{' '}
                        <span
                          data-toggle="tooltip"
                          data-placement="right"
                          title="e.g. seed rate / nutrient / soil type"
                        >
                          <i className="infobox-help">
                            (<u>what's this?</u>)
                          </i>
                        </span>
                      </th>
                      <th>
                        Map layer{' '}
                        <span
                          data-toggle="tooltip"
                          data-placement="right"
                          title="e.g. Big Field VR Seed WW 2021"
                        >
                          <i className="infobox-help">
                            (<u>what's this?</u>)
                          </i>
                        </span>
                      </th>
                      <th>Type</th>
                      <th>Units</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          className="custom-select"
                          value={selectedYear}
                          onChange={(e) => {
                            setSelectedYear(e.target.value);
                          }}
                        >
                          {yearList}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="Analysis Name"
                          className="form-control analysis-name-input"
                          autoComplete="off"
                          value={selectedAnalysisName}
                          onChange={(e) => {
                            setSelectedAnalysisName(e.target.value);
                          }}
                          required
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="Analysis type"
                          className="form-control analysis-name-input"
                          autoComplete="off"
                          value={selectedAnalysisType}
                          onChange={(e) => {
                            setSelectedAnalysisType(e.target.value);
                          }}
                          required
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </td>
                      <td>
                        <select
                          className="custom-select analysis-type-select"
                          value={selectedEdit}
                          onChange={(e) => {
                            setSelectedEdit(e.target.value);
                          }}
                          required
                        >
                          <option value="Non-Editable">Non-Editable</option>
                          <option value="Editable">Editable</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="custom-select um-type-select"
                          value={selectedUM}
                          onChange={(e) => {
                            setSelectedUM(e.target.value);
                          }}
                          required
                        >
                          <option value="Kg/Ha">Kg/Ha</option>
                          <option value="Index">Index</option>
                          <option value="M/S">M/S</option>
                          <option value=" " />
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="zonespropertycontainer">
                <div id="zonespropertytablecontainer">
                  <table id="zonespropertytable">
                    <thead>
                      <tr>
                        <th>Zone</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>{polygonZonesList}</tbody>
                  </table>
                </div>
                <div id="zonesinfomap" />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="create-zones-close"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  SetPolygonZonesState([]);
                  UpdateFieldsArray();
                }}
              >
                Clear
              </button>
              <button type="submit" className="btn btn-success btn-ok">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default SaveZonesModal;
