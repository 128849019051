import React, { useState } from 'react';
import { useFarmData } from './FarmData-Context';
import '../Styles/SoilHealthPanelUsersModel.css';
import { useUsers } from './Users-Context.js';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';

function SoilHealthPanelUsersModel(props) {
  const { UpdateFarmFieldData } = useFarmData();
  const { GetUsers } = useUsers();
  const [accountName, setAccountName] = useState(null);
  const [accountEmail, setAccountEmail] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [cursorXPos, setCursorXPos] = useState(0);
  const [cursorDown, setCursorDown] = useState(false);
  const { SetAnalysisTypeState, SetFieldState } = useSoilHealthContext();

  const usersList = props.users.length > 0
    && props.users.map((item, i) => {
      return (
        <tr
          key={i}
          id={item.user_id}
          data-dismiss="modal"
          onClick={(e) => {
            SetFieldState('all fields');
            UpdateFarmFieldData(null, e.target.id);

            SetAnalysisTypeState('');
          }}
        >
          <td id={item.user_id}>
            {item.glgmnumber.length > 0 ? 'true' : 'false'}
          </td>
          <td id={item.user_id}>{item.ifsnumber}</td>
          <td id={item.user_id}>{item.name}</td>
          <td id={item.user_id}>{item.email}</td>
          <td id={item.user_id}>{item.farmname}</td>
          <td id={item.user_id}>{item.tradingname}</td>
          <td id={item.user_id}>{item.phonenumber}</td>
          <td id={item.user_id}>{item.mobilenumber}</td>
          <td id={item.user_id}>{item.county}</td>
          <td id={item.user_id}>{item.postcode}</td>
        </tr>
      );
    }, this);

  return (
    <div
      className="modal fade"
      id="user-search"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog mw-100 w-95 modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="container">
              <h4 className="text-center">
                <i>User Select</i>
              </h4>
              <form>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Name"
                    aria-label="Account Name"
                    aria-describedby="button-addon"
                    onInput={(e) => {
                      if (e.target.value.length > 3) {
                        setAccountName(e.target.value);
                        GetUsers(e.target.value, accountEmail, pageNumber);
                      } else {
                        setAccountName(null);
                        GetUsers(null, accountEmail, pageNumber);
                      }
                    }}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Email"
                    aria-label="Account Email"
                    aria-describedby="button-addon"
                    onInput={(e) => {
                      if (e.target.value.length > 3) {
                        setAccountEmail(e.target.value);
                        GetUsers(accountName, e.target.value, pageNumber);
                      } else {
                        setAccountEmail(null);
                        GetUsers(accountName, null, pageNumber);
                      }
                    }}
                  />
                </div>
              </form>
            </div>
            <button
              id="user-search-close"
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div
            className="modal-body user-search-modal-body"
            id="user-select-body"
            onMouseMove={(e) => {
              if (cursorDown) {
                document
                  .getElementById('user-select-body')
                  .scrollTo(
                    document.getElementById('user-select-body').scrollLeft
                      + (cursorXPos - e.pageX),
                    document.getElementById('user-select-body').scrollTop,
                  );
              }
            }}
            onMouseDown={(e) => {
              setCursorDown(true);
              setCursorXPos(e.pageX);
            }}
            onMouseUp={(e) => {
              setCursorDown(false);
            }}
          >
            <table className="table table-striped admin-data-table">
              <thead>
                <tr>
                  <th>GLGM Linked</th>
                  <th>IFS No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Farm Name</th>
                  <th>Trading Name</th>
                  <th>Phone No</th>
                  <th>Mobile No</th>
                  <th>County</th>
                  <th>Postcode</th>
                </tr>
              </thead>
              <tbody>{usersList}</tbody>
            </table>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li
                  className={
                    pageNumber == 0 ? 'page-item disabled' : 'page-item'
                  }
                >
                  <a
                    className="page-link"
                    href="#"
                    tabIndex="-1"
                    onClick={() => {
                      setPageNumber(pageNumber - 1);
                      GetUsers(accountName, accountEmail, pageNumber - 1);
                    }}
                  >
                    Previous
                  </a>
                </li>
                <li
                  className={
                    pageNumber == 0 ? 'page-item disabled' : 'page-item'
                  }
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => {
                      setPageNumber(pageNumber - 1);
                      GetUsers(accountName, accountEmail, pageNumber - 1);
                    }}
                  >
                    {pageNumber}
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    {pageNumber + 1}
                  </a>
                </li>
                <li className={!usersList ? 'page-item disabled' : 'page-item'}>
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => {
                      setPageNumber(pageNumber + 1);
                      GetUsers(accountName, accountEmail, pageNumber + 1);
                    }}
                  >
                    {pageNumber + 2}
                  </a>
                </li>
                <li className={!usersList ? 'page-item disabled' : 'page-item'}>
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => {
                      setPageNumber(pageNumber + 1);
                      GetUsers(accountName, accountEmail, pageNumber + 1);
                    }}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoilHealthPanelUsersModel;
