import React, { useState, useEffect } from 'react';
import { useUsers } from './Users-Context.js';
import { useFarmData } from './FarmData-Context';
import { useIFS } from './IFSData-Context';
import '../Styles/InviteUser.css';

function InviteUser() {
  const {
    PostAuth0Users,
    SelectedGLGMFarm,
    SelectedIFSCustomer,
    SelectedPersonCode,
    UpdateIFSNumber,
    UpdateGLGMNumber,
    UpdatePersonCode,
  } = useUsers();
  const [emailDomain, setEmailDomain] = useState('@gmail.com');
  const [email, setEmail] = useState('');
  const [ifsNumber, setIfsNumber] = useState('');
  const [glgmNumber, setGlgmNumber] = useState('');
  const [personCode, setPersonCode] = useState('');
  const [customerFieldDisplay, setCustomerFieldDisplay] = useState(true);
  const { GetGLGMFarms } = useFarmData();
  const { GetIFSCustomers, GetIFSUsers } = useIFS();

  useEffect(() => {
    setGlgmNumber(SelectedGLGMFarm);
    setIfsNumber(SelectedIFSCustomer);
    setPersonCode(SelectedPersonCode);
  });

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h4 className="text-center">
            <i>Invite User</i>
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (emailDomain === 'other') {
                PostAuth0Users(email.trim(), ifsNumber, glgmNumber, personCode);
              } else {
                PostAuth0Users(
                  email.trim() + emailDomain,
                  ifsNumber,
                  glgmNumber,
                  personCode,
                );
              }

              setEmail('');
              UpdateIFSNumber('inviteuser', '');
              UpdateGLGMNumber('inviteuser', '');
              UpdatePersonCode('inviteuser', '');
            }}
          >
            <div className="form-row">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="basic-addon2"
                  autoComplete="off"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
                <div className="input-group-append">
                  <select
                    className="input-group-text"
                    id="basic-addon2"
                    onChange={(e) => {
                      setEmailDomain(e.target.value);
                      setEmail('');
                      setIfsNumber('');
                      setGlgmNumber('');
                      setPersonCode('');
                      if (e.target.value === '@agrovista.co.uk') {
                        setCustomerFieldDisplay(false);
                      } else {
                        setCustomerFieldDisplay(true);
                      }
                    }}
                  >
                    <option value="@gmail.com">@gmail.com</option>
                    <option value="@outlook.com">@outlook.com</option>
                    <option value="@agrovista.co.uk">@agrovista.co.uk</option>
                    <option value="other">other (please specify)</option>
                  </select>
                </div>
              </div>
            </div>
            {customerFieldDisplay ? (
              <>
                <div className="form-row">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control invite-ifsno-readonly"
                      placeholder="IFS Customer No"
                      aria-label="IFS Customer No"
                      aria-describedby="button-addon"
                      autoComplete="off"
                      value={ifsNumber}
                      onChange={(e) => {
                        setIfsNumber(e.target.value);
                      }}
                      onKeyDown={() => {
                        return false;
                      }}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon"
                        data-toggle="modal"
                        data-target="#search-ifs-customers"
                        onClick={() => {
                          GetIFSCustomers();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="GLGM Reference"
                      aria-label="GLGM Reference"
                      aria-describedby="button-addon"
                      autoComplete="off"
                      value={glgmNumber}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon"
                        data-target="#search-glgm-farms"
                        data-toggle="modal"
                        onClick={() => {
                          GetGLGMFarms();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="form-row">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control invite-personcode-readonly"
                    placeholder="IFS Person Code"
                    aria-label="IFS Person Code"
                    aria-describedby="button-addon"
                    autoComplete="off"
                    value={personCode}
                    onChange={(e) => {
                      setPersonCode(e.target.value);
                    }}
                    onKeyDown={() => {
                      return false;
                    }}
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon"
                      data-toggle="modal"
                      data-target="#search-ifs-users"
                      onClick={() => {
                        GetIFSUsers();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            )}
            <button type="submit" className="btn btn-primary">
              Invite
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default InviteUser;
