import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/Components/SoilHealthPanel/ViewReportButton.css';
import { startCase, find } from 'lodash';
import { useFarmData } from '../FarmData-Context';
import { useAuth0 } from '../../Auth/Auth0-Context';
import { GenerateReportsList } from '../../Util/ExtractReports';

function ViewReportButton({ reportName, sectionTitle }) {
  const { user } = useAuth0();
  const {
    SetSelectedPDFURLState,
    GetSoilHealthDocs,
    CurrentFarm,
    CurrentField,
    CurrentUser,
    CurrentValidFromDate,
    CurrentValidToDate,
  } = useFarmData();

  const handleButtonClick = async () => {
    const farm = CurrentFarm;
    const field = CurrentField !== 'all fields' ? CurrentField : null;
    const selectedUser = user !== CurrentUser ? CurrentUser : null;

    const soilHealthDocument = await GetSoilHealthDocs({
      farm,
      reportType: startCase(sectionTitle),
      validFrom: CurrentValidFromDate,
      validTo: CurrentValidToDate,
      reportName,
      field,
      selectedUser,
      documentSummary: false,
    });

    const updatedReportsList = await GenerateReportsList({
      reports: soilHealthDocument,
    });

    const filteredReport = find(updatedReportsList, ({ name }) => name === `${reportName}.pdf`);

    SetSelectedPDFURLState(filteredReport.url);
  };

  return (
    <>
      <Button className="view__report__button" variant="outline-warning" onClick={handleButtonClick}>
        <FontAwesomeIcon icon={faFilePdf} />
        <div>{reportName}</div>
      </Button>
    </>
  );
}

export default ViewReportButton;
