import React from 'react';
import { useFarmData } from './FarmData-Context';
import Options from './Common/Options';

function FarmList() {
  const { Farms = [] } = useFarmData();

  return <Options items={Farms} />;
}

export default FarmList;
