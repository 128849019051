import React, { Component, createContext, useContext } from 'react';
import { includes } from 'lodash';
import { httpStatusCodes } from '../Models/HttpStatusCodes';
import {
  CreateSoilHealthDocs,
  ReadSoilHealthDocs,
  DeleteSoilHealthDocs,
} from '../APIs/SoilHealthDocsAPI';
import { addSoilHealthReportSection } from '../Util/FindSoilHealthReportSection';
import { ReadSoilHealthGlossary, CreateSoilHealthGlossary } from '../APIs/SoilHealthGlossaryAPI';
import { FormatDate } from '../Util/FormatDate';

export const FarmDataContext = createContext();
export const useFarmData = () => useContext(FarmDataContext);

class FarmDataProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GLGMFarms: [],
      Markers: [],
      Measures: [],
      GLGMChanged: false,
      Farms: [],
      GCIStations: [],
      GCIStationLinks: [],
      Fields: [],
      UpdatedField: '',
      CurrentUser: null,
      CurrentFarm: null,
      CurrentField: 'all fields',
      CurrentValidFromDate: FormatDate({ date: new Date() }),
      CurrentValidToDate: null,
      FieldAnalysis: [],
      AnalysisToDelete: null,
      AnalysisUploads: [],
      ExistingUploads: [],
      SupportedHomePageLinks: [],
      ImagePODs: [],
      FarmMessage: '',
      UserProfile: [],
      DeliveryNotifications: [],
      SoilHealthReports: [],
      SoilHealthGlossary: null,
      ShowErrorModal: false,
      ShowUploadSoilHealthReportModal: false,
      SelectUserModal: false,
      SelectedPDFURL: null,
      TriggerSoilHealthReportsUpdate: true,
      isLoading: false,
      disableFields: true,
    };

    this.SetDisableFieldsState = this.SetDisableFieldsState.bind(this);
    this.SetAnalysisUploadsState = this.SetAnalysisUploadsState.bind(this);
    this.SetExistingUploadsState = this.SetExistingUploadsState.bind(this);
    this.SetFieldsState = this.SetFieldsState.bind(this);
    this.GetGLGMFarms = this.GetGLGMFarms.bind(this);
    this.PostMeasure = this.PostMeasure.bind(this);
    this.GetMeasures = this.GetMeasures.bind(this);
    this.DeleteMeasure = this.DeleteMeasure.bind(this);
    this.PostMarker = this.PostMarker.bind(this);
    this.GetMarkers = this.GetMarkers.bind(this);
    this.DeleteMarker = this.DeleteMarker.bind(this);
    this.UpdateFarmFieldData = this.UpdateFarmFieldData.bind(this);
    this.ConvertShapeFiles = this.ConvertShapeFiles.bind(this);
    this.UploadAnalysis = this.UploadAnalysis.bind(this);
    this.UploadHomePageLink = this.UploadHomePageLink.bind(this);
    this.GetHomePageLink = this.GetHomePageLink.bind(this);
    this.DeleteHomePageLink = this.DeleteHomePageLink.bind(this);
    this.DownloadFiles = this.DownloadFiles.bind(this);
    this.GetAnalysis = this.GetAnalysis.bind(this);
    this.DeleteAnalysis = this.DeleteAnalysis.bind(this);
    this.UpdateAnalysisData = this.UpdateAnalysisData.bind(this);
    this.UpdateFieldAnalysisState = this.UpdateFieldAnalysisState.bind(this);
    this.PostFarm = this.PostFarm.bind(this);
    this.PutFarm = this.PutFarm.bind(this);
    this.DeleteFarm = this.DeleteFarm.bind(this);
    this.PostField = this.PostField.bind(this);
    this.UpdateField = this.UpdateField.bind(this);
    this.DeleteField = this.DeleteField.bind(this);
    this.GetUserProfile = this.GetUserProfile.bind(this);
    this.PostUserProfile = this.PostUserProfile.bind(this);
    this.DeleteUserProfile = this.DeleteUserProfile.bind(this);
    this.GetGCIStations = this.GetGCIStations.bind(this);
    this.GetGCIStationLinks = this.GetGCIStationLinks.bind(this);
    this.GetDeliveryNotifications = this.GetDeliveryNotifications.bind(this);
    this.GetImagePODs = this.GetImagePODs.bind(this);
    this.PostSoilHealthDocs = this.PostSoilHealthDocs.bind(this);
    this.GetSoilHealthDocs = this.GetSoilHealthDocs.bind(this);
    this.GetSoilHealthGlossary = this.GetSoilHealthGlossary.bind(this);
    this.PostSoilHealthGlossary = this.PostSoilHealthGlossary.bind(this);
    this.DeleteSoilHealthDocument = this.DeleteSoilHealthDocument.bind(this);
    this.SetShowErrorModalState = this.SetShowErrorModalState.bind(this);
    this.SetSelectedPDFURLState = this.SetSelectedPDFURLState.bind(this);
    this.SetTriggerSoilHealthReportsUpdateState = this.SetTriggerSoilHealthReportsUpdateState.bind(this);
    this.SetUploadSoilHealthReportModalState = this.SetUploadSoilHealthReportModalState.bind(this);
    this.SetSelectUserModalState = this.SetSelectUserModalState.bind(this);
    this.SetFarmState = this.SetFarmState.bind(this);
    this.SetSoilHealthReportsState = this.SetSoilHealthReportsState.bind(this);
    this.SetCurrentUserState = this.SetCurrentUserState.bind(this);
    this.SetGLGMChangedState = this.SetGLGMChangedState.bind(this);
    this.SetCurrentFarmState = this.SetCurrentFarmState.bind(this);
    this.SetCurrentFieldState = this.SetCurrentFieldState.bind(this);
    this.SetCurrentValidFromDateState = this.SetCurrentValidFromDateState.bind(this);
    this.SetCurrentValidToDateState = this.SetCurrentValidToDateState.bind(this);
    this.SetAnalysisToDeleteState = this.SetAnalysisToDeleteState.bind(this);
    this.SetFarmMessageState = this.SetFarmMessageState.bind(this);
    this.SetIsLoadingState = this.SetIsLoadingState.bind(this);
  }

  componentDidMount() {
    if (window.location.pathname !== '/signup') {
      const currentComponent = this;

      async function fetchData(props) {
        const token = await props.token;
        currentComponent.setState({
          isLoading: true,
        });

        fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/farms`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (response.ok) {
              if (response.status === 204) {
                currentComponent.setState({
                  isLoading: false,
                });
              } else {
                response.json().then((farmData) => {
                  const farmsFromApi = farmData.map((farm) => {
                    return {
                      key: farm.farm_ID,
                      name: farm.farm_Name,
                      display: farm.farm_Name,
                      glgmref: farm.glgM_Ref,
                      ifsref: farm.ifS_Ref,
                      userid: farm.auth0_User_ID,
                    };
                  });

                  currentComponent.setState({
                    Farms: farmsFromApi,
                  });

                  if (farmData.length > 0) {
                    fetch(
                      `${process.env.REACT_APP_AZURE_API_DOMAIN
                      }api/v1/fields/${
                        farmData[0].farm_ID}`,
                      {
                        method: 'GET',
                        headers: {
                          Accept: 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    ).then((response) => {
                      if (response.ok) {
                        if (response.status === 204) {
                          currentComponent.setState({
                            isLoading: false,
                          });
                        } else {
                          response.json().then((fieldData) => {
                            const fieldsFromApi = fieldData.map((field) => {
                              return {
                                key: field.field_ID,
                                name: field.field_Name,
                                fieldCoords: field.boundary_GeoJson,
                              };
                            });

                            currentComponent.setState({
                              Fields: fieldsFromApi,
                            });

                            // analysis here

                            if (farmData.length > 0 && fieldData.length > 0) {
                              fetch(
                                `${process.env.REACT_APP_AZURE_API_DOMAIN
                                }api/v1/geojson/?farmid=${
                                  farmData[0].farm_ID
                                }&fieldid=${
                                  fieldData[0].field_ID
                                }&year=`
                                  + '2020',
                                {
                                  method: 'GET',
                                  headers: {
                                    Accept: 'application/json',
                                    Authorization: `Bearer ${token}`,
                                  },
                                },
                              )
                                .then((response) => {
                                  if (response.ok) {
                                    if (response.status === 204) {
                                      currentComponent.setState({
                                        isLoading: false,
                                      });
                                    } else {
                                      response.json().then((analysisData) => {
                                        const analysisFromApi = analysisData.map(
                                          (analysis) => {
                                            return {
                                              farmid: analysis.farm_ID,
                                              fieldid: analysis.field_ID,
                                              geojson:
                                                analysis.analysis_GeoJson,
                                              name: analysis.analysis_Name,
                                              type: analysis.analysis_Type,
                                              unitofmeasure:
                                                analysis.unit_Of_Measure,
                                              year: analysis.year,
                                              mapping:
                                                analysis.property_Mapping,
                                            };
                                          },
                                        );

                                        currentComponent.setState({
                                          FieldAnalysis: analysisFromApi,
                                          isLoading: false,
                                        });
                                      });
                                    }
                                  } else if (
                                    response.status === 401
                                    || response.status === 403
                                  ) {
                                    currentComponent.setState({
                                      FarmMessage:
                                        'You do not have permission to access analysis data.',
                                      isLoading: false,
                                    });
                                    document
                                      .getElementById(
                                        'error-message-modal-trigger',
                                      )
                                      .click();
                                  } else {
                                    response.json().then((error) => {
                                      currentComponent.setState({
                                        FarmMessage: error.message,
                                        isLoading: false,
                                      });
                                      document
                                        .getElementById(
                                          'error-message-modal-trigger',
                                        )
                                        .click();
                                    });
                                  }
                                })
                                .catch((error) => {
                                  currentComponent.setState({
                                    FarmMessage: error,
                                    isLoading: false,
                                  });
                                  document
                                    .getElementById(
                                      'error-message-modal-trigger',
                                    )
                                    .click();
                                });
                            }

                            currentComponent.setState({
                              isLoading: false,
                            });
                          });
                        }
                      } else if (
                        response.status === 401
                        || response.status === 403
                      ) {
                        currentComponent.setState({
                          FarmMessage:
                            'you do not have permission to access field data.',
                          isLoading: false,
                        });

                        document
                          .getElementById('error-message-modal-trigger')
                          .click();
                      } else {
                        response.json().then((error) => {
                          currentComponent.setState({
                            FarmMessage: error.message,
                            isLoading: false,
                          });

                          document
                            .getElementById('error-message-modal-trigger')
                            .click();
                        });
                      }
                    });
                  }

                  currentComponent.setState({
                    isLoading: false,
                  });
                });
              }
            } else if (response.status === 401 || response.status === 403) {
              currentComponent.setState({
                FarmMessage: 'you do not have permission to access farm data.',
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            } else {
              response.json().then((error) => {
                currentComponent.setState({
                  FarmMessage: error.message,
                  isLoading: false,
                });

                document.getElementById('error-message-modal-trigger').click();
              });
            }
          })
          .catch((error) => {
            currentComponent.setState({
              FarmMessage: error,
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          });
      }

      fetchData(this.props);
    }
  }

  async SetAnalysisUploadsState(analysisuploadsstate = null) {
    if (analysisuploadsstate === null) {
      this.setState({
        AnalysisUploads: [],
      });
    } else {
      this.setState({
        AnalysisUploads: analysisuploadsstate,
      });
    }
  }

  async SetExistingUploadsState() {
    this.setState({
      ExistingUploads: [],
    });
  }

  async SetFieldsState() {
    this.setState({
      Fields: [],
    });
  }

  async GetGLGMFarms(
    ifsnumber = null,
    accountname = null,
    pagenumber = 0,
  ) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      let firstParam = true;

      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/glgmfarms`;

      if (ifsnumber !== null && ifsnumber.length > 0) {
        if (firstParam) {
          url += `?ifsnumber=${ifsnumber}`;
          firstParam = false;
        } else {
          url += `&ifsnumber=${ifsnumber}`;
        }
      }

      if (accountname !== null && accountname.length > 0) {
        if (firstParam) {
          url += `?accountname=${accountname}`;
          firstParam = false;
        } else {
          url += `&accountname=${accountname}`;
        }
      }

      if (firstParam) {
        url += `?page=${pagenumber}`;
        firstParam = false;
      } else {
        url += `&page=${pagenumber}`;
      }

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((glgmfarms) => {
                const glgmFarmsFromApi = glgmfarms.map((farms) => {
                  return {
                    key: farms.glgM_Site_Reference,
                    ifsnumber: farms.ifS_Number,
                    name: farms.name,
                    address1: farms.address1,
                    towncity: farms.townCity,
                    postcode: farms.postcode,
                  };
                });

                currentComponent.setState({
                  GLGMFarms: glgmFarmsFromApi,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access glgm farms.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PostMeasure(measure) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/measures`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(measure),
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((measurelist) => {
                const measureListFromApi = measurelist.map((measure) => {
                  return {
                    measureid: measure.measure_ID,
                    farmid: measure.farm_ID,
                    fieldid: measure.field_ID,
                    measuretype: measure.measure_Type,
                    label: measure.label,
                    category: measure.category,
                    measureyear: measure.measure_year,
                    measure: measure.measure,
                    unitofmeasure: measure.unit_Of_Measure,
                    startmarkerlat: measure.start_Marker_Lat,
                    startmarkerlng: measure.start_Marker_Lng,
                    endmarkerlat: measure.end_Marker_Lat,
                    endmarkerlng: measure.end_Marker_Lng,
                    polygonpath: measure.polygon_Path,
                  };
                });

                currentComponent.setState({
                  Measures: measureListFromApi,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access measures.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetMeasures(farmid, fieldid, category = null) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;

      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN
      }api/v1/measures?farmid=${
        farmid
      }&fieldid=${
        fieldid
      }&category=${
        category}`;

      if (category === null) {
        url = `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/measures?farmid=${
          farmid
        }&fieldid=${
          fieldid}`;
      }

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((measurelist) => {
                const measureListFromApi = measurelist.map((measure) => {
                  return {
                    measureid: measure.measure_ID,
                    farmid: measure.farm_ID,
                    fieldid: measure.field_ID,
                    measuretype: measure.measure_Type,
                    label: measure.label,
                    category: measure.category,
                    measureyear: measure.measure_year,
                    measure: measure.measure,
                    unitofmeasure: measure.unit_Of_Measure,
                    startmarkerlat: measure.start_Marker_Lat,
                    startmarkerlng: measure.start_Marker_Lng,
                    endmarkerlat: measure.end_Marker_Lat,
                    endmarkerlng: measure.end_Marker_Lng,
                    polygonpath: measure.polygon_Path,
                  };
                });

                currentComponent.setState({
                  Measures: measureListFromApi,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access measures.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DeleteMeasure(measureid) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;

      const url = `${process.env.REACT_APP_AZURE_API_DOMAIN
      }api/v1/measures?id=${
        measureid}`;

      fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((deletedmeasure) => {
                const updatedExistingMeasures = [];

                for (
                  let i = 0;
                  i < currentComponent.state.Measures.length;
                  i++
                ) {
                  if (
                    currentComponent.state.Measures[i].measureid
                    !== deletedmeasure.measure_ID
                  ) {
                    updatedExistingMeasures.push(
                      currentComponent.state.Measures[i],
                    );
                  }
                }

                currentComponent.setState({
                  Measures: updatedExistingMeasures,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access measures.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PostMarker(farmid, fieldid, description, latitude, longitude) {
    const currentComponent = this;

    const marker = {
      Farm_ID: farmid,
      Field_ID: fieldid,
      Description: description,
      Latitude: latitude.toString(),
      Longitude: longitude.toString(),
    };
    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/markers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(marker),
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((markerlist) => {
                const markerListFromApi = markerlist.map((marker) => {
                  return {
                    markerid: marker.marker_ID,
                    farmid: marker.farm_ID,
                    fieldid: marker.field_ID,
                    description: marker.description,
                    latitude: marker.latitude,
                    longitude: marker.longitude,
                  };
                });

                currentComponent.setState({
                  Markers: markerListFromApi,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access markers.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetMarkers(farmid, fieldid) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      let firstParam = true;

      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/markers`;

      if (farmid !== null && farmid.length > 0) {
        if (firstParam) {
          url += `?farmid=${farmid}`;
          firstParam = false;
        } else {
          url += `&farmid=${farmid}`;
        }
      }

      if (fieldid !== null && fieldid.length > 0) {
        if (firstParam) {
          url += `?fieldid=${fieldid}`;
          firstParam = false;
        } else {
          url += `&fieldid=${fieldid}`;
        }
      }

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((markerlist) => {
                const markerListFromApi = markerlist.map((marker) => {
                  return {
                    markerid: marker.marker_ID,
                    farmid: marker.farm_ID,
                    fieldid: marker.field_ID,
                    description: marker.description,
                    latitude: marker.latitude,
                    longitude: marker.longitude,
                  };
                });

                currentComponent.setState({
                  Markers: markerListFromApi,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access markers.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DeleteMarker(markerid) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;

      const url = `${process.env.REACT_APP_AZURE_API_DOMAIN
      }api/v1/markers?id=${
        markerid}`;

      fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((deletedmarker) => {
                const updatedExistingMarkers = [];

                for (
                  let i = 0;
                  i < currentComponent.state.Markers.length;
                  i++
                ) {
                  if (
                    currentComponent.state.Markers[i].markerid
                    !== deletedmarker.marker_ID
                  ) {
                    updatedExistingMarkers.push(
                      currentComponent.state.Markers[i],
                    );
                  }
                }

                currentComponent.setState({
                  Markers: updatedExistingMarkers,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access markers.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async UpdateFarmFieldData(farmkey = null, userid = null) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/farms`;

      if (userid !== null) {
        url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/farms/${userid}`;

        currentComponent.setState({ CurrentUser: userid });
      }

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((farmData) => {
                if (farmkey === null && farmData[0] !== undefined) {
                  farmkey = farmData[0].farm_ID;
                }
                const farmsFromApi = farmData.map((farm) => {
                  return {
                    key: farm.farm_ID,
                    name: farm.farm_Name,
                    display: farm.farm_Name,
                    glgmref: farm.glgM_Ref,
                    ifsref: farm.ifS_Ref,
                    userid: farm.auth0_User_ID,
                  };
                });

                fetch(
                  `${process.env.REACT_APP_AZURE_API_DOMAIN
                  }api/v1/fields/${
                    farmkey}`,
                  {
                    method: 'GET',
                    headers: {
                      Accept: 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
                  },
                ).then((response) => {
                  if (response.ok) {
                    if (response.status === 204) {
                      currentComponent.setState({
                        isLoading: false,
                      });
                    } else {
                      response.json().then((fieldData) => {
                        const fieldsFromApi = fieldData.map((field) => {
                          return {
                            key: field.field_ID,
                            farmkey,
                            name: field.field_Name,
                            fieldCoords: field.boundary_GeoJson,
                          };
                        });

                        currentComponent.setState({
                          Farms: farmsFromApi,
                          Fields: fieldsFromApi,
                        });

                        if (
                          farmsFromApi.length > 0
                          && fieldsFromApi.length > 0
                        ) {
                          let yearSelected = '2020';
                          if (
                            document.getElementById('year-select') !== null
                            && document.getElementById('year-select').value
                              .length > 0
                          ) {
                            yearSelected = document.getElementById('year-select').value;
                          }

                          if (
                            document.getElementById('farm-select') !== null
                            && document.getElementById('field-select') !== null
                          ) {
                            currentComponent.UpdateAnalysisData(
                              document.getElementById('farm-select').value,
                              document.getElementById('field-select').value,
                              yearSelected,
                            );

                            currentComponent.GetMarkers(
                              document.getElementById('farm-select').value,
                              document.getElementById('field-select').value,
                            );
                          } else {
                            currentComponent.UpdateAnalysisData(
                              farmsFromApi[0].key,
                              fieldsFromApi[0].key,
                              '2020',
                            );

                            currentComponent.GetMarkers(
                              farmsFromApi[0].key,
                              fieldsFromApi[0].key,
                            );
                          }
                        } else {
                          currentComponent.setState({
                            FieldAnalysis: [],
                          });
                        }

                        currentComponent.setState({
                          isLoading: false,
                        });
                      });
                    }
                  } else if (
                    response.status === 401
                    || response.status === 403
                  ) {
                    currentComponent.setState({
                      FarmMessage:
                        'you do not have permission to access field data.',
                      isLoading: false,
                    });

                    document
                      .getElementById('error-message-modal-trigger')
                      .click();
                  } else {
                    response.json().then((error) => {
                      currentComponent.setState({
                        FarmMessage: error.message,
                        isLoading: false,
                      });

                      document
                        .getElementById('error-message-modal-trigger')
                        .click();
                    });
                  }
                });

                currentComponent.setState({
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access farm data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async ConvertShapeFiles(files) {
    const currentComponent = this;

    currentComponent.setState({
      isLoading: true,
    });

    async function fetchData(props) {
      const token = await props.token;

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i], files[i].name);
      }

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/geojson`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((geojsondata) => {
                const geoJsonFromApi = geojsondata.map((geojson, i) => {
                  return {
                    name: geojson.analysis_Name,
                    geojson: JSON.parse(geojson.analysis_GeoJson),
                    mapping: JSON.parse(geojson.property_Mapping),
                  };
                });

                currentComponent.setState({
                  AnalysisUploads: geoJsonFromApi,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access geojson data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async UploadAnalysis(analysis) {
    const currentComponent = this;

    currentComponent.setState({
      isLoading: true,
    });

    async function fetchData(props) {
      const token = await props.token;
      let fileSize = 0;
      const json = JSON.stringify(analysis);
      const blob = new Blob([json], {
        type: 'application/json',
      });
      const data = new FormData();
      data.append('json', blob);

      fileSize += blob.size;

      for (let i = 0; i < analysis.length; i++) {
        if (analysis[i].Reports !== undefined) {
          for (let j = 0; j < analysis[i].Reports.length; j++) {
            fileSize += analysis[i].Reports[j].size;
            data.append(
              analysis[i].DirectoryPath,
              analysis[i].Reports[j],
              analysis[i].Reports[j].name,
            );
          }
        }
      }

      fileSize /= 1024;
      fileSize /= 1024;

      if (fileSize > 500) {
        currentComponent.setState({
          FarmMessage:
            'The total file sizes must be less than 500mb. Try uploading the Analysis in smaller bulk.',
        });

        document.getElementById('error-message-modal-trigger').click();

        return;
      }

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/analysis`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
        body: data,
      })
        .then((response) => {
          if (response.ok) {
            for (let i = 0; i < analysis.length; i++) {
              currentComponent.UpdateAnalysisData(
                analysis[i].Farm_ID,
                analysis[i].Field_ID,
              );
            }

            currentComponent.setState({
              FarmMessage: 'Successfully uploaded the analysis',
              isLoading: false,
            });

            document.getElementById('message-modal-trigger').click();
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access analysis data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async UploadHomePageLink(websiteurl, imagefile) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      const data = new FormData();

      data.append(websiteurl, imagefile);

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/SupportedHomePageLinks`,
        {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
          body: data,
        },
      )
        .then((response) => {
          if (response.ok) {
            currentComponent.setState({
              isLoading: false,
            });

            currentComponent.GetHomePageLink();
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access supported home page links.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetHomePageLink() {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/SupportedHomePageLinks`,
        {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((homepageicons) => {
                const homePageIconsArray = [];

                for (let i = 0; i < homepageicons.length; i++) {
                  homePageIconsArray.push(homepageicons[i]);
                }

                currentComponent.setState({
                  SupportedHomePageLinks: homePageIconsArray,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access supported home page links.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access supported home page links.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }
    fetchData(this.props);
  }

  async DeleteHomePageLink(websiteurl) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/SupportedHomePageLinks?websiteurl=${
          websiteurl}`,
        {
          method: 'DELETE',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((homepageicons) => {
                const homePageIconsArray = [];

                for (let i = 0; i < homepageicons.length; i++) {
                  homePageIconsArray.push(homepageicons[i]);
                }

                currentComponent.setState({
                  isLoading: false,
                });

                currentComponent.GetHomePageLink();
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access supported home page links.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DownloadFiles(
    geojson,
    farmid,
    fieldid,
    year,
    analysisname,
    type,
  ) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/shapefiles`, {
        method: 'POST',
        body: JSON.stringify({
          Json: geojson,
          FarmId: farmid,
          FieldId: fieldid,
          Year: year,
          AnalysisName: analysisname,
          Type: type,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${analysisname}.zip`;
                document.body.appendChild(a);
                a.click();
                a.remove();

                currentComponent.setState({
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access shapefiles.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            currentComponent.setState({
              FarmMessage: 'error downloading files',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetAnalysis(farmid) {
    const currentComponent = this;

    currentComponent.setState({
      isLoading: true,
    });

    async function fetchData(props) {
      const token = await props.token;

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/analysis?farmid=${
          farmid}`,
        {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((analysisdata) => {
                const analysisFromApi = analysisdata.map((analysis, i) => {
                  return {
                    directorypath: analysis.directoryPath,
                    name: analysis.analysis_Name,
                    type: analysis.analysis_Type,
                    unitofmeasure: analysis.unit_Of_Measure,
                    farmid: analysis.farm_ID,
                    fieldid: analysis.field_ID,
                    year: analysis.year,
                    geojson:
                      analysis.analysis_GeoJson !== ''
                        ? JSON.parse(analysis.analysis_GeoJson)
                        : '',
                    mapping: JSON.parse(analysis.property_Mapping),
                  };
                });

                currentComponent.setState({
                  ExistingUploads: analysisFromApi,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access analysis data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DeleteAnalysis(directorypath) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;

      currentComponent.setState({
        isLoading: true,
      });

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/analysis?directorypath=${
          directorypath}`,
        {
          method: 'DELETE',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((deletedanalysis) => {
                const updatedExistingUploads = [];

                for (
                  let i = 0;
                  i < currentComponent.state.ExistingUploads.length;
                  i++
                ) {
                  if (
                    currentComponent.state.ExistingUploads[i].directorypath
                    !== deletedanalysis.directoryPath
                  ) {
                    updatedExistingUploads.push(
                      currentComponent.state.ExistingUploads[i],
                    );
                  }
                }

                currentComponent.setState({
                  ExistingUploads: updatedExistingUploads,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access analysis data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async UpdateAnalysisData(farmkey, fieldkey, yearkey) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: false,
      });

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/geojson/?farmid=${
          farmkey
        }&fieldid=${
          fieldkey}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((analysisData) => {
                const analysisFromApi = analysisData.map((analysis, i) => {
                  return {
                    farmid: analysis.farm_ID,
                    fieldid: analysis.field_ID,
                    geojson: analysis.analysis_GeoJson,
                    name: analysis.analysis_Name,
                    type: analysis.analysis_Type,
                    unitofmeasure: analysis.unit_Of_Measure,
                    year: analysis.year,
                    mapping: analysis.property_Mapping,
                  };
                });

                currentComponent.setState({
                  FieldAnalysis: analysisFromApi,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access geojson data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async UpdateFieldAnalysisState(fieldanalysis) {
    this.setState({ FieldAnalysis: fieldanalysis });
  }

  async PostFarm(
    currentuser,
    farmname,
    address1,
    address2,
    city,
    county,
    postcode,
    latitude,
    longitude,
  ) {
    const currentComponent = this;
    currentComponent.setState({
      isLoading: true,
    });

    const farm = {
      Auth0_User_ID: currentuser,
      Farm_Name: farmname,
      Address_1: address1,
      Address_2: address2,
      City: city,
      County: county,
      Postcode: postcode,
      Latitude: latitude.toString(),
      Longitude: longitude.toString(),
    };
    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/farms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(farm),
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((newfarm) => {
                const farm = {
                  key: newfarm.farm_ID,
                  display: newfarm.farm_Name,
                  name: newfarm.farm_Name,
                };
                currentComponent.state.Farms.push(farm);

                currentComponent.setState({
                  Farms: currentComponent.state.Farms,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access farm data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PutFarm(farms) {
    const currentComponent = this;
    currentComponent.setState({
      isLoading: true,
    });

    const farmList = [];

    for (let i = 0; i < farms.length; i++) {
      const farm = {
        Farm_ID: farms[i].key,
        Auth0_User_ID: farms[i].userid,
        GLGM_Ref: farms[i].glgmref,
      };

      farmList.push(farm);
    }

    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/farms`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(farmList),
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((farms) => {
                currentComponent.setState({
                  Farms: farms,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access farm data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DeleteFarm(id) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/farms?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((deletedfarm) => {
                const updatedExistingFarms = [];

                for (let i = 0; i < currentComponent.state.Farms.length; i++) {
                  if (
                    currentComponent.state.Farms[i].key !== deletedfarm.farm_ID
                  ) {
                    updatedExistingFarms.push(currentComponent.state.Farms[i]);
                  }
                }

                currentComponent.setState({
                  Farms: updatedExistingFarms,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access farm data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PostField(selectedFarm, fieldname) {
    const currentComponent = this;

    const field = {
      Farm_ID: selectedFarm,
      Field_Name: fieldname,
    };
    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/fields`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(field),
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((newfield) => {
                const field = {
                  key: newfield.field_ID,
                  name: newfield.field_Name,
                  fieldCoords: newfield.boundary_GeoJson,
                };
                currentComponent.state.Fields.push(field);

                currentComponent.setState({
                  Fields: currentComponent.state.Fields,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access field data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async UpdateField(field) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/fields`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(field),
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((updatedfield) => {
                for (let i = 0; i < currentComponent.state.Fields.length; i++) {
                  if (
                    currentComponent.state.Fields[i].key
                    === updatedfield.field_ID
                  ) {
                    currentComponent.state.Fields[i].fieldCoords = updatedfield.boundary_GeoJson;
                  }
                }

                currentComponent.setState({
                  UpdatedField:
                    currentComponent.state.UpdatedField + updatedfield.field_ID,
                  Fields: currentComponent.state.Fields,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access field data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DeleteField(id) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/fields?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((deletedfield) => {
                const updatedExistingFields = [];

                for (let i = 0; i < currentComponent.state.Fields.length; i++) {
                  if (
                    currentComponent.state.Fields[i].key
                    !== deletedfield.field_ID
                  ) {
                    updatedExistingFields.push(
                      currentComponent.state.Fields[i],
                    );
                  }
                }

                currentComponent.setState({
                  Fields: updatedExistingFields,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access field data.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetUserProfile() {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/userprofile`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((userprofile) => {
                currentComponent.setState({
                  UserProfile: [userprofile],
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access user profile.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PostUserProfile(websiteurl, homepagepanel) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/userprofile?websiteurl=${
          websiteurl
        }&homepagepanel=${
          homepagepanel}`,
        {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((userprofile) => {
                currentComponent.setState({
                  UserProfile: [userprofile],
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access user profile.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DeleteUserProfile(homepagepanel) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/userprofile?homepagepanel=${
          homepagepanel}`,
        {
          method: 'DELETE',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((userprofile) => {
                currentComponent.setState({
                  UserProfile: [userprofile],
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access user profile.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                FarmMessage: error.message,
                isLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetGCIStations() {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;

      fetch(`${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/GCIStations`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((gcistations) => {
                const gciStationArray = [];

                for (let i = 0; i < gcistations.length; i++) {
                  gciStationArray.push(gcistations[i]);
                }

                currentComponent.setState({
                  GCIStations: gciStationArray,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access GCI stations.',
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access GCI stations.',
            });

            document.getElementById('error-message-modal-trigger').click();
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetGCIStationLinks(stationname) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/GCIStationLinks?stationname=${
          stationname}`,
        {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((gcistationlinks) => {
                const gciStationLinksArray = [];

                for (let i = 0; i < gcistationlinks.length; i++) {
                  gciStationLinksArray.push(gcistationlinks[i]);
                }

                currentComponent.setState({
                  GCIStationLinks: gciStationLinksArray,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access GCI stations.',
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access GCI stations.',
            });

            document.getElementById('error-message-modal-trigger').click();
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetDeliveryNotifications(datefilter = null) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      let apiEndpoint = 'api/v1/DeliveryNotifications';

      if (datefilter !== null) {
        apiEndpoint += `?datefilter=${datefilter}`;
      }
      fetch(process.env.REACT_APP_AZURE_API_DOMAIN + apiEndpoint, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
              currentComponent.setState({
                isLoading: false,
                FarmMessage: 'No deliveries found for the selected date.',
              });

              document.getElementById('error-message-modal-trigger').click();
            } else {
              response.json().then((deliverynotifications) => {
                const deliveryNotificationsArray = [];

                if (deliverynotifications.length === 0) {
                  currentComponent.setState({
                    isLoading: false,
                    FarmMessage: 'No deliveries found for the selected date.',
                  });

                  document.getElementById('message-modal-trigger').click();
                }

                for (let i = 0; i < deliverynotifications.length; i++) {
                  deliveryNotificationsArray.push(deliverynotifications[i]);
                }

                currentComponent.setState({
                  DeliveryNotifications: deliveryNotificationsArray,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access delivery notifications.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            currentComponent.setState({
              FarmMessage:
                'you do not have permission to access delivery notifications.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetImagePODs(epodsiteid) {
    const currentComponent = this;

    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
        ImagePODs: [],
      });

      fetch(
        `${process.env.REACT_APP_AZURE_API_DOMAIN
        }api/v1/imagepods?epodsiteid=${
          epodsiteid}`,
        {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                isLoading: false,
              });
            } else {
              response.json().then((imagepods) => {
                const imagePODsArray = [];

                for (let i = 0; i < imagepods.length; i++) {
                  imagePODsArray.push(imagepods[i]);
                }

                currentComponent.setState({
                  ImagePODs: imagePODsArray,
                  isLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access Image PODs.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            currentComponent.setState({
              FarmMessage: 'you do not have permission to access Image PODs.',
              isLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          }
        })
        .catch((error) => {
          currentComponent.setState({
            FarmMessage: error,
            isLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PostSoilHealthDocs({
    file,
    farm,
    reportType,
    reportName,
    expirationDate = null,
    field = null,
    selectedUser = null,
  }) {
    this.setState({
      isLoading: true,
    });

    const jwtToken = await this.props.token;
    const response = await CreateSoilHealthDocs({
      file,
      jwtToken,
      farm,
      reportType,
      reportName,
      expirationDate,
      field,
      selectedUser,
    });

    this.setState({
      SoilHealthReports: [],
      TriggerSoilHealthReportsUpdate: true,
      isLoading: false,
    });

    return response;
  }

  async GetSoilHealthDocs({
    farm,
    reportType,
    validFrom = null,
    validTo = null,
    reportName = null,
    field = null,
    selectedUser = null,
    documentSummary = false,
  }) {
    this.setState({
      isLoading: true,
    });

    const jwtToken = await this.props.token;
    const response = await ReadSoilHealthDocs({
      jwtToken,
      farm,
      reportType,
      validFrom,
      validTo,
      reportName,
      field,
      selectedUser,
      documentSummary,
    });

    const soilHealthDocs = {
      farm: null,
      field: null,
      selectedUser: null,
      reportTypeArray: [],
    };

    const reportTypeObject = {
      reportType: null,
      reportsList: null,
      reportName: null,
    };

    soilHealthDocs.farm = farm;
    soilHealthDocs.field = field ?? 'allfields';
    soilHealthDocs.selectedUser = selectedUser ?? 'my reports';

    reportTypeObject.reportName = reportName ?? 'all reports';
    reportTypeObject.reportType = reportType;
    reportTypeObject.reportsList = documentSummary ? response.responseMessage : response.responseBlob;

    soilHealthDocs.reportTypeArray[0] = reportTypeObject;

    if (!documentSummary) {
      return reportTypeObject;
    }

    const currentReports = [...this.state.SoilHealthReports];

    addSoilHealthReportSection({
      reports: currentReports,
      section: reportType,
      newReport: soilHealthDocs,
    });

    const statusCodes = [httpStatusCodes.ok, httpStatusCodes.okEmpty];
    const { responseStatus } = response;

    if (includes(statusCodes, responseStatus)) {
      this.setState({
        SoilHealthReports: currentReports,
        isLoading: false,
        TriggerSoilHealthReportsUpdate: false,
      });
    }

    if (
      response.responseStatus === httpStatusCodes.badRequest
      || response.responseStatus === httpStatusCodes.forbidden
      || response.responseStatus === httpStatusCodes.unAuthorised
    ) {
      this.setState({
        FarmMessage: response.responseMessage,
        ShowErrorModal: true,
        isLoading: false,
        TriggerSoilHealthReportsUpdate: false,
      });
    }
  }

  async DeleteSoilHealthDocument({
    farm,
    reportType,
    reportName,
    field = null,
    selectedUser = null,
  }) {
    this.setState({
      isLoading: true,
    });

    const token = await this.props.token;
    const response = await DeleteSoilHealthDocs({
      jwtToken: token,
      farm,
      reportType,
      reportName,
      field,
      selectedUser,
    });

    let statusCodes = [httpStatusCodes.ok, httpStatusCodes.okEmpty];
    const { responseStatus, responseMessage } = response;

    if (includes(statusCodes, responseStatus)) {
      this.setState({
        SoilHealthReports: [],
        TriggerSoilHealthReportsUpdate: true,
        isLoading: false,
      });
    }

    statusCodes = [httpStatusCodes.badRequest, httpStatusCodes.forbidden, httpStatusCodes.unAuthorised];

    if (includes(statusCodes, responseStatus)) {
      this.setState({
        FarmMessage: responseMessage,
        ShowErrorModal: true,
        isLoading: false,
      });
    }
  }

  async GetSoilHealthGlossary() {
    this.setState({
      isLoading: true,
    });

    const jwtToken = await this.props.token;
    const response = await ReadSoilHealthGlossary({ jwtToken });

    let statusCodes = [httpStatusCodes.ok, httpStatusCodes.okEmpty];
    const { responseStatus, responseMessage } = response;

    if (includes(statusCodes, responseStatus)) {
      this.setState({
        SoilHealthGlossary: response.responseBlob,
        isLoading: false,
      });

      return;
    }

    statusCodes = [httpStatusCodes.badRequest, httpStatusCodes.forbidden, httpStatusCodes.unAuthorised];

    if (includes(statusCodes, responseStatus)) {
      this.setState({
        FarmMessage: responseMessage,
        ShowErrorModal: true,
        isLoading: false,
      });
    }
  }

  async PostSoilHealthGlossary({ file }) {
    this.setState({
      isLoading: true,
    });

    const jwtToken = await this.props.token;
    const response = await CreateSoilHealthGlossary({ file, jwtToken });

    let statusCodes = [httpStatusCodes.ok, httpStatusCodes.okEmpty];
    const { responseStatus, responseMessage } = response;

    if (includes(statusCodes, responseStatus)) {
      this.setState({
        SoilHealthGlossary: null,
        isLoading: false,
      });

      return;
    }

    statusCodes = [httpStatusCodes.badRequest, httpStatusCodes.forbidden, httpStatusCodes.unAuthorised];

    if (includes(statusCodes, responseStatus)) {
      this.setState({
        FarmMessage: responseMessage,
        ShowErrorModal: true,
        isLoading: false,
      });
    }
  }

  async SetDisableFieldsState(state) {
    this.setState({ disableFields: state });
  }

  async SetTriggerSoilHealthReportsUpdateState(state) {
    this.setState({ TriggerSoilHealthReportsUpdate: state });
  }

  async SetShowErrorModalState(modalState) {
    this.setState({ ShowErrorModal: modalState });
  }

  async SetUploadSoilHealthReportModalState(modalState) {
    this.setState({ ShowUploadSoilHealthReportModal: modalState });
  }

  async SetSelectUserModalState(modalState) {
    this.setState({ SelectUserModal: modalState });
  }

  async SetFarmState(farmkey, glgmnumber) {
    for (let i = 0; i < this.state.Farms.length; i++) {
      if (this.state.Farms[i].key === farmkey) {
        this.state.Farms[i].glgmref = glgmnumber;
      }
    }

    this.setState({ Farms: this.state.Farms });
  }

  async SetCurrentUserState(userid = null) {
    this.setState({ CurrentUser: userid });
  }

  async SetSelectedPDFURLState(pdfURL) {
    this.setState({ SelectedPDFURL: pdfURL });
  }

  async SetGLGMChangedState(changed) {
    this.setState({ GLGMChanged: changed });
  }

  async SetCurrentFarmState(farmkey) {
    this.setState({ CurrentFarm: farmkey });
  }

  async SetCurrentFieldState(fieldkey) {
    this.setState({ CurrentField: fieldkey });
  }

  async SetCurrentValidFromDateState(validfromkey) {
    this.setState({ CurrentValidFromDate: validfromkey });
  }

  async SetCurrentValidToDateState(validtokey) {
    this.setState({ CurrentValidToDate: validtokey });
  }

  async SetSoilHealthReportsState(soilHealthReportsKey) {
    this.setState({ SoilHealthReports: soilHealthReportsKey });
  }

  async SetAnalysisToDeleteState(directorypath) {
    this.setState({ AnalysisToDelete: directorypath });
  }

  async SetFarmMessageState(message) {
    this.setState({ FarmMessage: message });
  }

  async SetIsLoadingState(loading) {
    this.setState({ isLoading: loading });
  }

  render() {
    const { children } = this.props;
    const {
      GLGMFarms,
      GLGMChanged,
      Farms,
      Markers,
      Measures,
      GCIStations,
      GCIStationLinks,
      Fields,
      UpdatedField,
      FieldAnalysis,
      AnalysisUploads,
      ExistingUploads,
      CurrentUser,
      CurrentFarm,
      CurrentField,
      CurrentValidFromDate,
      CurrentValidToDate,
      AnalysisToDelete,
      SupportedHomePageLinks,
      ImagePODs,
      UserProfile,
      FarmMessage,
      DeliveryNotifications,
      SoilHealthReports,
      SoilHealthGlossary,
      ShowErrorModal,
      SelectedPDFURL,
      TriggerSoilHealthReportsUpdate,
      ShowUploadSoilHealthReportModal,
      SelectUserModal,
      isLoading,
      disableFields,
    } = this.state;
    const configObject = {
      GLGMFarms,
      GLGMChanged,
      Farms,
      Markers,
      Measures,
      GCIStations,
      GCIStationLinks,
      Fields,
      UpdatedField,
      FieldAnalysis,
      AnalysisUploads,
      ExistingUploads,
      CurrentUser,
      SupportedHomePageLinks,
      ImagePODs,
      UserProfile,
      DeliveryNotifications,
      SoilHealthReports,
      SoilHealthGlossary,
      ShowErrorModal,
      SelectedPDFURL,
      TriggerSoilHealthReportsUpdate,
      ShowUploadSoilHealthReportModal,
      SelectUserModal,
      isLoading,
      disableFields,
      CurrentFarm,
      CurrentField,
      CurrentValidFromDate,
      CurrentValidToDate,
      AnalysisToDelete,
      FarmMessage,
      SetDisableFieldsState: this.SetDisableFieldsState,
      SetSelectUserModalState: this.SetSelectUserModalState,
      SetUploadSoilHealthReportModalState: this.SetUploadSoilHealthReportModalState,
      SetShowErrorModalState: this.SetShowErrorModalState,
      SetTriggerSoilHealthReportsUpdateState: this.SetTriggerSoilHealthReportsUpdateState,
      PostSoilHealthDocs: this.PostSoilHealthDocs,
      GetSoilHealthDocs: this.GetSoilHealthDocs,
      DeleteSoilHealthDocument: this.DeleteSoilHealthDocument,
      GetSoilHealthGlossary: this.GetSoilHealthGlossary,
      PostSoilHealthGlossary: this.PostSoilHealthGlossary,
      SetCurrentUserState: this.SetCurrentUserState,
      SetGLGMChangedState: this.SetGLGMChangedState,
      SetFarmMessageState: this.SetFarmMessageState,
      SetAnalysisToDeleteState: this.SetAnalysisToDeleteState,
      SetCurrentFarmState: this.SetCurrentFarmState,
      SetCurrentFieldState: this.SetCurrentFieldState,
      SetCurrentValidFromDateState: this.SetCurrentValidFromDateState,
      SetCurrentValidToDateState: this.SetCurrentValidToDateState,
      SetSoilHealthReportsState: this.SetSoilHealthReportsState,
      SetIsLoadingState: this.SetIsLoadingState,
      SetFarmState: this.SetFarmState,
      GetMarkers: this.GetMarkers,
      PostMarker: this.PostMarker,
      PostMeasure: this.PostMeasure,
      GetMeasures: this.GetMeasures,
      DeleteMeasure: this.DeleteMeasure,
      DeleteMarker: this.DeleteMarker,
      GetGCIStations: this.GetGCIStations,
      SetSelectedPDFURLState: this.SetSelectedPDFURLState,
      GetGCIStationLinks: this.GetGCIStationLinks,
      GetUserProfile: this.GetUserProfile,
      PostUserProfile: this.PostUserProfile,
      DeleteUserProfile: this.DeleteUserProfile,
      UploadHomePageLink: this.UploadHomePageLink,
      GetHomePageLink: this.GetHomePageLink,
      DeleteHomePageLink: this.DeleteHomePageLink,
      PostFarm: this.PostFarm,
      PutFarm: this.PutFarm,
      DeleteFarm: this.DeleteFarm,
      DownloadFiles: this.DownloadFiles,
      GetGLGMFarms: this.GetGLGMFarms,
      PostField: this.PostField,
      UpdateField: this.UpdateField,
      DeleteField: this.DeleteField,
      SetFieldsState: this.SetFieldsState,
      SetAnalysisUploadsState: this.SetAnalysisUploadsState,
      SetExistingUploadsState: this.SetExistingUploadsState,
      UploadAnalysis: this.UploadAnalysis,
      GetAnalysis: this.GetAnalysis,
      DeleteAnalysis: this.DeleteAnalysis,
      ConvertShapeFiles: this.ConvertShapeFiles,
      UpdateFarmFieldData: this.UpdateFarmFieldData,
      UpdateAnalysisData: this.UpdateAnalysisData,
      UpdateFieldAnalysisState: this.UpdateFieldAnalysisState,
      GetDeliveryNotifications: this.GetDeliveryNotifications,
      GetImagePODs: this.GetImagePODs,
    };
    return (
      <FarmDataContext.Provider value={configObject}>
        {children}
      </FarmDataContext.Provider>
    );
  }
}

export default FarmDataProvider;
