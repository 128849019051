import React, { useState } from 'react';
import '../Styles/AddFieldModal.css';
import { useFarmData } from './FarmData-Context';

function AddFieldModal() {
  const [fieldName, setFieldName] = useState('');
  const { PostField } = useFarmData();

  return (
    <div
      className="modal fade"
      id="add-field"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form
            id="add-field-form"
            onSubmit={(e) => {
              e.preventDefault();
              PostField(
                document.getElementById('farm-select').value,
                fieldName,
              );

              setFieldName('');
              document.getElementById('add-field-close').click();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add Field
              </h5>
              <button
                id="add-field-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Field Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={fieldName}
                    onChange={(e) => {
                      setFieldName(e.target.value);
                    }}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button id="test" type="submit" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddFieldModal;
