import React from 'react';
import '../Styles/Loader.css';
import LoaderNoText from '../Images/Insight_Logo_No_Text.svg';

const LoaderBlock = () => (
  <div className="spinner-grow text-light main-loader" role="status">
    <span>
      <img
        className="main-loader-img"
        src={LoaderNoText}
        alt="Loading"
      />
    </span>
  </div>
);

function Loader({ className = null }) {
  return (
    <div className={className ? `${className}__background` : 'loader-background'}>
      <div className={className ? `${className}__animation` : 'loading-animation'}>
        <LoaderBlock />
        <LoaderBlock />
        <LoaderBlock />
      </div>
    </div>
  );
}

export default Loader;
