import React from 'react';
import '../Styles/AddFieldModal.css';
import { useFarmData } from './FarmData-Context';

function DeleteFieldModal() {
  const { DeleteField } = useFarmData();

  return (
    <div
      className="modal fade"
      id="delete-field"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form
            id="delete-field-form"
            onSubmit={(e) => {
              e.preventDefault();
              DeleteField(document.getElementById('field-select').value);
              document.getElementById('delete-field-close').click();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Delete Field
              </h5>
              <button
                id="delete-field-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete the selected field?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DeleteFieldModal;
