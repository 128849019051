import React, { useEffect, useState } from 'react';
import { useUsers } from './Users-Context.js';
import UnhandledSignupsModal from './UnhandledSignupsModal.js';
import UnhandledSignupsSimplifiedModal from './UnhandledSignupsSimplifiedModal.js';

function UnhandledSignups() {
  const { GetAuth0Users, Auth0Users } = useUsers();
  const [accountLinked, setAccountLinked] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);
  const [signUpComplete, setSignUpComplete] = useState(true);
  const [loginCount, setLoginCount] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountEmail, setAccountEmail] = useState(null);
  const [initialUpdate, setInitialUpdate] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [accountLinkedRadioSelected, setAccountLinkedRadioSelected] = useState(true);

  useEffect(() => {
    if (initialUpdate) {
      GetAuth0Users(
        accountLinked,
        signUpComplete,
        emailVerified,
        accountName,
        accountEmail,
        pageNumber,
        loginCount,
      );
      setInitialUpdate(false);
    }
  });

  Auth0Users.sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });

  const usersList = Auth0Users.length > 0
    && Auth0Users.map((item, i) => {
      const date = new Date(item.created_at);
      return (
        <tr
          key={i}
          id={item.user_id}
          data-toggle="modal"
          data-target={`#modal${item.user_id.split('|')[1]}`}
        >
          <td>{date.toDateString()}</td>
          <td>{item.name}</td>
          <td>{item.email}</td>
          <td>{item.aukcustomer}</td>
          <td>{item.accountnumber}</td>
          <td>{item.farmname}</td>
          <td>{item.tradingname}</td>
          <td>{item.phonenumber}</td>
          <td>{item.mobilenumber}</td>
          <td>{item.county}</td>
          <td>{item.postcode}</td>
        </tr>
      );
    }, this);

  const modalList = Auth0Users.length > 0
    && accountLinkedRadioSelected
    && Auth0Users.map((item, i) => {
      return (
        <div key={i}>
          <UnhandledSignupsModal user={item} />
        </div>
      );
    }, this);

  const modalListSimplified = Auth0Users.length > 0
    && !accountLinkedRadioSelected
    && Auth0Users.map((item, i) => {
      return (
        <div key={i}>
          <UnhandledSignupsSimplifiedModal user={item} />
        </div>
      );
    }, this);

  return (
    <div>
      <div className="container">
        <h4 className="text-center">
          <i>Unhandled Signups</i>
        </h4>
        <form>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="option1"
              defaultChecked
              onChange={() => {
                setAccountLinkedRadioSelected(true);
                setAccountLinked(false);
                setSignUpComplete(true);
                setEmailVerified(true);
                setLoginCount(null);
                setPageNumber(0);
                GetAuth0Users(
                  false,
                  true,
                  true,
                  accountName,
                  accountEmail,
                  0,
                  null,
                );
              }}
            />
            <label className="form-check-label" htmlFor="inlineRadio1">
              Awaiting Account Linking
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="option2"
              onChange={() => {
                setAccountLinkedRadioSelected(false);
                setAccountLinked(null);
                setSignUpComplete(false);
                setLoginCount(null);
                setEmailVerified(null);
                setPageNumber(0);
                GetAuth0Users(
                  null,
                  false,
                  null,
                  accountName,
                  accountEmail,
                  0,
                  null,
                );
              }}
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              Awaiting Sign Up Completion
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="option2"
              onChange={() => {
                setAccountLinkedRadioSelected(false);
                setAccountLinked(null);
                setSignUpComplete(null);
                setLoginCount(null);
                setEmailVerified(false);
                setPageNumber(0);
                GetAuth0Users(
                  null,
                  null,
                  false,
                  accountName,
                  accountEmail,
                  0,
                  null,
                );
              }}
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              Awaiting Email Verification
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="option2"
              onChange={() => {
                setAccountLinkedRadioSelected(false);
                setAccountLinked(null);
                setSignUpComplete(null);
                setLoginCount(0);
                setEmailVerified(null);
                setPageNumber(0);
                GetAuth0Users(
                  null,
                  null,
                  null,
                  accountName,
                  accountEmail,
                  0,
                  0,
                );
              }}
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              Awaiting Invitation Completion
            </label>
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Account Name"
              aria-label="Account Name"
              aria-describedby="button-addon"
              onInput={(e) => {
                if (e.target.value.length > 3) {
                  setAccountName(e.target.value);
                  GetAuth0Users(
                    accountLinked,
                    signUpComplete,
                    emailVerified,
                    e.target.value,
                    accountEmail,
                    pageNumber,
                    loginCount,
                  );
                } else {
                  setAccountName(null);
                  GetAuth0Users(
                    accountLinked,
                    signUpComplete,
                    emailVerified,
                    null,
                    accountEmail,
                    pageNumber,
                    loginCount,
                  );
                }
              }}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Account Email"
              aria-label="Account Email"
              aria-describedby="button-addon"
              onInput={(e) => {
                if (e.target.value.length > 3) {
                  setAccountEmail(e.target.value);
                  GetAuth0Users(
                    accountLinked,
                    signUpComplete,
                    emailVerified,
                    accountName,
                    e.target.value,
                    pageNumber,
                    loginCount,
                  );
                } else {
                  setAccountEmail(null);
                  GetAuth0Users(
                    accountLinked,
                    signUpComplete,
                    emailVerified,
                    accountName,
                    null,
                    pageNumber,
                    loginCount,
                  );
                }
              }}
            />
          </div>
        </form>
      </div>
      <div className="admin-data-table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Signup Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>AUK Customer</th>
              <th>Account Number</th>
              <th>Farm Name</th>
              <th>Trading Name</th>
              <th>Phone No</th>
              <th>Mobile No</th>
              <th>County</th>
              <th>Postcode</th>
            </tr>
          </thead>
          <tbody>{usersList}</tbody>
        </table>
      </div>
      <nav
        className="admin-pagination-nav"
        aria-label="Page navigation example"
      >
        <ul className="pagination justify-content-center">
          <li className={pageNumber == 0 ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              tabIndex="-1"
              onClick={() => {
                setPageNumber(pageNumber - 1);
                GetAuth0Users(
                  accountLinked,
                  signUpComplete,
                  emailVerified,
                  accountName,
                  accountEmail,
                  pageNumber - 1,
                  loginCount,
                );
              }}
            >
              Previous
            </a>
          </li>
          <li className={pageNumber == 0 ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              onClick={() => {
                setPageNumber(pageNumber - 1);
                GetAuth0Users(
                  accountLinked,
                  signUpComplete,
                  emailVerified,
                  accountName,
                  accountEmail,
                  pageNumber - 1,
                  loginCount,
                );
              }}
            >
              {pageNumber}
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              {pageNumber + 1}
            </a>
          </li>
          <li className={!usersList ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              onClick={() => {
                setPageNumber(pageNumber + 1);
                GetAuth0Users(
                  accountLinked,
                  signUpComplete,
                  emailVerified,
                  accountName,
                  accountEmail,
                  pageNumber + 1,
                  loginCount,
                );
              }}
            >
              {pageNumber + 2}
            </a>
          </li>
          <li className={!usersList ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              onClick={() => {
                setPageNumber(pageNumber + 1);
                GetAuth0Users(
                  accountLinked,
                  signUpComplete,
                  emailVerified,
                  accountName,
                  accountEmail,
                  pageNumber + 1,
                  loginCount,
                );
              }}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
      {modalList}
      {modalListSimplified}
    </div>
  );
}

export default UnhandledSignups;
