import React, { useState } from 'react';
import { useFarmData } from './FarmData-Context';

function AddFarmModal() {
  const [farmName, setFarmName] = useState('');
  const [addressDisplay, setAddressDisplay] = useState('none');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [townCity, setTownCity] = useState('');
  const [county, setCounty] = useState('');
  const [country, setCountry] = useState('');
  const [postCode, setPostCode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [invalidPostcode, setInvalidPostcode] = useState('');
  const { PostFarm, CurrentUser } = useFarmData();

  const handlePostcodeSearch = async () => {
    const patt = /^(((([A-Za-z][A-Za-z]{0,1})[0-9][A-Za-z0-9]{0,1}) {0,}[0-9])[A-Za-z]{2})$/;
    if (patt.test(postCode)) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: postCode }, (results, status) => {
        if (status == 'OK') {
          setAddress1('');
          setAddress2('');
          setTownCity('');
          setCounty('');
          setCountry('');

          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              switch (results[0].address_components[i].types[j]) {
                case 'route':
                  setAddress1(results[0].address_components[i].long_name);
                  break;
                case 'locality':
                  setAddress2(results[0].address_components[i].long_name);
                  break;
                case 'postal_town':
                  setTownCity(results[0].address_components[i].long_name);
                  break;
                case 'administrative_area_level_2':
                  setCounty(results[0].address_components[i].long_name);
                  break;
                case 'country':
                  setCountry(results[0].address_components[i].long_name);
                  break;
                case 'postal_code':
                  setPostCode(results[0].address_components[i].long_name);
                  break;
              }
            }
          }

          setLatitude(results[0].geometry.location.lat);
          setLongitude(results[0].geometry.location.lng);
          setAddressDisplay('block');
          setInvalidPostcode('');
        } else {
          alert(
            `Geocode was not successful for the following reason: ${status}`,
          );
        }
      });
    } else {
      setInvalidPostcode('The postcode must follow a UK format');
    }
  };

  return (
    <div
      className="modal fade"
      id="add-farm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form
            id="add-farm-form"
            onSubmit={(e) => {
              e.preventDefault();

              if (latitude.length === 0 || longitude.length === 0) {
                setInvalidPostcode(
                  'You must click search to generate the address.',
                );
              } else {
                PostFarm(
                  CurrentUser,
                  farmName,
                  address1,
                  address2,
                  townCity,
                  county,
                  postCode,
                  latitude,
                  longitude,
                );
                document.getElementById('add-farm-close').click();
                setFarmName('');
                setAddressDisplay('none');
                setAddress1('');
                setAddress2('');
                setTownCity('');
                setCounty('');
                setCountry('');
                setPostCode('');
                setLatitude('');
                setLongitude('');
                setInvalidPostcode('');
              }
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add Farm
              </h5>
              <button
                id="add-farm-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Farm Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={farmName}
                    onChange={(e) => {
                      setFarmName(e.target.value);
                    }}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Address Line 1</label>
                  <input value={address1} className="form-control" readOnly />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Address Line 2</label>
                  <input value={address2} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Town/City</label>
                  <input value={townCity} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">County</label>
                  <input value={county} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Country</label>
                  <input value={country} className="form-control" readOnly />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Postcode</label>
                  <div className="form-validation">
                    <i>{invalidPostcode}</i>
                  </div>
                  <div className="input-group">
                    <input
                      id="validationTooltip01"
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      aria-label="IFS Customer No"
                      aria-describedby="button-addon"
                      pattern="^(((([A-Za-z][A-Za-z]{0,1})[0-9][A-Za-z0-9]{0,1}) {0,}[0-9])[A-Za-z]{2})$"
                      title="The postcode must follow a UK format"
                      value={postCode}
                      onChange={(e) => {
                        setPostCode(e.target.value);
                      }}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon"
                        onClick={() => {
                          handlePostcodeSearch();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button id="test" type="submit" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddFarmModal;
