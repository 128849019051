import React from 'react';
import '../Styles/DeliveryNotificationsMapLegend.css';

function DeliveryNotificationsMapLegend() {
  return (
    <div id="deliverynotifications-info-box">
      <div id="ratekey-title">
        <b>
          <i>Key</i>
        </b>
      </div>
      <div>
        <div className="delivery-key">
          <img className="delivery-icons" src="Images/Delivered-Icon.png" />
          <span className="deliverykey-text">- Delivered</span>
        </div>
        <div className="delivery-key">
          <img
            className="delivery-icons"
            src="Images/Delivery-In-Transit-Icon.png"
          />
          <span className="deliverykey-text">
            - <i>Out for Delivery</i>
          </span>
        </div>
        <div className="delivery-key">
          <img className="delivery-icons" src="Images/Failed-Delivery.png" />
          <span className="deliverykey-text">
            - <i>Failed</i>
          </span>
        </div>
        <div className="delivery-key">
          <img className="delivery-icons" src="Images/Partial-Delivery.png" />
          <span className="deliverykey-text">
            - <i>Partially Delivered</i>
          </span>
        </div>
        <div className="delivery-key">
          <img className="delivery-icons" src="Images/Tracked-Delivery.png" />
          <span className="deliverykey-text">
            - <i>Tracked Vehicle</i>
          </span>
        </div>
      </div>
    </div>
  );
}

export default DeliveryNotificationsMapLegend;
