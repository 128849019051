import React, { useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Route } from 'react-router-dom';
import { Wrapper } from '@googlemaps/react-wrapper';
import Home from './Pages/Home';
import PrecisionMapping from './Pages/PrecisionMapping';
import SoilHealth from './Pages/SoilHealth';
import SoilHealthGlossary from './Pages/SoilHealthGlossary';
import Admin from './Pages/Admin';
import { useAuth0 } from './Auth/Auth0-Context';
import Header from './Components/Header';
import HeaderUnauthorised from './Components/HeaderUnauthorised';
import './Styles/App.css';
import SignUp from './Pages/SignUp';
import UserContext from './Components/Users-Context';
import FarmDataContext from './Components/FarmData-Context';
import GLGMUpdatesModal from './Components/GLGMUpdatesModal';
import IFSContext from './Components/IFSData-Context';
import MessageModal from './Components/MessageModal';
import ErrorMessageModal from './Components/ErrorMessageModal';
import ErrorMessageReactModal from './Modals/ErrorMessageReactModal';
import DeliveryNotifications from './Pages/DeliveryNotifications';

function App() {
  const {
    isAuthenticated,
    isAccountLinked,
    isEmailVerified,
    hasAcceptedAuthRequest,
    getTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    window.addEventListener('popstate', () => {
      for (
        let i = 0;
        i < document.getElementsByClassName('modal-backdrop').length;
        i++
      ) {
        document.getElementsByClassName('modal-backdrop')[i].style.display = 'none';
      }
    });
  }, []);

  if (!isEmailVerified && window.location.pathname !== '/signup') {
    return (
      <>
        <span className="shaded-background-color" />
        <HeaderUnauthorised />
        <div className="container-fluid email-not-verified-container">
          <div className="email-not-verified-background">
            <h4 className="text-center">
              <i>Email Not Verified</i>
            </h4>
            <div className="row justify-content-md-center">
              <p className="text-center">
                The email used to sign up with hasn't been verified yet. You
                <br />
                should have received an email with a link to verify the account,{' '}
                <br />
                please complete this step before proceeding.
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CookieConsent
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            <b>Privacy and Cookies | </b>if you would like to know more about
            how we use cookies please read our{' '}
            <a
              target="_blank"
              href="https://www.agrovista.co.uk/privacy-policy"
              rel="noreferrer"
            >
              privacy
            </a>{' '}
            and{' '}
            <a target="_blank" href="https://www.agrovista.co.uk/cookie-policy" rel="noreferrer">
              cookie
            </a>{' '}
            policy.
          </CookieConsent>
        </div>
      </>
    );
  } if (!isAccountLinked && window.location.pathname !== '/signup') {
    return (
      <>
        <span className="shaded-background-color" />
        <HeaderUnauthorised />
        <div className="container-fluid account-not-linked-container">
          <div className="account-not-linked-background">
            <h4 className="text-center">
              <i>Account Not Linked</i>
            </h4>
            <div className="row justify-content-md-center">
              <p className="text-center">
                Your account hasn't been linked yet by our admins, this should
                be completed
                <br />
                within 5 days. If this is taking longer than expected please
                contact support.
                <br />
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CookieConsent
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            <b>Privacy and Cookies | </b>if you would like to know more about
            how we use cookies please read our{' '}
            <a
              target="_blank"
              href="https://www.agrovista.co.uk/privacy-policy"
              rel="noreferrer"
            >
              privacy
            </a>{' '}
            and{' '}
            <a target="_blank" href="https://www.agrovista.co.uk/cookie-policy" rel="noreferrer">
              cookie
            </a>{' '}
            policy.
          </CookieConsent>
        </div>
      </>
    );
  } if (
    !hasAcceptedAuthRequest
    && window.location.pathname !== '/signup'
  ) {
    return (
      <>
        <span className="shaded-background-color" />
        <Header />
        <div className="container-fluid app-not-authorised-container">
          <div className="app-not-authorised-background">
            <h4 className="text-center">
              <i>App Not Authorised</i>
            </h4>
            <div className="row justify-content-md-center">
              <p className="text-center">
                Your must authorise the app to gain access to the application,{' '}
                <br />
                please refresh and accept the request too continue.
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CookieConsent
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            <b>Privacy and Cookies | </b>if you would like to know more about
            how we use cookies please read our{' '}
            <a
              target="_blank"
              href="https://www.agrovista.co.uk/privacy-policy"
              rel="noreferrer"
            >
              privacy
            </a>{' '}
            and{' '}
            <a target="_blank" href="https://www.agrovista.co.uk/cookie-policy" rel="noreferrer">
              cookie
            </a>{' '}
            policy.
          </CookieConsent>
        </div>
      </>
    );
  } if (!isAuthenticated && window.location.pathname !== '/signup') {
    return (
      <p>
        Loading, if this takes longer than 5 seconds please try again{' '}
        <a href="#" onClick={loginWithRedirect}>
          here...
        </a>
      </p>
    );
  }
  return (
    <Wrapper id="googlemapscript" apiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['drawing', 'geometry']}>
      <IFSContext token={getTokenSilently()}>
        <FarmDataContext token={getTokenSilently()}>
          <UserContext token={getTokenSilently()}>
            <Header />
            <Route path="/" exact component={Home} />
            <Route path="/precisionmapping" component={PrecisionMapping} />
            <Route path="/deliverynotifications" component={DeliveryNotifications} />
            <Route path="/soilhealth" component={SoilHealth} />
            <Route path="/soilhealthglossary" component={SoilHealthGlossary} />
            <Route path="/admin" component={Admin} />
            <Route path="/signup" component={SignUp} />
            <GLGMUpdatesModal />
            <MessageModal />
            <ErrorMessageModal />
            <ErrorMessageReactModal />
            <a
              id="message-modal-trigger"
              data-target="#message-modal"
              data-toggle="modal"
            />
            <a
              id="error-message-modal-trigger"
              data-target="#error-message-modal"
              data-toggle="modal"
            />
            <a
              id="glgm-updates-trigger"
              data-target="#glgm-updates"
              data-toggle="modal"
            />
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CookieConsent
                style={{ background: '#2B373B' }}
                buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
                expires={150}
              >
                <b>Privacy and Cookies | </b>if you would like to know more about
                how we use cookies please read our{' '}
                <a
                  target="_blank"
                  href="https://www.agrovista.co.uk/privacy-policy"
                  rel="noreferrer"
                >
                  privacy
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  href="https://www.agrovista.co.uk/cookie-policy"
                  rel="noreferrer"
                >
                  cookie
                </a>{' '}
                policy.
              </CookieConsent>
            </div>
          </UserContext>
        </FarmDataContext>
      </IFSContext>
    </Wrapper>
  );
}

export default App;
