import React from 'react';
import { useUsers } from './Users-Context.js';
import '../Styles/UnhandledSignupsModal.css';

function UnhandledSignupsSimplifiedModal(props) {
  const { DeleteAuth0Users } = useUsers();

  const HandleDeleteButtonClick = async (id) => {
    document.getElementsByClassName('modal-close-button')[0].click();
    document.getElementsByClassName('modal-backdrop')[0].remove();
    DeleteAuth0Users(id);
  };

  const date = new Date(props.user.created_at);

  return (
    <>
      <div
        className="modal fade"
        id={`modal${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Handle Sign Up
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <h6>Account Details ({props.user.name})</h6>
                  <p>
                    Sign Up Date: <i>{date.toDateString()}</i>
                    <br />
                    Email: <i>{props.user.email}</i>
                  </p>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id={props.user.user_id}
                type="button"
                className="btn btn-primary delete-user-button"
                data-toggle="modal"
                data-target={
                  `#confirm-delete${props.user.user_id.split('|')[1]}`
                }
                data-dismiss="modal"
              >
                Delete User
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`confirm-delete${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">Confirm</div>
            <div className="modal-body">
              Are you sure you want to delete the user?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                id={props.user.user_id}
                className="btn btn-danger btn-ok"
                data-dismiss="modal"
                onClick={(e) => {
                  HandleDeleteButtonClick(e.target.id);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UnhandledSignupsSimplifiedModal;
