import React, { useState } from 'react';
import { useFarmData } from './FarmData-Context';
import { useUsers } from './Users-Context.js';
import '../Styles/GLGMSiteSearchModal.css';

function GLGMSiteSearchModal() {
  const [pageNumber, setPageNumber] = useState(0);
  const [accountName, setAccountName] = useState('');
  const [ifsNumber, setIfsNumber] = useState('');
  const {
    GLGMFarms,
    GetGLGMFarms,
    CurrentUser,
    SetFarmState,
    CurrentFarm,
    SetGLGMChangedState,
  } = useFarmData();
  const { UpdateGLGMNumber } = useUsers();

  const glgmFarmsList = GLGMFarms.length > 0
    && GLGMFarms.map((item, i) => {
      return (
        <tr
          key={i}
          id={item.key}
          onClick={(e) => {
            SetGLGMChangedState(true);
            SetFarmState(CurrentFarm, e.target.id);
            UpdateGLGMNumber(CurrentUser, e.target.id);
          }}
          data-dismiss="modal"
        >
          <td id={item.key}>{item.ifsnumber}</td>
          <td id={item.key}>{item.name}</td>
          <td id={item.key}>{item.address1}</td>
          <td id={item.key}>{item.towncity}</td>
          <td id={item.key}>{item.postcode}</td>
        </tr>
      );
    }, this);

  return (
    <div
      className="modal fade"
      id="search-glgm-farms"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <form id="glgm-farms-form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                GLGM Farm Search
              </h5>
              <button
                id="add-field-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body glgm-site-search-modal-body">
              <div className="form-row">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="IFS Number"
                    aria-label="IFS Number"
                    aria-describedby="button-addon"
                    value={ifsNumber}
                    onInput={(e) => {
                      setIfsNumber(e.target.value);
                      GetGLGMFarms(e.target.value, accountName, pageNumber);
                    }}
                    onChange={() => {}}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Name"
                    aria-label="Account Name"
                    aria-describedby="button-addon"
                    value={accountName}
                    onInput={(e) => {
                      setAccountName(e.target.value);
                      GetGLGMFarms(ifsNumber, e.target.value, pageNumber);
                    }}
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className="form-row justify-content-center">
                <div className="glgm-search-data-table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>IFS Number</th>
                        <th>Name</th>
                        <th>Address1</th>
                        <th>TownCity</th>
                        <th>Postcode</th>
                      </tr>
                    </thead>
                    <tbody>{glgmFarmsList}</tbody>
                  </table>
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={
                        pageNumber == 0 ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        tabIndex="-1"
                        onClick={() => {
                          setPageNumber(pageNumber - 1);
                          GetGLGMFarms(ifsNumber, accountName, pageNumber - 1);
                        }}
                      >
                        Previous
                      </a>
                    </li>
                    <li
                      className={
                        pageNumber == 0 ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPageNumber(pageNumber - 1);
                          GetGLGMFarms(ifsNumber, accountName, pageNumber - 1);
                        }}
                      >
                        {pageNumber}
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        {pageNumber + 1}
                      </a>
                    </li>
                    <li
                      className={
                        !glgmFarmsList ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPageNumber(pageNumber + 1);
                          GetGLGMFarms(ifsNumber, accountName, pageNumber + 1);
                        }}
                      >
                        {pageNumber + 2}
                      </a>
                    </li>
                    <li
                      className={
                        !glgmFarmsList ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPageNumber(pageNumber + 1);
                          GetGLGMFarms(ifsNumber, accountName, pageNumber + 1);
                        }}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GLGMSiteSearchModal;
