import React from 'react';
import { useFarmData } from './FarmData-Context';

function DeleteFarmModal() {
  const { Fields, DeleteFarm } = useFarmData();

  return (
    <div
      className="modal fade"
      id="delete-farm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form
            id="delete-farm-form"
            onSubmit={(e) => {
              e.preventDefault();

              if (Fields.length > 0) {
                alert('you must delete all fields before deleting the farm.');
                document.getElementById('delete-farm-close').click();
              } else {
                DeleteFarm(document.getElementById('farm-select').value);
                document.getElementById('delete-farm-close').click();
              }
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Delete Farm
              </h5>
              <button
                id="delete-farm-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete the selected farm?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DeleteFarmModal;
