export function ExtractGeoJsonProperties(fieldanalysis) {
  const propertiesListArray = [
    {
      properties: '',
    },
  ];
  const shapeObject = JSON.parse(fieldanalysis.geojson);
  const propertyMapping = JSON.parse(fieldanalysis.mapping);

  for (let j = 0; j < shapeObject.features.length; j++) {
    const analysisPropertiesListArray = [];
    const { properties } = shapeObject.features[j];
    const propertyNames = Object.getOwnPropertyNames(properties);

    for (let k = 0; k < propertyNames.length; k++) {
      for (let l = 0; l < propertyMapping.length; l++) {
        if (
          propertyMapping[l].Property_Name === propertyNames[k]
          && propertyMapping[l].Mapping !== 'Hidden'
        ) {
          analysisPropertiesListArray[k] = {
            name: propertyNames[k],
            mapping: propertyMapping[l].Mapping,
            analysis: fieldanalysis.name,
            type: fieldanalysis.type,
            unitofmeasure: fieldanalysis.unitofmeasure,
            value: properties[propertyNames[k]],
          };
        }
      }
    }

    let k = 0;
    while (k < analysisPropertiesListArray.length) {
      if (analysisPropertiesListArray[k] === undefined) {
        analysisPropertiesListArray.splice(k, 1);
      } else {
        k++;
      }
    }

    propertiesListArray[j] = {
      featureId: properties.Feature_Id,
      properties: analysisPropertiesListArray,
    };
  }

  return propertiesListArray;
}
