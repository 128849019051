import React from 'react';

function LineIntersectsMessageModal() {
  return (
    <div
      className="modal fade"
      id="lineintersects-message-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Message
            </h5>
          </div>
          <div className="modal-body">
            <p>
              The line drawn to create the zone must not cross over itself and
              join to at least two points of the field.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LineIntersectsMessageModal;
