import { map, compact } from 'lodash';
import { CalculateArea, ClearAllFieldLabels } from './PrecisionToolBox';

function createNewPolygonField(googleMap, fieldCoordinates, fieldName) {
  const polygon = new window.google.maps.Polygon({
    map: googleMap,
    paths: fieldCoordinates,
    editable: false,
  });
  polygon.set('fieldname', fieldName);
  CalculateArea(googleMap, polygon, 'allfieldarealabel');
  return polygon;
}

function getFieldBoundaryCoordinates(bounds, fieldBoundaryCoordinates) {
  return map(fieldBoundaryCoordinates, ([longitude, latitude]) => {
    bounds.extend(new window.google.maps.LatLng(latitude, longitude));
    return { lat: latitude, lng: longitude };
  });
}

function getAllFieldPolygons(googleMap, fields, bounds) {
  return compact(map(fields, ({ fieldCoords, name }) => {
    const fieldBoundary = JSON.parse(fieldCoords);
    if (fieldBoundary === null) {
      return null;
    }
    const fieldCoordinates = getFieldBoundaryCoordinates(bounds, fieldBoundary.coordinates[0]);
    return createNewPolygonField(googleMap, fieldCoordinates, name);
  }));
}

export function UpdateFieldsOnMap({ map: googleMap, fields, fieldPolygons }) {
  map(fieldPolygons, (polygon) => polygon.setMap(null));
  ClearAllFieldLabels();
  const bounds = new window.google.maps.LatLngBounds();
  const allFieldsPolygons = getAllFieldPolygons(googleMap, fields, bounds);
  googleMap.fitBounds(bounds);
  return allFieldsPolygons;
}
