import React, { useState, useRef, useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import '../../Styles/Common/PDFDisplay.css';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import { InView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader';
import UploadGlossaryModal from '../../Modals/UploadGlossaryModal';
import { useFarmData } from '../FarmData-Context';
import { useAuth0 } from '../../Auth/Auth0-Context';

function PDFDisplay({ pdfFileURL = null }) {
  const [modalState, setModalState] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfDisplayHeight, setPdfDisplayHeight] = useState('75vh');
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [scale, setScale] = useState(2);
  const virtuosoScrollRef = useRef(null);
  const { role } = useAuth0();
  const {
    SelectedPDFURL,
    SetSelectedPDFURLState,
    SetIsLoadingState,
  } = useFarmData();

  const onDocumentLoadSuccess = ({ numPages }) => setNumberOfPages(numPages);

  const handleCloseButtonClick = () => {
    SetSelectedPDFURLState(null);
    SetIsLoadingState(false);
  };

  const handleUploadButtonClick = () => setModalState(true);

  const handleUploadGlossaryModalState = (state) => setModalState(state);

  const handlePaginationItemClick = (pageNumber, movePageNumber) => {
    setPageNumber(pageNumber + movePageNumber);
    virtuosoScrollRef.current.scrollToIndex({
      index: (pageNumber - 1) + movePageNumber,
    });
  };

  const handleDocumentScroll = () => {
    if (!isScrolling) {
      setIsScrolling(true);
    }
  };

  const handleFormControlInput = ({ target: { value } }) => {
    setPageNumber(parseInt(value));
    virtuosoScrollRef.current.scrollToIndex({
      index: parseInt(value - 1),
    });
  };

  const handleInViewChange = (inView, entry) => {
    if (inView && isScrolling) {
      setPageNumber(parseInt(entry.target.id));
    }
  };

  const handlePaginationScaleClick = (scale, moveScale) => {
    setScale(scale + moveScale);
  };

  const handlePageLoad = (page) => {
    const parentDiv = document.querySelector('.pdf__display');
    const pageScale = (parentDiv.clientWidth / page.originalWidth) * 0.8;
    if (scale !== pageScale && initialPageLoad) {
      setScale(pageScale);
      setInitialPageLoad(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPdfDisplayHeight(document.getElementsByClassName('pdf__display')[0].clientHeight);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="pdf__display__toolbar">
        <div className="pdf__pagination__group">
          <Pagination className="pdf__pagination">
            <Pagination.Prev
              onClick={() => handlePaginationItemClick(pageNumber, -1)}
              disabled={pageNumber === 1}
            />
            <Pagination.Item>Page
              <Form.Control
                className="page__number__pdf"
                onInput={(e) => handleFormControlInput(e)}
                value={pageNumber}
                type="number"
              /> of {numberOfPages}
            </Pagination.Item>
            <Pagination.Next
              onClick={() => handlePaginationItemClick(pageNumber, 1)}
              disabled={pageNumber >= numberOfPages}
            />
          </Pagination>
          <span className="pdf__zoom__buttons">
            <Button
              className="pdf__display__buttons"
              variant="outline-warning"
              onClick={() => handlePaginationScaleClick(scale, -0.25)}
              disabled={scale === 0.25}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <Button
              className="pdf__display__buttons"
              variant="outline-warning"
              onClick={() => handlePaginationScaleClick(scale, 0.25)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </span>
          {pdfFileURL && role === 'Admin' && (
            <Button
              className="pdf__display__buttons"
              variant="outline-warning"
              onClick={handleUploadButtonClick}
            >
              Upload
            </Button>

          )}
          {!pdfFileURL && (
            <Button
              id="pdf__close__button"
              className="pdf__display__buttons"
              variant="outline-danger"
              onClick={handleCloseButtonClick}
            >
              Close
            </Button>

          )}
        </div>
      </div>
      <div
        className="pdf__display"
      >
        <Document file={pdfFileURL ?? SelectedPDFURL} onLoadSuccess={onDocumentLoadSuccess}>
          <Virtuoso
            style={{ height: pdfDisplayHeight }}
            className="virtuoso__page__div"
            isScrolling={handleDocumentScroll}
            initialTopMostItemIndex={0}
            totalCount={numberOfPages}
            ref={virtuosoScrollRef}
            itemContent={(index) => (
              <InView
                as="div"
                key={index}
                id={`${index + 1}`}
                threshold={0.2}
                onChange={(inView, entry) => handleInViewChange(inView, entry)}
              >
                <Page
                  className="pdf__page__display"
                  key={`page_${index + 1}`}
                  renderTextLayer={pdfFileURL}
                  scale={scale}
                  onLoadSuccess={handlePageLoad}
                  pageNumber={index + 1}
                />
              </InView>
            )}
          />
        </Document>
      </div>
      <UploadGlossaryModal modalState={modalState} handleModalState={handleUploadGlossaryModalState} />
    </>
  );
}

export default PDFDisplay;
