import React, { useState } from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { ClearMeasurements } from '../Util/PrecisionToolBox.js';
import { useFarmData } from './FarmData-Context';

function SaveMeasureModal() {
  const { currentMeasurements, SetCurrentMeasurementsState } = useSoilHealthContext();
  const { PostMeasure } = useFarmData();
  const [selectedYear, setSelectedYear] = useState('perminant');
  const [labelName, setLabelName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        currentMeasurements.Label = labelName;
        currentMeasurements.Measure_Year = selectedYear;
        currentMeasurements.Category = selectedCategory;

        PostMeasure(currentMeasurements);

        document.getElementById('save-measure-close').click();
        ClearMeasurements();
        SetCurrentMeasurementsState({});
        setSelectedCategory('');
        setSelectedYear('perminant');
        setLabelName('');
      }}
    >
      <div
        className="modal fade"
        id="save-measure-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Save Measure
              </h5>
            </div>
            <div id="info-box-modal-body" className="modal-body">
              <table>
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Category</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        placeholder="Label"
                        className="form-control analysis-name-input"
                        autoComplete="off"
                        value={labelName}
                        onChange={(e) => {
                          setLabelName(e.target.value);
                        }}
                        required
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </td>
                    <td>
                      <select
                        className="custom-select"
                        value={selectedCategory}
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                        }}
                        required
                      >
                        <option value="" />
                        <option value="Weed patch">Weed patch</option>
                        <option value="Trial plot">Trial plot</option>
                        <option value="Woodland / Trees">
                          Woodland / Trees
                        </option>
                        <option value="CSS - Countryside stewardship">
                          CSS - Countryside stewardship
                        </option>
                        <option value="Local Nature recovery">
                          Local Nature recovery
                        </option>
                        <option value="SFI - Sustainable farming incentive">
                          SFI - Sustainable farming incentive
                        </option>
                        <option value="HLS - Higher level stewardship">
                          HLS - Higher level stewardship
                        </option>
                        <option value="ELM - Environmental land management">
                          ELM - Environmental land management
                        </option>
                        <option value="Hedge row">Hedge row</option>
                        <option value="Water course">Water course</option>
                        <option value="Other">Other</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="save-measure-close"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  ClearMeasurements();
                }}
              >
                Clear
              </button>
              <button type="submit" className="btn btn-success btn-ok">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default SaveMeasureModal;
