import { find } from 'lodash';

function findReportCollection({ reports, newReport }) {
  return find(reports, ({ farm, field, selectedUser }) => farm === newReport.farm
    && field === newReport.field
    && selectedUser === newReport.selectedUser);
}

function findCurrentReportSectionCollection({ reportCollection, section }) {
  return find(reportCollection.reportTypeArray, ({ reportType }) => reportType === section);
}

export function findSoilHealthReportSection({
  reports, section, farm, field, selectedUser,
}) {
  const newReport = {
    farm,
    field: field ?? 'allfields',
    selectedUser: selectedUser ?? 'my reports',
  };

  const reportCollection = findReportCollection({ reports, newReport });

  if (!reportCollection) {
    return;
  }

  const reportSectionCollection = findCurrentReportSectionCollection({ reportCollection, section });

  return reportSectionCollection;
}

export function addSoilHealthReportSection({ reports, section, newReport }) {
  const reportCollection = findReportCollection({ reports, newReport });

  if (!reportCollection) {
    reports.push(newReport);
    return;
  }

  const reportSectionCollection = findCurrentReportSectionCollection({ reportCollection, section });

  if (!reportSectionCollection) {
    reportCollection.reportTypeArray.push(newReport.reportTypeArray[0]);
    return;
  }

  reportSectionCollection.reportsList = newReport.reportTypeArray[0].reportsList;
}
