import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import DeliveryNotificationsMap from '../Components/DeliveryNotificationsMap';
import DeliveryNotificationsPanel from '../Components/DeliveryNotificationsPanel';
import { useAuth0 } from '../Auth/Auth0-Context';
import '../Styles/DeliveryNotifications.css';
import listIconImage from '../Images/listicon.svg';
import mapIconImage from '../Images/mapicon.svg';

export const DeliveryNotificationsContext = createContext();
export const useDeliveryNotificationsContext = () => useContext(DeliveryNotificationsContext);

function DeliveryNotifications() {
  const [deliveryMapGlobal, setDeliveryMapGlobal] = useState('');
  const { role, SetRoleState } = useAuth0();

  const SetDeliveryMapState = async (map) => {
    setDeliveryMapGlobal(map);
  };

  const configObject = {
    deliveryMapGlobal,
    SetDeliveryMapState: (...p) => SetDeliveryMapState(...p),
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        let windowWidth = '';

        if (window.navigator.standalone) {
          windowWidth = window.outerWidth;
        } else {
          windowWidth = window.innerWidth;
        }

        if (
          windowWidth > 550
          && windowWidth < 1200
          && window.location.pathname === '/deliverynotifications'
        ) {
          document.getElementsByClassName(
            'deliverynotificationspanel',
          )[0].style.width = '40%';

          document.getElementById(
            'deliverynotifications-info-box',
          ).style.display = 'block';
          document.getElementById('deliverynotificationsmap').style.width = '60%';
        } else if (
          windowWidth > 550
          && window.location.pathname === '/deliverynotifications'
        ) {
          document.getElementsByClassName(
            'deliverynotificationspanel',
          )[0].style.width = '40%';

          document.getElementById(
            'deliverynotifications-info-box',
          ).style.display = 'block';
          document.getElementById('deliverynotificationsmap').style.width = '60%';
        } else if (window.location.pathname === '/deliverynotifications') {
          document.getElementsByClassName(
            'deliverynotificationspanel',
          )[0].style.width = '100%';

          document.getElementById(
            'deliverynotifications-info-box',
          ).style.display = 'none';
          document.getElementById('deliverynotificationsmap').style.width = '0%';
          document.getElementById(
            'deliverynotificationsmobilelisticon',
          ).style.backgroundColor = '#e8e8e8';
          document.getElementById(
            'deliverynotificationsmobilemapicon',
          ).style.backgroundColor = 'white';
        }
      },
      true,
    );
  }, []);

  return (
    <DeliveryNotificationsContext.Provider value={configObject}>
      <div className="deliverynotifications-mainworkspace">
        <DeliveryNotificationsPanel />
        <DeliveryNotificationsMap />
        <div id="deliverynotificationsmobilenav">
          <span
            id="deliverynotificationsmobilelisticon"
            onClick={() => {
              document.getElementsByClassName(
                'deliverynotificationspanel',
              )[0].style.width = '100%';
              document.getElementById(
                'deliverynotifications-info-box',
              ).style.display = 'none';
              document.getElementById('deliverynotificationsmap').style.width = '0%';
              document.getElementById(
                'deliverynotificationsmobilelisticon',
              ).style.backgroundColor = '#e8e8e8';
              document.getElementById(
                'deliverynotificationsmobilemapicon',
              ).style.backgroundColor = 'white';
            }}
          >
            <img src={listIconImage} alt="List Icon" />
          </span>
          <span
            id="deliverynotificationsmobilemapicon"
            onClick={() => {
              document.getElementsByClassName(
                'deliverynotificationspanel',
              )[0].style.width = '0%';

              document.getElementById(
                'deliverynotifications-info-box',
              ).style.display = 'block';
              document.getElementById('deliverynotificationsmap').style.width = '100%';
              document.getElementById(
                'deliverynotificationsmobilelisticon',
              ).style.backgroundColor = 'white';
              document.getElementById(
                'deliverynotificationsmobilemapicon',
              ).style.backgroundColor = '#e8e8e8';
            }}
          >
            <img src={mapIconImage} alt="Map Icon" />
          </span>
        </div>
      </div>
    </DeliveryNotificationsContext.Provider>
  );
}

export default DeliveryNotifications;
