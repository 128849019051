import React, { useRef, useEffect, useState } from 'react';
import { find } from 'lodash';
import { useFarmData } from '../FarmData-Context';
import '../../Styles/Components/Common/GoogleMaps.css';
import { UpdateFieldsOnMap } from '../../Util/UpdateFieldsOnMap';

function GoogleMaps({
  center = { lat: 52.349206954080564, lng: 0.923879995915898 },
  zoom = 16,
  mapTypeId = window.google.maps.MapTypeId.HYBRID,
  fullscreenControl = false,
  streetViewControl = false,
  mapTypeControl = false,
}) {
  const ref = useRef();
  const [map, setMap] = useState();
  const [fieldPolygons, setFieldPolygons] = useState([]);
  const { Fields = [], CurrentField } = useFarmData();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        center,
        zoom,
        mapTypeId,
        fullscreenControl,
        streetViewControl,
        mapTypeControl,
      }));
    }

    const fieldSelection = CurrentField === 'all fields' ? Fields : [find(Fields, ({ key }) => key === CurrentField)];

    if (map) {
      setFieldPolygons(UpdateFieldsOnMap({ map, fields: fieldSelection, fieldPolygons }));
    }
  }, [ref, map, Fields, CurrentField]);

  return (
    <div ref={ref} id="map" className="react__google__map" />
  );
}

export default GoogleMaps;
