import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { find } from 'lodash';
import { useFarmData } from '../FarmData-Context';
import { useUsers } from '../Users-Context.js';
import { useAuth0 } from '../../Auth/Auth0-Context';

function UserSearchFormGroup({ disabled = false }) {
  const {
    CurrentUser, SetSelectUserModalState, SetCurrentFarmState,
  } = useFarmData();
  const { Users = [] } = useUsers();
  const { user, role } = useAuth0();

  const selectedUser = find(Users, ({ user_id }) => user_id === CurrentUser);

  const handleSearchButtonClick = () => {
    SetSelectUserModalState(true);
    SetCurrentFarmState(null);
  };

  return (
    <Form.Group>
      <Form.Label>User</Form.Label>
      <InputGroup className="mb-3 user__search___input__group">
        <FormControl
          placeholder="user"
          aria-label="user"
          aria-describedby="user-button-addon"
          value={selectedUser !== undefined ? selectedUser.name : user.name}
          disabled
        />
        {role === 'Agronomist' || role === 'Admin' ? (
          <Button
            variant="outline-secondary"
            id="user-button-addon"
            onClick={handleSearchButtonClick}
            disabled={disabled}
          >
            Search
          </Button>
        ) : (null)}
      </InputGroup>
    </Form.Group>
  );
}

export default UserSearchFormGroup;
