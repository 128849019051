import React from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import {
  MeasureLength,
  MeasureArea,
  ToggleFieldLabels,
} from '../Util/PrecisionToolBox.js';
import { ShowMarkers } from './SoilHealthMap';
import '../Styles/SelectMeasureModal.css';

function SelectMeasureModal() {
  const { mapGlobal, SetMeasureClickState } = useSoilHealthContext();
  return (
    <div
      className="modal fade"
      id="select-measure-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="selectmeasuremodal"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="selectmeasuremodal">
              Select Measure
            </h5>
          </div>
          <div id="info-box-modal-body" className="modal-body">
            <button
              type="button"
              className="btn btn-secondary measure-buttons"
              data-dismiss="modal"
              onClick={() => {
                MeasureLength(mapGlobal);
              }}
            >
              Measure Length
            </button>
            <button
              type="button"
              className="btn btn-secondary measure-buttons"
              data-dismiss="modal"
              onClick={() => {
                MeasureArea(mapGlobal);
              }}
            >
              Measure Area
            </button>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                SetMeasureClickState(false);
                ShowMarkers();
                ToggleFieldLabels();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectMeasureModal;
