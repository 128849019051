import React, { Component, createContext, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import jwt_decode from 'jwt-decode';

export const Auth0Context = createContext();
export const useAuth0 = () => useContext(Auth0Context);

// create the context
export class Auth0Provider extends Component {
  constructor() {
    super();
    this.state = {
      auth0Client: null,
      currentState: null,
      isLoading: true,
      isAuthenticated: false,
      isEmailVerified: true,
      isAccountLinked: true,
      hasAcceptedAuthRequest: true,
      user: null,
      role: null,
    };
    this.config = {
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      useRefreshTokens: true,
      scope: 'openid profile',
      redirect_uri: process.env.REACT_APP_REDIRECT, // window.location.origin,
    };

    this.handleRedirectCallback = this.handleRedirectCallback.bind(this);
    this.continueAuth0 = this.continueAuth0.bind(this);
    this.initializeAuth0 = this.initializeAuth0.bind(this);
    this.SetRoleState = this.SetRoleState.bind(this);
  }

  componentDidMount() {
    this.initializeAuth0();
  }

  // handle the authentication callback
  async handleRedirectCallback() {
    this.setState({ isLoading: true });
    await this.state.auth0Client.handleRedirectCallback();
    const user = await this.state.auth0Client.getUser();

    this.setState({ user, isAuthenticated: true, isLoading: false });
    window.history.replaceState({}, document.title, window.location.pathname);
  }

  // initialize the auth0 library
  async continueAuth0(
    aukcustomer,
    accountnumber,
    farmname,
    tradingname,
    phonenumber,
    mobilenumber,
    housenumber,
    address1,
    address2,
    townCity,
    postcode,
    latitude,
    longitude,
    county,
    country,
    consent,
  ) {
    const continueEndpoint = `https://${
      process.env.REACT_APP_AUTH0_DOMAIN
    }/continue?state=${
      this.state.currentState
    }&aukcustomer=${
      aukcustomer
    }&accountnumber=${
      accountnumber
    }&farmname=${
      farmname
    }&tradingname=${
      tradingname
    }&phonenumber=${
      phonenumber
    }&mobilenumber=${
      mobilenumber
    }&housenumber=${
      housenumber
    }&address1=${
      address1
    }&address2=${
      address2
    }&towncity=${
      townCity
    }&postcode=${
      postcode
    }&latitude=${
      latitude
    }&longitude=${
      longitude
    }&county=${
      county
    }&country=${
      country
    }&consent=${
      consent}`;

    window.location.href = continueEndpoint;
  }

  // initialize the auth0 library
  async initializeAuth0() {
    const auth0Client = await createAuth0Client(this.config);
    this.setState({ auth0Client });

    // check to see if they have been redirected after login
    if (window.location.pathname === '/emailverified') {
      this.state.auth0Client.loginWithRedirect();
    } else if (window.location.search.includes('code=')) {
      return this.handleRedirectCallback();
    }

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (window.location.search.includes('error=')) {
      const params = new URLSearchParams(window.location.search);
      const errorType = params.get('error');
      const errorDesc = params.get('error_description');
      if (errorType === 'unauthorized') {
        if (errorDesc === 'Please verify your email before logging in.') {
          this.setState({ isEmailVerified: false });
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          );
        } else if (errorDesc === 'Account not linked') {
          this.setState({ isAccountLinked: false });
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          );
        }
      } else if (errorType === 'access_denied') {
        if (errorDesc === 'User did not authorize the request') {
          this.setState({ hasAcceptedAuthRequest: false });
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          );
        }
      }
    } else if (window.location.pathname === '/signup') {
      const params = new URLSearchParams(window.location.search);
      const state = params.get('state');
      this.setState({ currentState: state });
      window.history.replaceState({}, document.title, window.location.pathname);
    } else if (!isAuthenticated) {
      this.state.auth0Client.loginWithRedirect();
    }

    const token = isAuthenticated ? await auth0Client.getTokenSilently() : null;
    const decoded = token !== null ? jwt_decode(token) : null;
    const role = decoded !== null ? decoded['http://aukportal.net/roles'][0] : null;

    const user = isAuthenticated ? await auth0Client.getUser() : null;
    this.setState({
      isLoading: false, isAuthenticated, user, role,
    });
  }

  async SetRoleState() {
    const auth0Client = await createAuth0Client(this.config);
    const isAuthenticated = await auth0Client.isAuthenticated();
    const token = isAuthenticated ? await auth0Client.getTokenSilently() : null;
    const decoded = token !== null ? jwt_decode(token) : null;
    const role = decoded !== null ? decoded['http://aukportal.net/roles'][0] : null;

    this.setState({ role });
  }

  render() {
    const {
      auth0Client,
      currentState,
      isLoading,
      isAuthenticated,
      isAccountLinked,
      isEmailVerified,
      hasAcceptedAuthRequest,
      user,
      role,
    } = this.state;
    const { children } = this.props;

    let configObject;

    if (isAuthenticated) {
      configObject = {
        isLoading,
        currentState,
        isAuthenticated,
        isAccountLinked,
        isEmailVerified,
        hasAcceptedAuthRequest,
        user,
        role,
        SetRoleState: (...p) => this.SetRoleState(...p),
        continueAuth0: (...p) => this.continueAuth0(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        logout: (...p) => auth0Client.logout(...p),
      };
    } else {
      configObject = {
        isLoading,
        currentState,
        isAuthenticated,
        isAccountLinked,
        isEmailVerified,
        hasAcceptedAuthRequest,
        user,
        continueAuth0: (...p) => this.continueAuth0(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => {
          'test';
        },
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        logout: (...p) => auth0Client.logout(...p),
      };
    }

    return (
      <Auth0Context.Provider value={configObject}>
        {children}
      </Auth0Context.Provider>
    );
  }
}
