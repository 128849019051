import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { capitalize } from 'lodash';

function ConfirmationModal({
  onConfirmClick, itemName, confirmationType, showModalCallback, showModal = false,
}) {
  const handleClose = () => showModalCallback();

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{capitalize(confirmationType)} Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to {confirmationType} '{itemName}'?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button id={itemName} onClick={onConfirmClick}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
