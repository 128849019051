import { FormatResponseMessage } from '../Util/FormatResponseMessage';

function GetAPIUrl({
  farm,
  reportType,
  validFrom = null,
  validTo = null,
  expirationDate = null,
  reportName = null,
  field = null,
  selectedUser = null,
  documentSummary = false,
}) {
  let apiUrl = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/soilhealthdocs?farm=${farm}&reporttype=${reportType}`;
  if (reportName) {
    apiUrl += `&reportName=${reportName}`;
  }
  if (validFrom) {
    apiUrl += `&validFrom=${validFrom}`;
  }
  if (validTo) {
    apiUrl += `&validTo=${validTo}`;
  }
  if (expirationDate) {
    apiUrl += `&expirationDate=${expirationDate}`;
  }
  if (field) {
    apiUrl += `&field=${field}`;
  }
  if (selectedUser) {
    apiUrl += `&selecteduser=${selectedUser}`;
  }
  if (documentSummary) {
    apiUrl += `&documentSummary=${documentSummary}`;
  }

  return apiUrl;
}

export async function CreateSoilHealthDocs({
  file,
  jwtToken,
  farm,
  reportType,
  reportName,
  expirationDate = null,
  field = null,
  selectedUser = null,
}) {
  const formData = new FormData();
  formData.append('soilhealthfiles', file, file.name);
  const apiUrl = GetAPIUrl({
    farm,
    reportType,
    expirationDate,
    reportName,
    field,
    selectedUser,
  });
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    body: formData,
  }).then((result) => result);
  return FormatResponseMessage(response);
}

export async function ReadSoilHealthDocs({
  jwtToken,
  farm,
  reportType,
  validFrom = null,
  validTo = null,
  reportName = null,
  field = null,
  selectedUser = null,
  documentSummary = false,
}) {
  const apiUrl = GetAPIUrl({
    farm,
    reportType,
    validFrom,
    validTo,
    reportName,
    field,
    selectedUser,
    documentSummary,
  });
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((result) => result);
  return FormatResponseMessage(response);
}

export async function DeleteSoilHealthDocs({
  jwtToken,
  farm,
  reportType,
  reportName,
  field = null,
  selectedUser = null,
}) {
  const apiUrl = GetAPIUrl({
    farm,
    reportType,
    reportName,
    field,
    selectedUser,
  });
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((result) => result);
  return FormatResponseMessage(response);
}
