import React from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import '../../Styles/Common/DateRangePicker.css';

function DateRangePicker({ onValidFromChange, validFromDate = null, validToDate = null }) {
  return (
    <div className="date__range__picker">
      <Form.Group className="date__range__picker_form__group">
        <Form.Label>Valid From</Form.Label><br />
        <DatePicker
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
          placeholderText="-----"
          dateFormat="dd/MM/yyyy"
          selected={validFromDate}
          onFocus={(e) => e.target.blur()}
          onChange={onValidFromChange}
          required
        />
      </Form.Group>
      <Form.Group className="date__range__picker_form__group">
        <Form.Label>Valid To</Form.Label><br />
        <DatePicker
          placeholderText="-----"
          dateFormat="dd/MM/yyyy"
          selected={validToDate}
          disabled
        />
      </Form.Group>
    </div>
  );
}

export default DateRangePicker;
