import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import '../../Styles/Components/SoilHealthPanel/DeleteReportIcon.css';

function DeleteReportButtonModalTrigger({ reportName, onDeleteIconClick }) {
  return (
    <Button
      id={reportName}
      className="delete__report__button"
      variant="outline-warning"
      onClick={onDeleteIconClick}
    >
      <FontAwesomeIcon icon={faTrashCan} size="2x" />
      <div>Delete</div>
    </Button>
  );
}

export default DeleteReportButtonModalTrigger;
