import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../Auth/Auth0-Context';
import { useFarmData } from './FarmData-Context';
import '../Styles/Header.css';
import insightLogo from '../Images/Insight_Logo.png';
import { FormatDate } from '../Util/FormatDate';

function GenerateLink({ name = null, link = null }) {
  const {
    UpdateFarmFieldData,
    SetCurrentUserState,
    SetTriggerSoilHealthReportsUpdateState,
    SetSoilHealthReportsState,
    SetCurrentValidFromDateState,
    SetCurrentFarmState,
    SetCurrentFieldState,
    SetCurrentValidToDateState,
    SetSelectedPDFURLState,
  } = useFarmData();

  return (
    <li
      className="nav-item active"
      onClick={() => {
        SetCurrentUserState();
        UpdateFarmFieldData();
        SetCurrentFarmState(null);
        SetSelectedPDFURLState(null);
        SetCurrentFieldState('all fields');
        SetSoilHealthReportsState([]);
        SetCurrentValidFromDateState(FormatDate({ date: new Date() }));
        SetCurrentValidToDateState(null);
        SetTriggerSoilHealthReportsUpdateState(true);
      }}
    >
      <Link className="nav-link header-link" to={link ? `/${link}` : ''}>
        {name ?? 'Home'}
      </Link>
    </li>
  );
}

export default function Header() {
  const {
    isLoading,
    isAccountLinked,
    isEmailVerified,
    hasAcceptedAuthRequest,
    user,
    role,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const {
    UpdateFarmFieldData,
    SetCurrentUserState,
    SetTriggerSoilHealthReportsUpdateState,
    SetSoilHealthReportsState,
    SetSelectedPDFURLState,
    SetCurrentValidFromDateState,
    SetCurrentFarmState,
    SetCurrentFieldState,
    SetCurrentValidToDateState,
  } = useFarmData();

  const establishVisibility = ({ componentLink, currentRole }) => {
    if (componentLink === 'admin' && currentRole === 'Admin') {
      return true;
    }

    if (window.location.pathname !== '/' && componentLink === 'home') {
      return true;
    }

    if (window.location.pathname === '/soilhealth' && componentLink === 'soilhealthglossary') {
      return true;
    }

    if (window.location.pathname === '/soilhealthglossary' && componentLink === 'soilhealth') {
      return true;
    }

    return false;
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light nav-header">
        <Link
          className="navbar-brand mb-0 h1 header-link"
          to="/"
          onClick={() => {
            SetCurrentUserState();
            UpdateFarmFieldData();
            SetCurrentFarmState(null);
            SetSelectedPDFURLState(null);
            SetCurrentFieldState('all fields');
            SetSoilHealthReportsState([]);
            SetCurrentValidFromDateState(FormatDate({ date: new Date() }));
            SetCurrentValidToDateState(null);
            SetTriggerSoilHealthReportsUpdateState(true);
          }}
        >
          <img
            className="navbar-brand-main-logo"
            src={insightLogo}
            alt="Insight Logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {isAccountLinked
              && isEmailVerified
              && hasAcceptedAuthRequest
              && window.location.pathname !== '/signup' && (
                <>
                  {establishVisibility({ componentLink: 'home', currentRole: role }) && (
                  <GenerateLink />
                  )}
                  {establishVisibility({ componentLink: 'precisionmapping', currentRole: role }) && (
                  <GenerateLink name="Precision Mapping" link="precisionmapping" />
                  )}
                  {establishVisibility({ componentLink: 'deliverynotifications', currentRole: role }) && (
                  <GenerateLink name="Delivery Notifications" link="deliverynotifications" />
                  )}
                  {establishVisibility({ componentLink: 'soilhealth', currentRole: role }) && (
                  <GenerateLink name="Soil Health" link="soilhealth" />
                  )}
                  {establishVisibility({ componentLink: 'soilhealthglossary', currentRole: role }) && (
                  <GenerateLink name="Soil Health Glossary" link="soilhealthglossary" />
                  )}
                  {establishVisibility({ componentLink: 'admin', currentRole: role }) && (
                  <GenerateLink name="Admin" link="admin" />
                  )}
                </>
            )}
          </ul>

          <div className="navbar-end">
            {/* if there is no user. show the login button */}
            {!isLoading
              && !user
              && isAccountLinked
              && isEmailVerified
              && hasAcceptedAuthRequest
              && window.location.pathname !== '/signup' && (
                <button onClick={loginWithRedirect} className="navbar-item">
                  Login
                </button>
            )}

            {/* if account isn't linked or email not verified show a basic logout */}
            {!isLoading && !user && (isAccountLinked || isEmailVerified) && (
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="navbar-item"
              >
                Logout
              </button>
            )}

            {/* if there is a user. show user name and logout button */}
            {!isLoading && user && (
              <>
                <span>
                  <span className="font-italic font-weight-light signedin-user-title">
                    {user.name} -
                  </span>
                </span>
                <button
                  onClick={() => logout({ returnTo: window.location.origin })}
                  className="navbar-item header-link"
                >
                  Logout
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}
