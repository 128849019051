import JSZip from 'jszip';
import { forEach } from 'lodash';

async function GetZippedFiles({ zippedFiles }) {
  return JSZip.loadAsync(zippedFiles);
}

async function DecompressFile({ fileList, reportName }) {
  return fileList.files[reportName]
    .async('uint8array');
}

async function ExtractObjectUrl({ fileList, reportName }) {
  const uncompressedFile = await DecompressFile({ fileList, reportName });
  const file = new Blob([(uncompressedFile.buffer)], { type: 'application/pdf' });

  return window.URL.createObjectURL(file);
}

function DownloadFromUrl(url, name) {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export async function ShowReportsInNewWindows({ reports }) {
  // remove size check, api must return 204 not 200 and not set the object
  // if empty response is returned.
  if (reports !== undefined && reports.reportsList.size > 22) {
    const fileList = await GetZippedFiles({ zippedFiles: reports.reportsList });

    forEach(fileList.files, async (file, reportName) => {
      const url = await ExtractObjectUrl({ fileList, reportName });
      window.open(url);
    });
  }
}

export async function DownloadPDFReport({ reports }) {
  // remove size check, api must return 204 not 200 and not set the object
  // if empty response is returned.
  if (reports !== undefined && reports.reportsList.size > 22) {
    const fileList = await GetZippedFiles({ zippedFiles: reports[0].reportsList });

    forEach(fileList.files, async (file, reportName) => {
      const url = await ExtractObjectUrl({ fileList, reportName });
      DownloadFromUrl(url, `${reportName}.pdf`);
    });
  }
}

export async function DownloadSectionReports({ reports }) {
  const url = window.URL.createObjectURL(reports.reportsList);
  DownloadFromUrl(url, `${reports.reportType}.zip`);
}

export async function GenerateReportsList({ reports }) {
  // remove size check, api must return 204 not 200 and not set the object
  // if empty response is returned.

  if (reports !== undefined && reports.reportsList !== null && reports.reportsList.size > 22) {
    const fileList = await GetZippedFiles({ zippedFiles: reports.reportsList });

    const reportList = [];

    forEach(fileList.files, async (file, reportName) => {
      const Report = {
        name: null,
        file: null,
        url: null,
      };

      Report.name = reportName;
      reportList.push(Report);
    });

    const functionCalls = [];

    forEach(reportList, (report) => {
      const currentReport = report;

      functionCalls.push(ExtractObjectUrl({ fileList, reportName: currentReport.name })
        .then((returnedUrl) => { currentReport.url = returnedUrl; }));
      functionCalls.push(DecompressFile({ fileList, reportName: currentReport.name })
        .then((returnedFile) => { currentReport.file = returnedFile; }));
    });

    await Promise.all(functionCalls);

    return reportList;
  }
}

export async function ZipFiles({ reportList }) {
  const zip = new JSZip();

  const reportListArray = Object.keys(reportList).map((key) => {
    return reportList[key];
  });

  reportListArray.forEach((report) => {
    zip.file(report.name, report.file);
  });

  const blobFiles = await zip.generateAsync({ type: 'blob' });

  return blobFiles;
}
