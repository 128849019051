import React, { useEffect, useState } from 'react';
import '../Styles/SoilHealthMap.css';
import { useFarmData } from './FarmData-Context';
import SoilHealthMapLegend from './SoilHealthMapLegend.js';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import PrecisionToolBox, {
  CalculateArea,
  UpdateFieldsArray,
  ClearAllFieldLabels,
  SetFieldPolygon,
} from '../Util/PrecisionToolBox.js';
import { ExtractGeoJsonProperties } from '../Util/ExtractGeoJsonProperties';

let map;
let markersArray = [];
let fieldPolygons;
const allFieldsPolygons = [];
let fieldBoundaryDrawingManager;
let markerZoomListener;

export function HideMarkers() {
  if (map !== undefined) {
    for (let i = 0; i < markersArray.length; i++) {
      markersArray[i].setMap(null);
    }
  }
}

export function ShowMarkers() {
  if (map !== undefined) {
    for (let i = 0; i < markersArray.length; i++) {
      markersArray[i].setMap(map);
    }
  }
}

export const RateColours = (featureProperty, mapGlobal, rateListArray) => {
  if (featureProperty !== undefined) {
    const colours = [
      '#3b007a',
      '#1802ab',
      '#0989b8',
      '#43dbfa',
      '#0acf5c',
      '#5fff4a',
      '#9ceb91',
      '#9ceb91',
      '#caff4f',
      '#f2e338',
      '#ffcf30',
      '#ff9b30',
      '#ff6430',
      '#ff3030',
    ];

    if (rateListArray.length > 100) {
      rateListArray.sort((a, b) => {
        return a.value - b.value;
      });

      const smallestVal = rateListArray[0].value;
      const largestVal = rateListArray[rateListArray.length - 1].value;

      const smallLargeDiff = largestVal - smallestVal;
      const colourChangeIncrements = smallLargeDiff / colours.length;
      const featurevalSmallDiff = featureProperty - smallestVal;
      const selectedColour = Math.floor(
        featurevalSmallDiff / colourChangeIncrements,
      );
      const colour = selectedColour === colours.length
        ? colours[colours.length - 1]
        : colours[selectedColour];

      return colour;
    }
    let colour = '';

    let negativeValues = false;

    const featureRankArray = [];

    mapGlobal.data.forEach((feature) => {
      const featureRank = {
        feaureId: '',
        propertyValue: '',
        rank: '',
      };

      const selectedAnalysisType = document.getElementById(
        'analysis-type-select',
      ).value;

      featureRank.feaureId = feature.getProperty('Feature_Id');
      featureRank.propertyValue = feature.getProperty(selectedAnalysisType);

      featureRankArray.push(featureRank);
    });

    featureRankArray.sort((a, b) => {
      return a.propertyValue - b.propertyValue;
    });

    for (let i = 0; i < featureRankArray.length; i++) {
      featureRankArray[i].rank = i;

      if (featureRankArray[i].propertyValue < 0) {
        negativeValues = true;
      }
    }

    if (featureRankArray[featureRankArray.length - 1] !== undefined) {
      if (
        featureRankArray[featureRankArray.length - 1].propertyValue < 5.1
          && featureRankArray[featureRankArray.length - 1].propertyValue > 0
          && !negativeValues
      ) {
        if (featureProperty > 5.0) {
          colour = colours[0];
        } else if (featureProperty >= 4.5 && featureProperty <= 5.0) {
          colour = colours[1];
        } else if (featureProperty >= 4.0 && featureProperty <= 4.5) {
          colour = colours[2];
        } else if (featureProperty >= 3.5 && featureProperty <= 4.0) {
          colour = colours[3];
        } else if (featureProperty >= 3.0 && featureProperty <= 3.5) {
          colour = colours[4];
        } else if (featureProperty >= 2.7 && featureProperty <= 3.0) {
          colour = colours[5];
        } else if (featureProperty >= 2.3 && featureProperty <= 2.7) {
          colour = colours[6];
        } else if (featureProperty >= 2.0 && featureProperty <= 2.3) {
          colour = colours[7];
        } else if (featureProperty >= 1.7 && featureProperty <= 2.0) {
          colour = colours[8];
        } else if (featureProperty >= 1.3 && featureProperty <= 1.7) {
          colour = colours[9];
        } else if (featureProperty >= 1.0 && featureProperty <= 1.3) {
          colour = colours[10];
        } else if (featureProperty >= 1.0 && featureProperty <= 1.3) {
          colour = colours[11];
        } else if (featureProperty >= 0.5 && featureProperty <= 1.0) {
          colour = colours[12];
        } else if (featureProperty >= 0 && featureProperty <= 0.5) {
          colour = colours[13];
        }

        return colour;
      }
      const diff = colours.length / featureRankArray.length;

      for (let i = 0; i < featureRankArray.length; i++) {
        if (featureRankArray[i].propertyValue == featureProperty) {
          let selectedColour = Math.floor(featureRankArray[i].rank * diff);
          selectedColour = colours.length - selectedColour;
          colour = colours[selectedColour - 1];
        }
      }
      return colour;
    }
    return '';
  }
};

function SoilHealthMap({
  selectedField,
  selectedAnalysis,
  selectedAnalysisType,
  selectedYear,
}) {
  const [boundaryChanged, setBoundaryChanged] = useState(false);
  const {
    Fields, FieldAnalysis, UpdatedField, Markers,
  } = useFarmData();
  const {
    SetMapState,
    SetFieldBoundaryDrawingManagerState,
    SetFieldPolygonGlobalState,
    SetMarkerState,
    fieldPolygonGlobal,
  } = useSoilHealthContext();

  useEffect(() => {
    /* Load the google map into the app, depending on whether this is the first visit during the session
    the initial script may need to be appended to the HTML page.
    */
    if (document.getElementById('googlemapscript') === null) {
      const googleMapScript = document.createElement('script');
      googleMapScript.id = 'googlemapscript';
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_API_KEY
      }&libraries=drawing,geometry`;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener('load', () => {
        map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: 52.349206954080564, lng: 0.923879995915898 },
          zoom: 16,
          mapTypeId: window.google.maps.MapTypeId.HYBRID,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        });

        fieldBoundaryDrawingManager = new window.google.maps.drawing.DrawingManager({
          drawingControl: false,
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon'],
          },
          polygonOptions: {
            editable: false,
            zIndex: 1,
          },
        });

        const infowindow = new window.google.maps.InfoWindow();

        window.google.maps.event.addListener(
          fieldBoundaryDrawingManager,
          'polygoncomplete',
          (polygon) => {
            SetFieldPolygonGlobalState(polygon);

            document.getElementById('save-field-boundary-trigger').click();
          },
        );

        map.data.addListener('mouseover', (event) => {
          const selectedAnalysisType = document.getElementById(
            'analysis-type-select',
          ).value;
          if (selectedAnalysisType.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            const feature = event.feature.getGeometry().getArray();

            for (let i = 0; i < feature.length; i++) {
              for (let j = 0; j < feature[i].getLength(); j++) {
                bounds.extend(feature[i].getAt(j));
              }
            }

            map.data.overrideStyle(event.feature, { fillOpacity: 1 });

            infowindow.setContent(
              event.feature.getProperty(selectedAnalysisType).toString(),
            );
            infowindow.setPosition(bounds.getCenter());
            infowindow.open(map);
          }
        });

        map.data.addListener('mouseout', (event) => {
          const selectedAnalysisType = document.getElementById(
            'analysis-type-select',
          ).value;

          if (selectedAnalysisType.length > 0) {
            map.data.overrideStyle(event.feature, { fillOpacity: 0.75 });

            infowindow.close();
          }
        });

        SetFieldBoundaryDrawingManagerState(fieldBoundaryDrawingManager);
        SetMapState(map);
      });
    } else if (
      document.getElementById('map').childNodes.length === 0
      || map == undefined
    ) {
      if (window.google) {
        map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: 52.349206954080564, lng: 0.923879995915898 },
          zoom: 15.5,
          mapTypeId: window.google.maps.MapTypeId.HYBRID,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        });

        const infowindow = new window.google.maps.InfoWindow();

        map.data.addListener('mouseover', (event) => {
          const selectedAnalysisType = document.getElementById(
            'analysis-type-select',
          ).value;
          if (selectedAnalysisType.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            const feature = event.feature.getGeometry().getArray();

            for (let i = 0; i < feature.length; i++) {
              for (let j = 0; j < feature[i].getLength(); j++) {
                bounds.extend(feature[i].getAt(j));
              }
            }

            map.data.overrideStyle(event.feature, { fillOpacity: 1 });

            infowindow.setContent(
              event.feature.getProperty(selectedAnalysisType).toString(),
            );
            infowindow.setPosition(bounds.getCenter());
            infowindow.open(map);
          }
        });

        map.data.addListener('mouseout', (event) => {
          const selectedAnalysisType = document.getElementById(
            'analysis-type-select',
          ).value;

          if (selectedAnalysisType.length > 0) {
            map.data.overrideStyle(event.feature, { fillOpacity: 0.75 });

            infowindow.close();
          }
        });

        if (
          fieldBoundaryDrawingManager == undefined
          || fieldBoundaryDrawingManager.length === 0
        ) {
          fieldBoundaryDrawingManager = new window.google.maps.drawing.DrawingManager({
            drawingControl: false,
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: ['polygon'],
            },
            polygonOptions: {
              editable: false,
              zIndex: 1,
            },
          });

          window.google.maps.event.addListener(
            fieldBoundaryDrawingManager,
            'polygoncomplete',
            (polygon) => {
              SetFieldPolygonGlobalState(polygon);

              document.getElementById('save-field-boundary-trigger').click();
            },
          );

          SetFieldBoundaryDrawingManagerState(fieldBoundaryDrawingManager);
        } else {
          window.google.maps.event.addListener(
            fieldBoundaryDrawingManager,
            'polygoncomplete',
            (polygon) => {
              SetFieldPolygonGlobalState(polygon);

              document.getElementById('save-field-boundary-trigger').click();
            },
          );

          SetFieldBoundaryDrawingManagerState(fieldBoundaryDrawingManager);
        }
        SetMapState(map);
      }
    }

    if (Fields.length > 0 && map != undefined) {
      UpdateFieldsArray();
    }

    for (let i = 0; i < allFieldsPolygons.length; i++) {
      allFieldsPolygons[i].setMap(null);
    }

    if (
      selectedField === 'all fields'
      && Fields.length > 0
      && map != undefined
    ) {
      const bounds = new window.google.maps.LatLngBounds();
      if (fieldPolygons !== undefined) {
        fieldPolygons.setMap(null);
      }

      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      ClearAllFieldLabels();

      for (let i = 0; i < Fields.length; i++) {
        const fieldCoords = [];
        let fieldBoundary = JSON.parse(Fields[i].fieldCoords);

        if (typeof fieldBoundary === 'string') {
          if (fieldBoundary.length > 0) {
            fieldBoundary = JSON.parse(fieldBoundary);
          } else {
            fieldBoundary = null;
          }
        }

        if (fieldBoundary !== null) {
          for (let j = 0; j < fieldBoundary.coordinates[0].length; j++) {
            const latLong = fieldBoundary.coordinates[0][j];
            fieldCoords.push({ lat: latLong[1], lng: latLong[0] });
            const googleLatLng = new window.google.maps.LatLng(
              latLong[1],
              latLong[0],
            );
            bounds.extend(googleLatLng);
          }

          const fieldPolygonsnew = new window.google.maps.Polygon({
            map,
            paths: fieldCoords,
            editable: false,
          });

          fieldPolygonsnew.set('fieldname', Fields[i].name);
          CalculateArea(map, fieldPolygonsnew, 'allfieldarealabel');

          allFieldsPolygons.push(fieldPolygonsnew);
        }
      }

      map.fitBounds(bounds);
      const zoomLevel = map.getZoom();

      if (zoomLevel < 10) {
        map.setZoom(13);
      }
    } else if (
      Fields.length > 0
      && selectedField.length === 0
      && selectedAnalysis.length === 0
      && map != undefined
    ) {
      let fieldCoords = [];
      const bounds = new window.google.maps.LatLngBounds();
      ClearAllFieldLabels();

      if (Fields[0].fieldCoords !== null) {
        document.getElementById(Fields[0].key).selected = 'true';
        let fieldBoundary = JSON.parse(Fields[0].fieldCoords);

        if (typeof fieldBoundary === 'string') {
          if (fieldBoundary.length > 0) {
            fieldBoundary = JSON.parse(fieldBoundary);
          } else {
            fieldBoundary = null;
          }
        }

        if (fieldBoundary !== null) {
          for (let i = 0; i < fieldBoundary.coordinates[0].length; i++) {
            const latLong = fieldBoundary.coordinates[0][i];
            fieldCoords.push({ lat: latLong[1], lng: latLong[0] });
            const googleLatLng = new window.google.maps.LatLng(
              latLong[1],
              latLong[0],
            );
            bounds.extend(googleLatLng);
          }

          if (fieldPolygons !== undefined) {
            fieldPolygons.setMap(null);
          }

          map.data.forEach((feature) => {
            map.data.remove(feature);
          });

          if (selectedAnalysis.length === 0) {
            if (fieldPolygons !== undefined && boundaryChanged) {
              fieldCoords = fieldPolygons.getPaths();
            }

            fieldPolygons = new window.google.maps.Polygon({
              map,
              paths: fieldCoords,
              editable: false,
            });

            fieldPolygons.set('fieldname', Fields[0].name);
            CalculateArea(map, fieldPolygons, 'fieldarealabel');
            SetFieldPolygonGlobalState(fieldPolygons);
            SetFieldPolygon(fieldPolygons);
          }

          map.fitBounds(bounds);
        } else {
          if (fieldPolygons !== undefined) {
            fieldPolygons.setMap(null);
          }

          map.data.forEach((feature) => {
            map.data.remove(feature);
          });
        }
      } else if (fieldPolygons !== undefined) {
        fieldPolygons.setMap(null);
      }
    } else if (
      selectedField.length > 0
      && selectedAnalysis.length === 0
      && map !== undefined
    ) {
      ClearAllFieldLabels();

      for (let i = 0; i < Fields.length; i++) {
        if (Fields[i].key === selectedField) {
          let fieldCoords = [];
          const bounds = new window.google.maps.LatLngBounds();
          let fieldBoundary = JSON.parse(Fields[i].fieldCoords);

          if (typeof fieldBoundary === 'string') {
            if (fieldBoundary.length > 0) {
              fieldBoundary = JSON.parse(fieldBoundary);
            } else {
              fieldBoundary = null;
            }
          }

          if (fieldBoundary !== null) {
            for (let j = 0; j < fieldBoundary.coordinates[0].length; j++) {
              const latLong = fieldBoundary.coordinates[0][j];
              fieldCoords.push({ lat: latLong[1], lng: latLong[0] });
              const googleLatLng = new window.google.maps.LatLng(
                latLong[1],
                latLong[0],
              );
              bounds.extend(googleLatLng);
            }

            if (fieldPolygons !== undefined) {
              fieldPolygons.setMap(null);
            }

            map.data.forEach((feature) => {
              map.data.remove(feature);
            });

            if (selectedAnalysis.length === 0) {
              if (fieldPolygons !== undefined && boundaryChanged) {
                fieldCoords = fieldPolygons.getPaths();
              }

              fieldPolygons = new window.google.maps.Polygon({
                map,
                paths: fieldCoords,
                editable: false,
              });

              fieldPolygons.set('fieldname', Fields[i].name);
              CalculateArea(map, fieldPolygons, 'fieldarealabel');
              SetFieldPolygon(fieldPolygons);
            }

            map.fitBounds(bounds);
          } else {
            if (fieldPolygons !== undefined) {
              fieldPolygons.setMap(null);
            }
            map.data.forEach((feature) => {
              map.data.remove(feature);
            });
          }
        }
      }
    } else if (selectedAnalysis.length > 0) {
      if (fieldPolygons !== undefined) {
        fieldPolygons.setMap(null);
      }
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
    } else if (Fields.length === 0) {
      if (fieldPolygons !== undefined) {
        fieldPolygons.setMap(null);
      }

      if (map !== undefined) {
        map.data.forEach((feature) => {
          map.data.remove(feature);
        });
      }
    }

    // Marker Logic
    if (map !== undefined) {
      for (let i = 0; i < markersArray.length; i++) {
        markersArray[i].setMap(null);
      }

      if (selectedAnalysis.length === 0) {
        markersArray = [];

        if (Fields.length > 0) {
          for (let i = 0; i < Markers.length; i++) {
            const myLatLng = {
              lat: parseFloat(Markers[i].latitude),
              lng: parseFloat(Markers[i].longitude),
            };

            const icon = {
              url: 'Images/Utility-Marker-Icon.png',
              scaledSize: new window.google.maps.Size(30, 30), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(15, 15), // anchor
            };

            const marker = new window.google.maps.Marker({
              position: myLatLng,
              map,
              title: Markers[i].description,
              icon,
            });

            marker.set('id', Markers[i].markerid);

            markersArray.push(marker);

            const infowindow = new window.google.maps.InfoWindow({
              content: `<p>${Markers[i].description}</p>`,
            });

            marker.addListener('click', () => {
              infowindow.open(map, marker);
            });

            window.google.maps.event.addListener(marker, 'dblclick', (e) => {
              SetMarkerState(marker);
              document.getElementById('delete-marker-modal-trigger').click();
            });

            window.google.maps.event.removeListener(markerZoomListener);

            markerZoomListener = window.google.maps.event.addListener(
              map,
              'zoom_changed',
              () => {
                const zoom = map.getZoom();
                if (zoom < 17) {
                  for (let i = 0; i < markersArray.length; i++) {
                    markersArray[i].setMap(null);
                  }
                } else {
                  for (let i = 0; i < markersArray.length; i++) {
                    markersArray[i].setMap(map);
                  }
                }
              },
            );
          }
        }
      } else {
        if (markerZoomListener !== undefined) {
          window.google.maps.event.removeListener(markerZoomListener);
        }

        ClearAllFieldLabels();
      }
    }

    // Analysis logic

    if (selectedAnalysis.length > 0) {
      for (let i = 0; i < FieldAnalysis.length; i++) {
        if (
          FieldAnalysis[i].name === selectedAnalysis
          && FieldAnalysis[i].year === selectedYear
          && FieldAnalysis[i].fieldid === selectedField
        ) {
          const geoJsonObject = JSON.parse(FieldAnalysis[i].geojson);
          map.data.addGeoJson(geoJsonObject);
        }
      }
    }

    // Analysis Type Logic
    if (selectedAnalysisType.length > 0) {
      for (let i = 0; i < FieldAnalysis.length; i++) {
        if (
          FieldAnalysis[i].name === selectedAnalysis
          && FieldAnalysis[i].year === selectedYear
          && FieldAnalysis[i].fieldid === selectedField
        ) {
          let propertiesListArray = [
            {
              properties: '',
            },
          ];
          const ratesListArray = [];

          if (
            FieldAnalysis.length > 0
            && document.getElementById('analysis-select') !== null
          ) {
            const selectedAnalysis = document.getElementById('analysis-select').value;

            for (let i = 0; i < FieldAnalysis.length; i++) {
              if (
                FieldAnalysis[i].name === selectedAnalysis
                && FieldAnalysis[i].year === selectedYear
              ) {
                propertiesListArray = ExtractGeoJsonProperties(
                  FieldAnalysis[i],
                );
              }
            }

            for (let i = 0; i < propertiesListArray.length; i++) {
              for (
                let j = 0;
                j < propertiesListArray[i].properties.length;
                j++
              ) {
                if (
                  propertiesListArray[i].properties[j].name
                    === selectedAnalysisType
                  && propertiesListArray[i].properties[j].analysis
                    === selectedAnalysis
                ) {
                  ratesListArray[i] = {
                    rate: i,
                    featureId: propertiesListArray[i].featureId,
                    value: propertiesListArray[i].properties[j].value,
                    type: propertiesListArray[i].properties[j].type,
                    unitofmeasure:
                      propertiesListArray[i].properties[j].unitofmeasure,
                  };
                }
              }
            }
          }

          map.data.setStyle((feature) => {
            switch (selectedAnalysisType) {
              default:
                const featureProperty = feature.getProperty(selectedAnalysisType);

                return {
                  fillColor: RateColours(featureProperty, map, ratesListArray),
                  strokeWeight: 1,
                  fillOpacity: 0.75,
                };
            }
          });
        }
      }
    } else {
      try {
        map.data.setStyle((feature) => {
          return {
            fillColor: '#0a0a0a',
            strokeWeight: 1,
            fillOpacity: 0.25,
          };
        });
      } catch (error) {}
    }
  }, [
    selectedField,
    selectedAnalysis,
    selectedAnalysisType,
    selectedYear,
    Fields,
    UpdatedField,
    FieldAnalysis,
    boundaryChanged,
    fieldPolygonGlobal,
    Markers,
  ]);

  return (
    <>
      <div id="map" />
      <SoilHealthMapLegend
        selectedAnalysisType={selectedAnalysisType}
        selectedYear={selectedYear}
      />
      <PrecisionToolBox selectedField={selectedField} />
      <a
        id="info-box-modal-trigger"
        data-target="#info-box-modal"
        data-toggle="modal"
      />
      <a
        id="delete-marker-modal-trigger"
        data-target="#delete-marker-modal"
        data-toggle="modal"
      />
    </>
  );
}

export default SoilHealthMap;
