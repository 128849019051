import React, { useEffect, useState } from 'react';
import { useFarmData } from './FarmData-Context';
import '../Styles/GCIGraphModal.css';

function GCIGraphModal() {
  const [selectedLink, setSelectedLink] = useState('');
  const { GCIStationLinks } = useFarmData();

  useEffect(() => {
    if (GCIStationLinks.length > 0) {
      const first = 'Main';
      GCIStationLinks.sort((x, y) => {
        return x.link_Name.includes(first)
          ? -1
          : y.link_Name.includes(first)
            ? 1
            : 0;
      });

      setSelectedLink(GCIStationLinks[0].link);
    }
  }, [GCIStationLinks]);

  const gciStationLinkList = GCIStationLinks.length > 0
    && GCIStationLinks.map((item, i) => {
      return (
        <option key={i} value={item.link}>
          {item.link_Name}
        </option>
      );
    }, this);

  return (
    <div
      className="modal fade"
      id="gci-graph-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div
        className="modal-dialog mw-100 w-95 modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Growers Choice Interactive
              <select
                id="station-link-select"
                className="soilhealth-filters custom-select"
                onChange={(e) => {
                  setSelectedLink(e.target.value);
                }}
              >
                {gciStationLinkList}
              </select>
            </h5>
          </div>
          <div id="gci-modal-body" className="modal-body">
            <iframe
              className="gcigraphpanel"
              id="gcigraphpanel"
              name="contentPanel1"
              height="740"
              scrolling="no"
              src={selectedLink}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GCIGraphModal;
