import React from 'react';
import { useFarmData } from './FarmData-Context';
import '../Styles/AssignHomePageLinkModal.css';

function AssignHomePageLinkModalThree(props) {
  const { SupportedHomePageLinks, PostUserProfile } = useFarmData();

  const homepageLinkList = SupportedHomePageLinks.length > 0
    && SupportedHomePageLinks.map((item, i) => {
      return (
        <tr
          onClick={(e) => {
            const splitId = e.target.id.split('--');

            PostUserProfile(splitId[0], splitId[1]);
            document.getElementById('assign-homepage-link-three-close').click();
          }}
          key={i}
          className={props.selectedPanel}
          id={`${item.fileDownloadName}--` + 'homepagepanel3'}
        >
          <td id={`${item.fileDownloadName}--` + 'homepagepanel3'}>
            {item.fileDownloadName}
          </td>
          <td id={`${item.fileDownloadName}--` + 'homepagepanel3'}>
            <img
              id={`${item.fileDownloadName}--` + 'homepagepanel3'}
              className="assign-homepage-image"
              src={`data:image/jpeg;base64,${item.fileContents}`}
            />
          </td>
        </tr>
      );
    }, this);

  return (
    <div
      className="modal fade"
      id="assign-homepage-link-three"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form id="add-field-form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add Link
              </h5>
              <button
                id="assign-homepage-link-three-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body homepage-link-table-modal-body">
              <table className="table table-striped homepage-link-table">
                <thead>
                  <tr>
                    <th>Link</th>
                    <th>Icon</th>
                  </tr>
                </thead>
                <tbody>{homepageLinkList}</tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AssignHomePageLinkModalThree;
