import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useFarmData } from '../FarmData-Context';
import '../../Styles/Components/SoilHealthPanel/UploadReportButton.css';

function UploadReportButton() {
  const { SetUploadSoilHealthReportModalState } = useFarmData();

  return (
    <Button
      id="uploadreportbutton"
      variant="outline-warning"
      onClick={() => SetUploadSoilHealthReportModalState(true)}
    >
      <FontAwesomeIcon icon={faFileArrowUp} size="2x" />
      <div>
        Upload
      </div>
    </Button>
  );
}

export default UploadReportButton;
