import React, { useEffect, useState } from 'react';
import { useUsers } from './Users-Context.js';
import { useFarmData } from './FarmData-Context';
import UserMaintenanceModal from './UserMaintenanceModal.js';

function UserMaintenance() {
  const { GetUsers, Users } = useUsers();
  const [initialUpdate, setInitialUpdate] = useState(true);
  const [accountName, setAccountName] = useState(null);
  const [accountEmail, setAccountEmail] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const { UpdateFarmFieldData, SetGLGMChangedState } = useFarmData();

  useEffect(() => {
    if (initialUpdate) {
      GetUsers(accountName, accountEmail, pageNumber);
      setInitialUpdate(false);
    }
  });

  const usersList = Users.length > 0
    && Users.map((item, i) => {
      return (
        <tr
          key={i}
          id={item.user_id}
          data-toggle="modal"
          data-target={`#modal${item.user_id.split('|')[1]}`}
          onClick={(e) => {
            SetGLGMChangedState(false);
            UpdateFarmFieldData(null, e.target.id);

            const classList = document.getElementsByClassName('role-select');

            for (i = 0; i < classList.length; i++) {
              classList[i].value = item.roleid;
            }
          }}
        >
          <td id={item.user_id}>
            {item.gci_access == 'true' ? 'true' : 'false'}
          </td>
          <td id={item.user_id}>
            {item.glgmnumber.length > 0 ? 'true' : 'false'}
          </td>
          <td id={item.user_id}>{item.ifsnumber}</td>
          <td id={item.user_id}>{item.name}</td>
          <td id={item.user_id}>{item.email}</td>
          <td id={item.user_id}>{item.farmname}</td>
          <td id={item.user_id}>{item.tradingname}</td>
          <td id={item.user_id}>{item.phonenumber}</td>
          <td id={item.user_id}>{item.mobilenumber}</td>
          <td id={item.user_id}>{item.county}</td>
          <td id={item.user_id}>{item.postcode}</td>
        </tr>
      );
    }, this);

  const modalList = Users.length > 0
    && Users.map((item, i) => {
      return (
        <div key={i}>
          <UserMaintenanceModal user={item} />
        </div>
      );
    }, this);
  return (
    <div>
      <div className="container">
        <h4 className="text-center">
          <i>User Maintenance</i>
        </h4>
        <form>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Account Name"
              aria-label="Account Name"
              aria-describedby="button-addon"
              onInput={(e) => {
                if (e.target.value.length > 3) {
                  setAccountName(e.target.value);
                  GetUsers(e.target.value, accountEmail, pageNumber);
                } else {
                  setAccountName(null);
                  GetUsers(null, accountEmail, pageNumber);
                }
              }}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Account Email"
              aria-label="Account Email"
              aria-describedby="button-addon"
              onInput={(e) => {
                if (e.target.value.length > 3) {
                  setAccountEmail(e.target.value);
                  GetUsers(accountName, e.target.value, pageNumber);
                } else {
                  setAccountEmail(null);
                  GetUsers(accountName, null, pageNumber);
                }
              }}
            />
          </div>
        </form>
      </div>
      <div className="admin-data-table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>GCI Access</th>
              <th>GLGM Linked</th>
              <th>IFS No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Farm Name</th>
              <th>Trading Name</th>
              <th>Phone No</th>
              <th>Mobile No</th>
              <th>County</th>
              <th>Postcode</th>
            </tr>
          </thead>
          <tbody>{usersList}</tbody>
        </table>
      </div>
      <nav
        className="admin-pagination-nav"
        aria-label="Page navigation example"
      >
        <ul className="pagination justify-content-center">
          <li className={pageNumber == 0 ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              tabIndex="-1"
              onClick={() => {
                setPageNumber(pageNumber - 1);
                GetUsers(accountName, accountEmail, pageNumber - 1);
              }}
            >
              Previous
            </a>
          </li>
          <li className={pageNumber == 0 ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              onClick={() => {
                setPageNumber(pageNumber - 1);
                GetUsers(accountName, accountEmail, pageNumber - 1);
              }}
            >
              {pageNumber}
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              {pageNumber + 1}
            </a>
          </li>
          <li className={!usersList ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              onClick={() => {
                setPageNumber(pageNumber + 1);
                GetUsers(accountName, accountEmail, pageNumber + 1);
              }}
            >
              {pageNumber + 2}
            </a>
          </li>
          <li className={!usersList ? 'page-item disabled' : 'page-item'}>
            <a
              className="page-link"
              href="#"
              onClick={() => {
                setPageNumber(pageNumber + 1);
                GetUsers(accountName, accountEmail, pageNumber + 1);
              }}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
      {modalList}
    </div>
  );
}

export default UserMaintenance;
