import React from 'react';
import { useIFS } from './IFSData-Context';
import { useUsers } from './Users-Context.js';
import { useFarmData } from './FarmData-Context';
import '../Styles/ErrorMessageModal.css';

function ErrorMessageModal() {
  const { SetIFSMessageState, IFSMessage } = useIFS();
  const { UserMessage, SetUserMessageState } = useUsers();
  const { FarmMessage, SetFarmMessageState } = useFarmData();

  return (
    <div
      className="modal fade"
      id="error-message-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Error
            </h5>
          </div>
          <div className="modal-body">
            <p>{UserMessage + IFSMessage + FarmMessage}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                SetUserMessageState('');
                SetIFSMessageState('');
                SetFarmMessageState('');
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorMessageModal;
