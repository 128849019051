import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { forEach } from 'lodash';
import { useFarmData } from '../Components/FarmData-Context';
import { httpStatusCodes } from '../Models/HttpStatusCodes';

function UploadGlossaryModal({ handleModalState, modalState }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    SetShowErrorModalState,
    SetFarmMessageState,
    PostSoilHealthGlossary,
  } = useFarmData();

  const handleFileInputChange = (e) => {
    const { files } = e.target;

    forEach(files, (file) => {
      if (file.type !== 'application/pdf') {
        SetFarmMessageState('The files must all be PDF files.');
        SetShowErrorModalState(true);
        e.target.value = '';
      }
    });

    setSelectedFiles(files);
  };

  const handleSubmit = async () => {
    const apiCalls = [];

    forEach(selectedFiles, (selectedFile) => {
      apiCalls.push(PostSoilHealthGlossary({ file: selectedFile }));
    });

    const response = await Promise.all(apiCalls);

    if (response.Status === httpStatusCodes.badRequest) {
      SetFarmMessageState(
        `The was an error uploading the files. ${response.responseMessage}`,
      );
      SetShowErrorModalState(true);
    }

    setSelectedFiles([]);
    handleModalState(false);
  };

  const handleClose = () => {
    setSelectedFiles([]);
    handleModalState(false);
  };

  return (
    <Modal
      show={modalState}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Glossary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="pdfreportfiles" className="mb-3">
            <Form.Label>Report files</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileInputChange}
              multiple
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Submit</Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UploadGlossaryModal;
