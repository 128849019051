import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/index.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import { Auth0Provider } from './Auth/Auth0-Context';

ReactDOM.render(
  <Auth0Provider>
    <Router>
      <Route component={App} />
    </Router>
  </Auth0Provider>,
  document.getElementById('root'),
);
