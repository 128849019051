import React from 'react';
import { useFarmData } from './FarmData-Context';
import Options from './Common/Options';

function FieldList() {
  const { Fields = [] } = useFarmData();

  return (
    <>
      <option value="all fields">All Fields</option>
      <Options items={Fields} />
    </>
  );
}

export default FieldList;
