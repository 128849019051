import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useIFS } from '../Components/IFSData-Context';
import { useUsers } from '../Components/Users-Context.js';
import { useFarmData } from '../Components/FarmData-Context';

function ErrorMessageReactModal() {
  const { SetIFSMessageState, IFSMessage } = useIFS();
  const { UserMessage, SetUserMessageState } = useUsers();
  const {
    FarmMessage,
    SetFarmMessageState,
    ShowErrorModal,
    SetShowErrorModalState,
  } = useFarmData();

  const handleClose = () => {
    SetUserMessageState('');
    SetIFSMessageState('');
    SetFarmMessageState('');
    SetShowErrorModalState(false);
  };

  return (
    <Modal
      show={ShowErrorModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{UserMessage + IFSMessage + FarmMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorMessageReactModal;
