import React from 'react';
import Form from 'react-bootstrap/Form';

function SelectFormGroupHelper({
  label, onChange, options, value, required = true, disabled = false,
}) {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        value={value ?? ''}
        className="custom-select"
        onChange={onChange}
        required={required}
        disabled={disabled}
      >
        {options}
      </Form.Select>
    </Form.Group>
  );
}

export default SelectFormGroupHelper;
