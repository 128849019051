import React, { useState, useEffect } from 'react';
import { useUsers } from './Users-Context.js';
import { useIFS } from './IFSData-Context.js';
import { useFarmData } from './FarmData-Context';
import '../Styles/UnhandledSignupsModal.css';

function UnhandledSignupsModal(props) {
  const {
    Roles,
    GetAuth0Roles,
    PutAuth0Users,
    DeleteAuth0Users,
    SelectedGLGMFarm,
    SelectedIFSCustomer,
    SelectedPersonCode,
  } = useUsers();
  const { GetGLGMFarms } = useFarmData();
  const { GetIFSCustomers, GetIFSUsers } = useIFS();
  const [ifsNumber, setIfsNumber] = useState('');
  const [glgmNumber, setGlgmNumber] = useState('');
  const [initialMount, setInitialMount] = useState(true);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [personCode, setPersonCode] = useState('');

  useEffect(() => {
    setGlgmNumber(SelectedGLGMFarm);
    setIfsNumber(SelectedIFSCustomer);
    setPersonCode(SelectedPersonCode);

    if (initialMount) {
      GetAuth0Roles();
      setInitialMount(false);
    }
  });

  const roleList = Roles.length > 0
    && Roles.map((item, i) => {
      return (
        <option key={i} name={item.name} id={item.id} value={item.id}>
          {item.name}
        </option>
      );
    }, this);

  const HandleSaveChangesClick = async (id) => {
    if (selectedRoleName === 'Customer' && ifsNumber === '') {
      alert('You must supply an IFS number with the customer role.');
    } else if (selectedRoleName === 'Agronomist' && personCode === '') {
      alert('You must supply an person code with the agronomist role.');
    } else {
      PutAuth0Users(
        id,
        ifsNumber,
        glgmNumber,
        personCode,
        selectedRole,
        selectedRoleName,
      );
      setIfsNumber('');
      setGlgmNumber('');
    }
  };

  const HandleDeleteButtonClick = async (id) => {
    document.getElementsByClassName('modal-close-button')[0].click();
    document.getElementsByClassName('modal-backdrop')[0].remove();
    DeleteAuth0Users(id);
    setIfsNumber('');
    setGlgmNumber('');
  };

  return (
    <>
      <div
        className="modal fade"
        id={`modal${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Handle Sign Up
              </h5>
              <button
                id={props.user.user_id}
                type="button"
                className="btn btn-primary delete-user-button"
                data-toggle="modal"
                data-target={
                  `#confirm-delete${props.user.user_id.split('|')[1]}`
                }
                data-dismiss="modal"
              >
                Delete User
              </button>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <h6>Account Details ({props.user.name})</h6>
                  <p>
                    Email: <i>{props.user.email}</i>
                    <br />
                    Phone No: <i>{props.user.phonenumber}</i>
                  </p>
                  <i>{props.user.address1}</i>
                  <br />
                  <i>{props.user.address2}</i>
                  <br />
                  <i>{props.user.towncity}</i>
                  <br />
                  <i>{props.user.county}</i>
                  <br />
                  <i>{props.user.postcode}</i>
                  <br />
                </div>
                <div className="form-group">
                  <label>
                    <i>
                      <b>Selected Role</b>
                    </i>
                  </label>
                  <br />
                  <div className="input-group mb-3">
                    <select
                      id="role-select"
                      className="custom-select"
                      onChange={(e) => {
                        setSelectedRoleName(
                          document.getElementById(e.target.value).textContent,
                        );
                        setSelectedRole(e.target.value);
                      }}
                    >
                      <option id="none" value="none">
                        Select a Role
                      </option>
                      {roleList}
                    </select>
                  </div>
                </div>
                {selectedRoleName === 'Customer' ? (
                  <>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control signup-ifsno-readonly"
                          placeholder="IFS Account Number"
                          aria-label="IFS Account Number"
                          aria-describedby="button-addon"
                          value={ifsNumber}
                          onInput={(e) => {
                            setIfsNumber(e.target.value);
                          }}
                          onKeyDown={() => {
                            return false;
                          }}
                          onChange={() => {}}
                          required
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon"
                            data-toggle="modal"
                            data-target="#search-ifs-customers"
                            onClick={() => {
                              GetIFSCustomers();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="GLGM Reference"
                          aria-label="GLGM Reference"
                          aria-describedby="button-addon"
                          value={glgmNumber}
                          readOnly
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon"
                            data-toggle="modal"
                            data-target="#search-glgm-farms"
                            onClick={() => {
                              GetGLGMFarms();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : selectedRoleName === 'Agronomist' ? (
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="IFS Person Code"
                        aria-label="IFS Person Code"
                        aria-describedby="button-addon"
                        autoComplete="off"
                        value={personCode}
                        readOnly
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          id="button-addon"
                          data-toggle="modal"
                          data-target="#search-ifs-users"
                          onClick={() => {
                            GetIFSUsers();
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id={props.user.user_id}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                data-toggle="modal"
                data-target={`#confirm-save${props.user.user_id.split('|')[1]}`}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`confirm-save${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">Confirm</div>
            <div className="modal-body">
              Are you sure you want to save the changes?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id={props.user.user_id}
                className="btn btn-success btn-ok"
                data-dismiss="modal"
                onClick={(e) => {
                  HandleSaveChangesClick(e.target.id);
                }}
              >
                Save
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`confirm-delete${props.user.user_id.split('|')[1]}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">Confirm</div>
            <div className="modal-body">
              Are you sure you want to delete the user?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id={props.user.user_id}
                className="btn btn-danger btn-ok"
                data-dismiss="modal"
                onClick={(e) => {
                  HandleDeleteButtonClick(e.target.id);
                }}
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UnhandledSignupsModal;
