import React, { useEffect, useState } from 'react';
import { useFarmData } from '../Components/FarmData-Context';
import { useAuth0 } from '../Auth/Auth0-Context';
import SelectUserModal from '../Modals/SelectUserModal';
import UploadSoilHealthReportModal from '../Modals/UploadSoilHealthReportModal';
import '../Styles/Pages/SoilHealth.css';
import SoilHealthPanelReports from '../Components/SoilHealthPanel/SoilHealthPanelReports';
import GoogleMaps from '../Components/Common/GoogleMaps';
import Loader from '../Components/Loader';
import UserSearchFormGroup from '../Components/Common/UserSearchFormGroup';
import PDFDisplay from '../Components/Common/PDFDisplay';
import DateRangePicker from '../Components/Common/DateRangePicker';
import FarmList from '../Components/FarmList';
import FieldList from '../Components/FieldList';
import SelectFormGroupHelper from '../Components/Common/SelectFormGroupHelper';
import { FormatDate } from '../Util/FormatDate';

function SoilHealth() {
  const [validFromDate, setValidFromDate] = useState(new Date());
  const [validToDate, setValidToDate] = useState(null);
  const { role } = useAuth0();
  const {
    UpdateFarmFieldData,
    CurrentUser,
    SetCurrentFieldState,
    SetCurrentFarmState,
    SetCurrentValidFromDateState,
    SetCurrentValidToDateState,
    SetSoilHealthReportsState,
    SetTriggerSoilHealthReportsUpdateState,
    isLoading,
    Farms,
    CurrentFarm,
    CurrentField,
    SetDisableFieldsState,
    disableFields,
    SelectedPDFURL,
  } = useFarmData();

  const handleFarmChange = ({ target: { value } }) => {
    SetSoilHealthReportsState([]);
    UpdateFarmFieldData(value, CurrentUser);
    SetCurrentFieldState('all fields');
    SetCurrentFarmState(value);
    SetTriggerSoilHealthReportsUpdateState(true);
    SetDisableFieldsState(true);
  };

  const handleFieldChange = ({ target: { value } }) => {
    SetSoilHealthReportsState([]);
    SetCurrentFieldState(value);
    SetTriggerSoilHealthReportsUpdateState(true);
    SetDisableFieldsState(true);
  };

  const handleValidFromDateChange = (date) => {
    setValidFromDate(date);
    SetCurrentValidFromDateState(FormatDate({ date }));

    const validToDate = new Date(date.getTime());
    validToDate.setMonth(validToDate.getMonth() + 12);

    if (validToDate > new Date()) {
      setValidToDate(null);
      SetCurrentValidToDateState(null);

      SetSoilHealthReportsState([]);
      SetTriggerSoilHealthReportsUpdateState(true);
      SetDisableFieldsState(true);
      return;
    }

    setValidToDate(validToDate);
    SetCurrentValidToDateState(FormatDate({ date: validToDate }));

    SetSoilHealthReportsState([]);
    SetTriggerSoilHealthReportsUpdateState(true);
    SetDisableFieldsState(true);
  };

  useEffect(async () => {
    if (!CurrentFarm && Farms.length > 0) {
      SetCurrentFarmState(Farms[0].key);
    }
  }, [Farms]);

  return (
    <div className="soil__health__page">
      <div style={{ display: SelectedPDFURL ? 'none' : 'block' }}>
        <div className="soil__health__input__dropdowns">
          <UserSearchFormGroup disabled={disableFields} />
          <SelectFormGroupHelper
            label="Farm"
            value={CurrentFarm}
            onChange={handleFarmChange}
            options={<FarmList />}
            disabled={disableFields}
          />
          <SelectFormGroupHelper
            label="Field"
            value={CurrentField}
            onChange={handleFieldChange}
            options={<FieldList />}
            disabled={disableFields}
          />
          <DateRangePicker
            onValidFromChange={handleValidFromDateChange}
            validFromDate={validFromDate}
            validToDate={validToDate}
          />
        </div>
        <div className="soil__health__parent__container">
          {isLoading && <Loader />}
          <div className="float-left soil__health__div__container soil__health__map">
            <GoogleMaps />
          </div>
          <div className="float-right soil__health__div__container soil__health__reports">
            <SoilHealthPanelReports sectionTitle="Soil Health Reports" />
            <SoilHealthPanelReports sectionTitle="Precision Nutrient Mapping Reports" />
            <SoilHealthPanelReports sectionTitle="Field Variation Reports" />
            <SoilHealthPanelReports sectionTitle="Nutrient Management Plans" />
            <SoilHealthPanelReports sectionTitle="Grain Analysis Reports" />
            <SoilHealthPanelReports sectionTitle="Soil Management Plans" />
            <SoilHealthPanelReports sectionTitle="PCN Reports" />
            <SoilHealthPanelReports sectionTitle="Nutrient Budgets" />
            <SoilHealthPanelReports sectionTitle="Leaf Tissue Reports" />
          </div>
        </div>
        <UploadSoilHealthReportModal />
        {role === 'Agronomist' || role === 'Admin' ? (<SelectUserModal />) : (null)}
      </div>
      {SelectedPDFURL && (<PDFDisplay />)}
    </div>
  );
}

export default SoilHealth;
