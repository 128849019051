import React, { useEffect, useState } from 'react';
import '../Styles/Admin.css';
import UnhandledSignups from '../Components/UnhandledSignups.js';
import InviteUser from '../Components/InviteUser.js';
import { useFarmData } from '../Components/FarmData-Context';
import { useUsers } from '../Components/Users-Context';
import Loader from '../Components/Loader.js';
import UserMaintenance from '../Components/UserMaintenance';
import GLGMSiteSearchModal from '../Components/GLGMSiteSearchModal';
import IFSCustomerSearchModal from '../Components/IFSCustomerSearchModal';
import IFSUserSearchModal from '../Components/IFSUserSearchModal';
import SupportedHomePageLinks from '../Components/SupportedHomePageLinks';
import AddHomePageLinkModal from '../Components/AddHomePageLinkModal';

function Admin() {
  const {
    UpdateIFSNumber, UpdateGLGMNumber, UpdatePersonCode, GetAuth0Roles,
  } = useUsers();
  const { isLoading } = useFarmData();

  useEffect(() => {
    GetAuth0Roles();
  }, []);

  return (
    <>
      <div className="container-fluid admin-container">
        <div className="row justify-content-md-center admin-container-content-centre">
          {isLoading ? <Loader /> : ''}
          <div className="col-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link tab-link active"
                  id="signups-tab"
                  data-toggle="tab"
                  href="#signups"
                  role="tab"
                  aria-controls="signups"
                  aria-selected="true"
                >
                  Unhandled Signups
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link tab-link"
                  id="customer-tab"
                  data-toggle="tab"
                  href="#customer"
                  role="tab"
                  aria-controls="customer"
                  aria-selected="false"
                >
                  User Maintenance
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link tab-link"
                  id="invite-tab"
                  data-toggle="tab"
                  href="#invite"
                  role="tab"
                  aria-controls="invite"
                  aria-selected="false"
                  onClick={() => {
                    UpdateIFSNumber('inviteuser', '');
                    UpdateGLGMNumber('inviteuser', '');
                    UpdatePersonCode('inviteuser', '');
                  }}
                >
                  Invite User
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link tab-link"
                  id="supportedhomepagelinks-tab"
                  data-toggle="tab"
                  href="#supportedhomepagelinks"
                  role="tab"
                  aria-controls="invite"
                  aria-selected="false"
                >
                  Supported Home Page Links
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="signups"
                role="tabpanel"
                aria-labelledby="signups-tab"
              >
                <UnhandledSignups />
              </div>
              <div
                className="tab-pane fade"
                id="customer"
                role="tabpanel"
                aria-labelledby="customer-tab"
              >
                <UserMaintenance />
              </div>
              <div
                className="tab-pane fade"
                id="invite"
                role="tabpanel"
                aria-labelledby="invite-tab"
              >
                <InviteUser />
              </div>
              <div
                className="tab-pane fade"
                id="supportedhomepagelinks"
                role="tabpanel"
                aria-labelledby="invite-tab"
              >
                <SupportedHomePageLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GLGMSiteSearchModal />
      <IFSCustomerSearchModal />
      <IFSUserSearchModal />
      <AddHomePageLinkModal />
    </>
  );
}

export default Admin;
