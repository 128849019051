import React, { useState, useEffect } from 'react';
import '../Styles/SupportedHomePageLinks.css';
import { useFarmData } from './FarmData-Context';
import DeleteHomePageLinkModal from './DeleteHomePageLinkModal';

function SupportedHomePageLinks() {
  const [selectedLink, setSelectedLink] = useState('');
  const { GetHomePageLink, SupportedHomePageLinks } = useFarmData();
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (initial) {
      GetHomePageLink();
      setInitial(false);
    }
  });

  const homepageLinkList = SupportedHomePageLinks.length > 0
    && SupportedHomePageLinks.map((item, i) => {
      return (
        <tr
          onClick={(e) => {
            setSelectedLink(e.target.id);
          }}
          key={i}
          id={item.fileDownloadName}
          data-toggle="modal"
          data-target="#delete-homepage-link"
        >
          <td id={item.fileDownloadName}>{item.fileDownloadName}</td>
          <td id={item.fileDownloadName}>
            <img
              id={item.fileDownloadName}
              className="homepage-link-image"
              src={`data:image/jpeg;base64,${item.fileContents}`}
            />
          </td>
        </tr>
      );
    }, this);

  return (
    <>
      <div>
        <img id="photo" />
        <div className="container">
          <h4 className="text-center">
            <i>Supported Home Page Links</i>
          </h4>
          <form>
            <div className="form-group">
              <div className="input-group mb-3">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#upload-home-page-link"
                  className="upload-home-page-link-button"
                >
                  Upload Home Page Link
                </button>
              </div>
            </div>
          </form>
          <div className="homepage-links-container">
            <table className="table table-striped homepage-link-table">
              <thead>
                <tr>
                  <th>Link</th>
                  <th>Icon</th>
                </tr>
              </thead>
              <tbody>{homepageLinkList}</tbody>
            </table>
          </div>
        </div>
      </div>
      <DeleteHomePageLinkModal selectedlink={selectedLink} />
    </>
  );
}

export default SupportedHomePageLinks;
