import React, { Component, createContext, useContext } from 'react';

export const UserContext = createContext();
export const useUsers = () => useContext(UserContext);

class UsersProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Auth0Users: [],
      SelectedGLGMFarm: '',
      SelectedIFSCustomer: '',
      SelectedPersonCode: '',
      UsersIsLoading: false,
      UserMessage: '',
      Users: [],
      Roles: [],
    };

    this.GetAuth0Roles = this.GetAuth0Roles.bind(this);
    this.GetUsers = this.GetUsers.bind(this);
    this.GetAuth0Users = this.GetAuth0Users.bind(this);
    this.PostAuth0Users = this.PostAuth0Users.bind(this);
    this.PutUsers = this.PutUsers.bind(this);
    this.PutAuth0Users = this.PutAuth0Users.bind(this);
    this.DeleteAuth0Users = this.DeleteAuth0Users.bind(this);
    this.UpdateGLGMNumber = this.UpdateGLGMNumber.bind(this);
    this.UpdateIFSNumber = this.UpdateIFSNumber.bind(this);
    this.UpdatePersonCode = this.UpdatePersonCode.bind(this);
    this.SetUserMessageState = this.SetUserMessageState.bind(this);
  }

  async GetAuth0Roles() {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      const url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/auth0roles`;
      currentComponent.setState({
        isLoading: true,
      });

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                UsersIsLoading: false,
              });
            } else {
              response.json().then((roledata) => {
                const roleData = JSON.parse(roledata);
                const rolesFromApi = roleData.map((role) => {
                  return {
                    id: role.id,
                    name: role.name,
                  };
                });

                currentComponent.setState({
                  Roles: rolesFromApi,
                  UsersIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              UserMessage: 'you do not have permission to access auth0 roles.',
              UsersIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                UserMessage: error.message,
                UsersIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            UserMessage: error,
            UsersIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetUsers(accountname, accountemail, pagenumber) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        UsersIsLoading: true,
      });
      let firstParam = true;

      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/users`;

      if (accountname !== null) {
        if (firstParam) {
          url += `?name=${accountname}`;
          firstParam = false;
        } else {
          url += `&name=${accountname}`;
        }
      }

      if (accountemail !== null) {
        if (firstParam) {
          url += `?email=${accountemail}`;
          firstParam = false;
        } else {
          url += `&email=${accountemail}`;
        }
      }

      if (firstParam) {
        url += `?page=${pagenumber}`;
        firstParam = false;
      } else {
        url += `&page=${pagenumber}`;
      }

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                UsersIsLoading: false,
              });
            } else {
              response.json().then((userdata) => {
                currentComponent.setState({
                  Users: userdata,
                  UsersIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              UserMessage: 'you do not have permission to access users.',
              UsersIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                UserMessage: error.message,
                UsersIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            UserMessage: error,
            UsersIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetAuth0Users(
    accountlinked,
    signupcomplete,
    emailverified,
    accountname,
    accountemail,
    pagenumber,
    logincount,
  ) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      let firstParam = true;
      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/auth0users`;
      currentComponent.setState({
        isLoading: true,
      });

      if (accountlinked !== null) {
        if (firstParam) {
          url += `${'?' + 'accountlinked='}${accountlinked}`;
          firstParam = false;
        } else {
          url += `${'&' + 'accountlinked='}${accountlinked}`;
        }
      }

      if (signupcomplete !== null) {
        if (firstParam) {
          url += `${'?' + 'signupcomplete='}${signupcomplete}`;
          firstParam = false;
        } else {
          url += `${'&' + 'signupcomplete='}${signupcomplete}`;
        }
      }

      if (emailverified !== null) {
        if (firstParam) {
          url += `${'?' + 'emailverified='}${emailverified}`;
          firstParam = false;
        } else {
          url += `${'&' + 'emailverified='}${emailverified}`;
        }
      }

      if (logincount !== null) {
        if (firstParam) {
          url += `${'?' + 'logincount='}${logincount}`;
          firstParam = false;
        } else {
          url += `${'&' + 'logincount='}${logincount}`;
        }
      }

      if (accountname !== null) {
        url += `&name=${accountname}`;
      }

      if (accountemail !== null) {
        url += `&email=${accountemail}`;
      }

      url += `&page=${pagenumber}`;

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                UsersIsLoading: false,
              });
            } else {
              response.json().then((userdata) => {
                currentComponent.setState({
                  Auth0Users: userdata,
                  UsersIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              UserMessage: 'you do not have permission to access auth0 users.',
              UsersIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                UserMessage: error.message,
                UsersIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            UserMessage: error,
            UsersIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PostAuth0Users(email, ifsNumber, glgmNumber, personCode) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });
      const url = `${process.env.REACT_APP_AZURE_API_DOMAIN
      }api/v1/auth0users/${
        process.env.REACT_APP_AUTH0_CLIENT_ID}`;

      const newUser = {
        email,
        ifsnumber: ifsNumber,
        glgmnumber: glgmNumber,
        personcode: personCode,
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(newUser),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                UsersIsLoading: false,
              });
            } else {
              response.json().then((userdata) => {
                if (userdata.length > 0) {
                  const userResponse = JSON.parse(userdata);

                  const userObject = {
                    email,
                    accountlinked:
                      userResponse.app_metadata.accountlinked.toString(),
                    aukcustomer: userResponse.app_metadata.aukcustomer,
                    glgmnumber: userResponse.app_metadata.glgmnumber,
                    ifsnumber: userResponse.app_metadata.ifsnumber,
                    personcode: userResponse.app_metadata.personcode,
                    role: userResponse.app_metadata.role,
                    roleid: userResponse.app_metadata.roleid,
                    signupcomplete:
                      userResponse.app_metadata.signupcomplete.toString(),
                    name: userResponse.name,
                    user_id: userResponse.user_id,
                  };

                  fetch(
                    `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/users`,
                    {
                      method: 'POST',
                      body: JSON.stringify(userObject),
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                      },
                    },
                  )
                    .then((response) => {
                      if (response.ok) {
                        currentComponent.setState({
                          UserMessage: 'Successfully created the new user.',
                        });

                        document
                          .getElementById('message-modal-trigger')
                          .click();
                      } else if (
                        response.status === 401
                        || response.status === 403
                      ) {
                        currentComponent.setState({
                          UserMessage:
                            'you do not have permission to access users.',
                          UsersIsLoading: false,
                        });

                        document
                          .getElementById('error-message-modal-trigger')
                          .click();
                      } else {
                        response.json().then((error) => {
                          currentComponent.setState({
                            UserMessage: error.message,
                            UsersIsLoading: false,
                          });

                          document
                            .getElementById('error-message-modal-trigger')
                            .click();
                        });
                      }
                    })
                    .catch((error) => {
                      currentComponent.setState({
                        UserMessage: error,
                        UsersIsLoading: false,
                      });

                      document
                        .getElementById('error-message-modal-trigger')
                        .click();
                    });
                }

                currentComponent.setState({
                  UsersIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              UserMessage: 'you do not have permission to access auth0 users',
              UsersIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                UserMessage: error.message,
                UsersIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            UserMessage: error,
            UsersIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PutUsers(
    userid,
    ifsnumber,
    glgmnumber,
    personcode,
    role,
    rolename,
    gci_access,
  ) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });

      let selectedUser;
      const url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/auth0users/${userid}`;

      for (let i = 0; i < currentComponent.state.Users.length; i++) {
        if (currentComponent.state.Users[i].user_id === userid) {
          selectedUser = currentComponent.state.Users[i];

          if (rolename === 'Agronomist') {
            selectedUser.glgmnumber = '';
            selectedUser.ifsnumber = '';
            selectedUser.personcode = personcode;
            selectedUser.roleid = role;
            selectedUser.role = rolename;
            selectedUser.gci_access = gci_access.toString();
          } else if (rolename === 'Customer') {
            selectedUser.glgmnumber = glgmnumber;
            selectedUser.ifsnumber = ifsnumber;
            selectedUser.personcode = '';
            selectedUser.roleid = role;
            selectedUser.role = rolename;
            selectedUser.gci_access = gci_access.toString();
          } else {
            selectedUser.glgmnumber = '';
            selectedUser.ifsnumber = '';
            selectedUser.personcode = '';
            selectedUser.roleid = role;
            selectedUser.role = rolename;
            selectedUser.gci_access = gci_access.toString();
          }
        }
      }

      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(selectedUser),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                UsersIsLoading: false,
              });
            } else {
              response.json().then((userdata) => {
                const updatedUsers = [];
                let j = 0;
                for (let i = 0; i < currentComponent.state.Users.length; i++) {
                  if (currentComponent.state.Users[i].user_id !== userid) {
                    updatedUsers[j] = currentComponent.state.Users[i];
                    j++;
                  } else {
                    selectedUser.accountlinked = 'true';

                    fetch(
                      `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/users`,
                      {
                        method: 'PUT',
                        body: JSON.stringify(selectedUser),
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    )
                      .then((response) => {
                        if (response.ok) {
                          currentComponent.setState({
                            UserMessage: 'Successfully updated the users.',
                          });

                          document
                            .getElementById('message-modal-trigger')
                            .click();
                        } else if (
                          response.status === 401
                          || response.status === 403
                        ) {
                          currentComponent.setState({
                            UserMessage:
                              'you do not have permission to access users.',
                            UsersIsLoading: false,
                          });

                          document
                            .getElementById('error-message-modal-trigger')
                            .click();
                        } else {
                          response.json().then((error) => {
                            currentComponent.setState({
                              UserMessage: error.message,
                              UsersIsLoading: false,
                            });

                            document
                              .getElementById('error-message-modal-trigger')
                              .click();
                          });
                        }
                      })
                      .catch((error) => {
                        currentComponent.setState({
                          UserMessage: error,
                          UsersIsLoading: false,
                        });

                        document
                          .getElementById('error-message-modal-trigger')
                          .click();
                      });
                  }
                }

                currentComponent.setState({
                  UsersIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              UserMessage: 'you do not have permission to access auth0 users.',
              UsersIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                UserMessage: error.message,
                UsersIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            UserMessage: error,
            UsersIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async PutAuth0Users(
    userid,
    ifsnumber,
    glgmnumber,
    personcode,
    role,
    rolename,
  ) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        isLoading: true,
      });
      let selectedUser;
      const url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/auth0users/${userid}`;

      for (let i = 0; i < currentComponent.state.Auth0Users.length; i++) {
        if (currentComponent.state.Auth0Users[i].user_id === userid) {
          selectedUser = currentComponent.state.Auth0Users[i];
          selectedUser.glgmnumber = glgmnumber;
          selectedUser.ifsnumber = ifsnumber;
          selectedUser.personcode = personcode;
          selectedUser.roleid = role;
          selectedUser.role = rolename;
        }
      }

      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(selectedUser),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                UsersIsLoading: false,
              });
            } else {
              response.json().then((userdata) => {
                const updatedUsers = [];
                let j = 0;
                for (
                  let i = 0;
                  i < currentComponent.state.Auth0Users.length;
                  i++
                ) {
                  if (currentComponent.state.Auth0Users[i].user_id !== userid) {
                    updatedUsers[j] = currentComponent.state.Auth0Users[i];
                    j++;
                  } else {
                    selectedUser.accountlinked = 'true';

                    fetch(
                      `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/users`,
                      {
                        method: 'POST',
                        body: JSON.stringify(selectedUser),
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    )
                      .then((response) => {
                        if (response.ok) {
                          currentComponent.setState({
                            UserMessage: 'Successfully updated the user.',
                          });

                          document
                            .getElementById('message-modal-trigger')
                            .click();
                        } else if (
                          response.status === 401
                          || response.status === 403
                        ) {
                          currentComponent.setState({
                            UserMessage:
                              'you do not have permission to access auth0 users.',
                            UsersIsLoading: false,
                          });

                          document
                            .getElementById('error-message-modal-trigger')
                            .click();
                        } else {
                          response.json().then((error) => {
                            currentComponent.setState({
                              UserMessage: error.message,
                              UsersIsLoading: false,
                            });

                            document
                              .getElementById('error-message-modal-trigger')
                              .click();
                          });
                        }
                      })
                      .catch((error) => {
                        currentComponent.setState({
                          UserMessage: error,
                          UsersIsLoading: false,
                        });

                        document
                          .getElementById('error-message-modal-trigger')
                          .click();
                      });
                  }
                }
                currentComponent.setState({
                  Auth0Users: updatedUsers,
                  UsersIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              UserMessage: 'you do not have permission to access auth0 users.',
              UsersIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                UserMessage: error.message,
                UsersIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            UserMessage: error,
            UsersIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async DeleteAuth0Users(userid) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        UsersIsLoading: true,
      });

      const url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/auth0users/${userid}`;

      fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              // do nothing
            } else {
              response.json().then((userid) => {
                const updatedUsers = [];
                const updatedAuth0Users = [];
                let j = 0;
                let k = 0;
                for (let i = 0; i < currentComponent.state.Users.length; i++) {
                  if (currentComponent.state.Users[i].user_id !== userid) {
                    updatedUsers[j] = currentComponent.state.Users[i];
                    j++;
                  } else {
                    fetch(
                      `${process.env.REACT_APP_AZURE_API_DOMAIN
                      }api/v1/users/?id=${
                        userid}`,
                      {
                        method: 'DELETE',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    )
                      .then((response) => {
                        if (response.ok) {
                          currentComponent.setState({
                            UserMessage: 'Succefully deleted the user.',
                            UsersIsLoading: false,
                          });

                          document
                            .getElementById('message-modal-trigger')
                            .click();
                        } else if (
                          response.status === 401
                          || response.status === 403
                        ) {
                          currentComponent.setState({
                            UserMessage:
                              'you do not have permission to access auth0 users.',
                            UsersIsLoading: false,
                          });

                          document
                            .getElementById('error-message-modal-trigger')
                            .click();
                        } else {
                          response.json().then((error) => {
                            currentComponent.setState({
                              UserMessage: error.message,
                              UsersIsLoading: false,
                            });

                            document
                              .getElementById('error-message-modal-trigger')
                              .click();
                          });
                        }
                      })
                      .catch((error) => {
                        currentComponent.setState({
                          UserMessage: error,
                          UsersIsLoading: false,
                        });

                        document
                          .getElementById('error-message-modal-trigger')
                          .click();
                      });
                  }
                }

                for (
                  let i = 0;
                  i < currentComponent.state.Auth0Users.length;
                  i++
                ) {
                  if (currentComponent.state.Auth0Users[i].user_id !== userid) {
                    updatedAuth0Users[k] = currentComponent.state.Auth0Users[i];
                    k++;
                  } else {
                    fetch(
                      `${process.env.REACT_APP_AZURE_API_DOMAIN
                      }api/v1/users/?id=${
                        userid}`,
                      {
                        method: 'DELETE',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    )
                      .then((response) => {
                        if (response.ok) {
                          currentComponent.setState({
                            UserMessage: 'Successfully deleted the user.',
                          });
                          document
                            .getElementById('message-modal-trigger')
                            .click();
                        } else if (
                          response.status === 401
                          || response.status === 403
                        ) {
                          currentComponent.setState({
                            UserMessage:
                              'you do not have permission to access users.',
                            UsersIsLoading: false,
                          });

                          document
                            .getElementById('error-message-modal-trigger')
                            .click();
                        } else {
                          response.json().then((error) => {
                            currentComponent.setState({
                              UserMessage: error.message,
                              UsersIsLoading: false,
                            });

                            document
                              .getElementById('error-message-modal-trigger')
                              .click();
                          });
                        }
                      })
                      .catch((error) => {
                        currentComponent.setState({
                          UserMessage: error,
                          UsersIsLoading: false,
                        });

                        document
                          .getElementById('error-message-modal-trigger')
                          .click();
                      });
                  }
                }

                currentComponent.setState({
                  Auth0Users: updatedAuth0Users,
                  Users: updatedUsers,
                  UsersIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              UserMessage: 'you do not have permission to access auth0 users.',
              UsersIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                UserMessage: error.message,
                UsersIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            UserMessage: error,
            UsersIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async UpdateGLGMNumber(userid, glgmnumber) {
    for (let i = 0; i < this.state.Users.length; i++) {
      if (this.state.Users[i].user_id === userid) {
        this.state.Users[i].glgmnumber = glgmnumber;
      }
    }

    this.setState({ Users: this.state.Users, SelectedGLGMFarm: glgmnumber });
  }

  async UpdateIFSNumber(userid, ifsnumber) {
    for (let i = 0; i < this.state.Users.length; i++) {
      if (this.state.Users[i].user_id === userid) {
        this.state.Users[i].ifsnumber = ifsnumber;
      }
    }

    this.setState({ Users: this.state.Users, SelectedIFSCustomer: ifsnumber });
  }

  async UpdatePersonCode(userid, personcode) {
    for (let i = 0; i < this.state.Users.length; i++) {
      if (this.state.Users[i].user_id === userid) {
        this.state.Users[i].personcode = personcode;
      }
    }

    this.setState({ Users: this.state.Users, SelectedPersonCode: personcode });
  }

  async SetUserMessageState(message) {
    this.setState({ UserMessage: message });
  }

  render() {
    const { children } = this.props;
    const {
      Auth0Users,
      Roles,
      Users,
      SelectedGLGMFarm,
      SelectedIFSCustomer,
      SelectedPersonCode,
      UserMessage,
      UsersIsLoading,
    } = this.state;
    const configObject = {
      Users,
      SelectedGLGMFarm,
      SelectedIFSCustomer,
      SelectedPersonCode,
      Auth0Users,
      Roles,
      UserMessage,
      UsersIsLoading,
      SetUserMessageState: (...p) => this.SetUserMessageState(...p),
      UpdateIFSNumber: (...p) => this.UpdateIFSNumber(...p),
      UpdateGLGMNumber: (...p) => this.UpdateGLGMNumber(...p),
      UpdatePersonCode: (...p) => this.UpdatePersonCode(...p),
      GetUsers: (...p) => this.GetUsers(...p),
      PutUsers: (...p) => this.PutUsers(...p),
      GetAuth0Roles: (...p) => this.GetAuth0Roles(...p),
      GetAuth0Users: (...p) => this.GetAuth0Users(...p),
      PutAuth0Users: (...p) => this.PutAuth0Users(...p),
      PostAuth0Users: (...p) => this.PostAuth0Users(...p),
      DeleteAuth0Users: (...p) => this.DeleteAuth0Users(...p),
    };
    return (
      <UserContext.Provider value={configObject}>
        {children}
      </UserContext.Provider>
    );
  }
}

export default UsersProvider;
