import React, { useState } from 'react';
import { useIFS } from './IFSData-Context';
import { useFarmData } from './FarmData-Context';
import { useUsers } from './Users-Context.js';
import Loader from './Loader';
import '../Styles/IFSCustomerSearchModal.css';

function IFSCustomerSearchModal() {
  const [customerNumber, setCustomerNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const { IFSCustomers, GetIFSCustomers, IFSIsLoading } = useIFS();
  const { CurrentUser } = useFarmData();
  const { UpdateIFSNumber } = useUsers();

  const IFSCustomerList = IFSCustomers.length > 0
    && IFSCustomers.map((item, i) => {
      return (
        <tr
          key={i}
          id={item.customeR_ID}
          onClick={(e) => {
            UpdateIFSNumber(CurrentUser, e.target.id);
          }}
          data-dismiss="modal"
        >
          <td id={item.customeR_ID}>{item.customeR_ID}</td>
          <td id={item.customeR_ID}>{item.name}</td>
        </tr>
      );
    }, this);

  return (
    <div
      className="modal fade"
      id="search-ifs-customers"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {IFSIsLoading ? <Loader /> : ''}
          <form id="ifs-customers-form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                IFS Customer Search
              </h5>
              <button
                id="add-field-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="form-row ifs-customer-search-inputs">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer Number"
                  aria-label="Customer Number"
                  aria-describedby="button-addon"
                  value={customerNumber}
                  onInput={(e) => {
                    setCustomerNumber(e.target.value);
                    GetIFSCustomers(e.target.value, customerName, pageNumber);
                  }}
                  onChange={() => {}}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Name"
                  aria-label="Account Name"
                  aria-describedby="button-addon"
                  value={customerName}
                  onInput={(e) => {
                    setCustomerName(e.target.value);
                    GetIFSCustomers(customerNumber, e.target.value, pageNumber);
                  }}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="modal-body ifs-customers-modal-body">
              <div className="form-row justify-content-center">
                <div className="glgm-search-data-table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Customer Number</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>{IFSCustomerList}</tbody>
                  </table>
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={
                        pageNumber == 0 ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        tabIndex="-1"
                        onClick={() => {
                          setPageNumber(pageNumber - 1);
                          GetIFSCustomers(
                            customerNumber,
                            customerName,
                            pageNumber - 1,
                          );
                        }}
                      >
                        Previous
                      </a>
                    </li>
                    <li
                      className={
                        pageNumber == 0 ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPageNumber(pageNumber - 1);
                          GetIFSCustomers(
                            customerNumber,
                            customerName,
                            pageNumber - 1,
                          );
                        }}
                      >
                        {pageNumber}
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        {pageNumber + 1}
                      </a>
                    </li>
                    <li
                      className={
                        !IFSCustomerList ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPageNumber(pageNumber + 1);
                          GetIFSCustomers(
                            customerNumber,
                            customerName,
                            pageNumber + 1,
                          );
                        }}
                      >
                        {pageNumber + 2}
                      </a>
                    </li>
                    <li
                      className={
                        !IFSCustomerList ? 'page-item disabled' : 'page-item'
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPageNumber(pageNumber + 1);
                          GetIFSCustomers(
                            customerNumber,
                            customerName,
                            pageNumber + 1,
                          );
                        }}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default IFSCustomerSearchModal;
