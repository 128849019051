import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../Auth/Auth0-Context';
import '../Styles/SignUp.css';

function SignUp() {
  const { continueAuth0 } = useAuth0();
  const [farmName, setFarmName] = useState('');
  const [tradingName, setTradingName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [townCity, setTownCity] = useState('');
  const [county, setCounty] = useState('');
  const [country, setCountry] = useState('');
  const [postCode, setPostCode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [aukCustomer, setAukCustomer] = useState(false);
  const [consent, setConsent] = useState(false);
  const [addressDisplay, setAddressDisplay] = useState('none');
  const [accountNumberDisplay, setAccountNumberDisplay] = useState('none');
  const [invalidPostcode, setInvalidPostcode] = useState('');

  let geocoder;

  useEffect(() => {
    /* Load the google map into the app, depending on whether this is the first visit during the session
    the initial script may need to be appended to the HTML page.
    */
    if (document.getElementById('googlemapscript') === null) {
      const googleMapScript = document.createElement('script');
      googleMapScript.id = 'googlemapscript';
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_API_KEY}`;
      window.document.body.appendChild(googleMapScript);
    }
  });

  const handlePostcodeSearch = async () => {
    const patt = /^(((([A-Za-z][A-Za-z]{0,1})[0-9][A-Za-z0-9]{0,1}) {0,}[0-9])[A-Za-z]{2})$/;
    if (patt.test(postCode)) {
      geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: postCode }, (results, status) => {
        if (status == 'OK') {
          setAddress1('');
          setAddress2('');
          setTownCity('');
          setCounty('');
          setCountry('');

          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              switch (results[0].address_components[i].types[j]) {
                case 'route':
                  setAddress1(results[0].address_components[i].long_name);
                  break;
                case 'locality':
                  setAddress2(results[0].address_components[i].long_name);
                  break;
                case 'postal_town':
                  setTownCity(results[0].address_components[i].long_name);
                  break;
                case 'administrative_area_level_2':
                  setCounty(results[0].address_components[i].long_name);
                  break;
                case 'country':
                  setCountry(results[0].address_components[i].long_name);
                  break;
                case 'postal_code':
                  setPostCode(results[0].address_components[i].long_name);
                  break;
              }
            }
          }

          setLatitude(results[0].geometry.location.lat);
          setLongitude(results[0].geometry.location.lng);
          setAddressDisplay('block');
          setInvalidPostcode('');
        } else {
          alert(
            `Geocode was not successful for the following reason: ${status}`,
          );
        }
      });
    } else {
      setInvalidPostcode('The postcode must follow a UK format');
    }
  };

  const handleSubmit = async () => {
    continueAuth0(
      aukCustomer,
      accountNumber,
      farmName,
      tradingName,
      phoneNumber,
      mobileNumber,
      houseNumber,
      address1,
      address2,
      townCity,
      postCode,
      latitude,
      longitude,
      county,
      country,
      consent,
    );
  };

  return (
    <>
      <div className="container-fluid">
        <h4 className="text-center">
          <i>Sign Up Form</i>
        </h4>
        <p className="text-center">Theres just a few more details we need.</p>
        <div className="row justify-content-md-center">
          <form
            id="signup-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div id="signup-form-fields">
              <div className="form-row auk-control-input">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch1"
                    value={aukCustomer}
                    onChange={(e) => {
                      setAukCustomer(e.target.checked);
                      if (e.target.checked) {
                        setAccountNumberDisplay('block');
                      } else {
                        setAccountNumberDisplay('none');
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitch1"
                  >
                    Existing Agrovista Customer?
                  </label>
                </div>
              </div>
              <div
                className="form-row"
                style={{ display: accountNumberDisplay }}
              >
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={accountNumber}
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Farm Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={farmName}
                    onChange={(e) => {
                      setFarmName(e.target.value);
                    }}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Trading Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={tradingName}
                    onChange={(e) => {
                      setTradingName(e.target.value);
                    }}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationTooltip01">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={phoneNumber}
                    pattern="[0-9]{11}"
                    title="The phone number must contain 11 numbers"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationTooltip01">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={mobileNumber}
                    pattern="[0-9]{11}"
                    title="The mobile number must contain 11 numbers"
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">House Number</label>
                  <input
                    value={houseNumber}
                    onChange={(e) => {
                      setHouseNumber(e.target.value);
                    }}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Address Line 1</label>
                  <input value={address1} className="form-control" readOnly />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Address Line 2</label>
                  <input value={address2} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Town/City</label>
                  <input value={townCity} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">County</label>
                  <input value={county} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-row" style={{ display: addressDisplay }}>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Country</label>
                  <input value={country} className="form-control" readOnly />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Postcode</label>
                  <div className="form-validation">
                    <i>{invalidPostcode}</i>
                  </div>
                  <div className="input-group">
                    <input
                      id="validationTooltip01"
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      aria-label="IFS Customer No"
                      aria-describedby="button-addon"
                      pattern="^(((([A-Za-z][A-Za-z]{0,1})[0-9][A-Za-z0-9]{0,1}) {0,}[0-9])[A-Za-z]{2})$"
                      title="The postcode must follow a UK format"
                      value={postCode}
                      onChange={(e) => {
                        setPostCode(e.target.value);
                      }}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon"
                        onClick={() => {
                          handlePostcodeSearch();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  value={consent}
                  onChange={(e) => {
                    setConsent(e.target.checked);
                  }}
                  required
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  I consent to being contacted by Agrovista
                </label>
              </div>
            </div>

            <button
              id="signup-form-button"
              className="btn btn-primary"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignUp;
