import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../Styles/DeliveryNotificationsPanel.css';
import { useFarmData } from './FarmData-Context';
import { useDeliveryNotificationsContext } from '../Pages/DeliveryNotifications';
import Loader from './Loader';

let deliveryMarkersArray = [];
let myInterval;

function DeliveryNotificationsPanel() {
  const { deliveryMapGlobal } = useDeliveryNotificationsContext();
  const {
    DeliveryNotifications,
    GetDeliveryNotifications,
    GetImagePODs,
    ImagePODs,
    FarmMessage,
  } = useFarmData();
  const [initialUpdate, setInitialUpdate] = useState(true);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [selectedDrop, setSelectedDrop] = useState('all drops');
  const [dropChanged, setDropChanged] = useState(true);
  const [deliveredChecked, setDeliveredChecked] = useState(true);
  const [outForDeliveryChecked, setOutForDeliveryChecked] = useState(true);
  const [failedChecked, setFailedChecked] = useState(true);
  const [partiallyDeliveredChecked, setPartiallyDeliveredChecked] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (DeliveryNotifications.length === 0 && initialUpdate) {
      setInitialUpdate(false);
      GetDeliveryNotifications();
    }

    if (DeliveryNotifications.length > 0 && FarmMessage.length === 0) {
      let calendarDate = Date.parse(deliveryDate);
      let deliveryDates = Date.parse(DeliveryNotifications[0].delivery_Date);
      deliveryDates = new Date(deliveryDates);
      calendarDate = new Date(calendarDate);
      deliveryDates = deliveryDates.toDateString('ddMMyyyy');
      calendarDate = calendarDate.toDateString('ddMMyyyy');

      if (calendarDate !== deliveryDates) {
        let day = deliveryDate.getDate();
        let month = deliveryDate.getMonth() + 1;
        const year = deliveryDate.getFullYear();

        if (month < 10) {
          month = `0${month}`;
        }
        if (day < 10) {
          day = `0${day}`;
        }
        const selectedDate = `${year}-${month}-${day}`;

        GetDeliveryNotifications(selectedDate);
      }
    }

    if (window.google !== undefined && deliveryMapGlobal.length !== 0) {
      const bounds = new window.google.maps.LatLngBounds();

      for (let i = 0; i < deliveryMarkersArray.length; i++) {
        deliveryMarkersArray[i].setMap(null);
      }

      deliveryMarkersArray = [];

      for (let i = 0; i < DeliveryNotifications.length; i++) {
        if (
          DeliveryNotifications[i].epoD_Site_ID == selectedDrop
          || selectedDrop === 'all drops'
        ) {
          if (
            ((!outForDeliveryChecked
              && DeliveryNotifications[i].order_Status === 'N')
              || (!deliveredChecked
                && DeliveryNotifications[i].order_Status === 'C')
              || (!failedChecked
                && DeliveryNotifications[i].order_Status === 'R')
              || (!partiallyDeliveredChecked
                && DeliveryNotifications[i].order_Status === 'X'))
            && selectedDrop === 'all drops'
          ) {
            continue;
          } else {
            const myLatLng = {
              lat: parseFloat(DeliveryNotifications[i].latitude),
              lng: parseFloat(DeliveryNotifications[i].longitude),
            };

            const vehicleLatLng = {
              lat: parseFloat(DeliveryNotifications[i].vehicle_Latitude),
              lng: parseFloat(DeliveryNotifications[i].vehicle_Longitude),
            };

            const deliveredIcon = {
              url: 'Images/Delivered-Icon.png',
              scaledSize: new window.google.maps.Size(20, 20), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(10, 10), // anchor
            };

            const partiallyDeliveredIcon = {
              url: 'Images/Partial-Delivery.png',
              scaledSize: new window.google.maps.Size(20, 20), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(10, 10), // anchor
            };

            const failedIcon = {
              url: 'Images/Failed-Delivery.png',
              scaledSize: new window.google.maps.Size(20, 20), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(10, 10), // anchor
            };

            const inTransitIcon = {
              url: 'Images/Delivery-In-Transit-Icon.png',
              scaledSize: new window.google.maps.Size(20, 20), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(10, 10), // anchor
            };

            const vehicleIcon = {
              url: 'Images/Tracked-Delivery.png',
              scaledSize: new window.google.maps.Size(30, 30), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(15, 15), // anchor
            };

            let marker = '';

            switch (DeliveryNotifications[i].order_Status) {
              case 'N':
                marker = new window.google.maps.Marker({
                  position: myLatLng,
                  map: deliveryMapGlobal,
                  title: DeliveryNotifications[i].customer_Name,
                  icon: inTransitIcon,
                });

                break;

              case 'C':
                marker = new window.google.maps.Marker({
                  position: myLatLng,
                  map: deliveryMapGlobal,
                  title: DeliveryNotifications[i].customer_Name,
                  icon: deliveredIcon,
                });

                break;

              case 'R':
                marker = new window.google.maps.Marker({
                  position: myLatLng,
                  map: deliveryMapGlobal,
                  title: DeliveryNotifications[i].customer_Name,
                  icon: failedIcon,
                });

                break;

              case 'X':
                marker = new window.google.maps.Marker({
                  position: myLatLng,
                  map: deliveryMapGlobal,
                  title: DeliveryNotifications[i].customer_Name,
                  icon: partiallyDeliveredIcon,
                });

                break;
            }

            if (DeliveryNotifications[i].order_Status === 'N') {
              const vehicleMarker = new window.google.maps.Marker({
                position: vehicleLatLng,
                map: deliveryMapGlobal,
                title: DeliveryNotifications[i].vehicle_Desc,
                icon: vehicleIcon,
              });

              deliveryMarkersArray.push(vehicleMarker);
              bounds.extend(vehicleLatLng);
            }

            marker.set('id', DeliveryNotifications[i].epoD_Site_ID);
            marker.addListener('click', () => setSelectedDrop(marker.get('id')));
            deliveryMarkersArray.push(marker);
            bounds.extend(myLatLng);
          }
        }
      }

      if (dropChanged && DeliveryNotifications.length > 0) {
        deliveryMapGlobal.fitBounds(bounds);
        setDropChanged(false);
      }

      if (DeliveryNotifications.length > 0 || FarmMessage.length > 0) {
        if (DeliveryNotifications.length > 0) {
          let calendarDate = Date.parse(deliveryDate);
          let deliveryDates = Date.parse(
            DeliveryNotifications[0].delivery_Date,
          );
          deliveryDates = new Date(deliveryDates);
          calendarDate = new Date(calendarDate);
          deliveryDates = deliveryDates.toDateString('ddMMyyyy');
          calendarDate = calendarDate.toDateString('ddMMyyyy');

          if (calendarDate === deliveryDates) {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    }

    return function cleanup() {
      clearTimeout(myInterval);
    };
  }, [
    DeliveryNotifications,
    FarmMessage,
    selectedDrop,
    deliveryMapGlobal,
    deliveredChecked,
    outForDeliveryChecked,
    partiallyDeliveredChecked,
    failedChecked,
  ]);

  const todaysDate = new Date();
  clearTimeout(myInterval);

  if (deliveryDate.toDateString() === todaysDate.toDateString()) {
    myInterval = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        setDropChanged(false);
        setSeconds(60);

        let day = deliveryDate.getDate();
        let month = deliveryDate.getMonth() + 1;
        const year = deliveryDate.getFullYear();
        if (month < 10) {
          month = `0${month}`;
        }
        if (day < 10) {
          day = `0${day}`;
        }
        const today = `${year}-${month}-${day}`;

        GetDeliveryNotifications(today);
      }
    }, 1000);
  }

  DeliveryNotifications.sort((a, b) => {
    return a.customer_Name.localeCompare(b.customer_Name);
  });

  const deliveryNotificationList = DeliveryNotifications.length > 0
    && DeliveryNotifications.map((item, i) => {
      if (
        ((!outForDeliveryChecked && item.order_Status === 'N')
          || (!deliveredChecked && item.order_Status === 'C')
          || (!failedChecked && item.order_Status === 'R')
          || (!partiallyDeliveredChecked && item.order_Status === 'X'))
        && selectedDrop === 'all drops'
      ) {
        // don't add the values to the list
      } else {
        let orderStatus = '';
        switch (item.order_Status) {
          case 'C':
            orderStatus = 'Delivered';
            break;
          case 'N':
            orderStatus = 'Out for Delivery';
            break;
          case 'R':
            orderStatus = 'Failed';
            break;
          case 'X':
            orderStatus = 'Partially Delivered';
            break;
          default:
            orderStatus = 'Unknown';
            break;
        }

        return (
          <option key={i} value={item.epoD_Site_ID}>
            {item.eta !== '00:00'
            && orderStatus !== 'Delivered'
            && orderStatus !== 'Partially Delivered'
              ? `(${item.etA_Timeslot}) ${item.customer_Name}`
              : `(${orderStatus}) ${item.customer_Name}`}
          </option>
        );
      }
    }, this);

  const imagePODPanels = ImagePODs.length > 0
    && ImagePODs.map((item, i) => {
      return (
        <div className="imagepodsdiv" key={i} id={item.fileDownloadName}>
          <img src={`data:image/jpeg;base64,${item.fileContents}`} />
        </div>
      );
    }, this);

  const dropsList = DeliveryNotifications.length > 0
    && DeliveryNotifications.map((item, i) => {
      if (
        ((!outForDeliveryChecked && item.order_Status === 'N')
          || (!deliveredChecked && item.order_Status === 'C')
          || (!failedChecked && item.order_Status === 'R')
          || (!partiallyDeliveredChecked && item.order_Status === 'X'))
        && selectedDrop === 'all drops'
      ) {
        // don't add the values to the list
      } else if (item.epoD_Site_ID == selectedDrop || selectedDrop === 'all drops') {
        let etaEarlyLateMessage = '';
        let earlyLate = '';
        let cardColour = '';
        let orderStatus = '';

        switch (item.order_Status) {
          case 'C':
            orderStatus = 'Delivered';
            cardColour = 'delivered';
            break;
          case 'N':
            orderStatus = 'Out for Delivery';
            cardColour = 'intransit';
            break;
          case 'R':
            orderStatus = 'Failed';
            cardColour = 'failed';
            break;
          case 'X':
            orderStatus = 'Partially Delivered';
            cardColour = 'partiallydelivered';
            break;
          default:
            orderStatus = 'Unknown';
            cardColour = 'unknown';
            break;
        }

        if (item.etA_Timeslot !== null) {
          const etaTimes = item.etA_Timeslot.split('-');

          const earlyTimeDate = new Date(`01/01/2000 ${etaTimes[0].trim()}`);
          const lateTimeDate = new Date(`01/01/2000 ${etaTimes[1].trim()}`);
          const etaTimeDate = new Date(`01/01/2000 ${item.eta.trim()}`);

          if (
            orderStatus !== 'Delivered'
              && orderStatus !== 'Partially Delivered'
          ) {
            if (earlyTimeDate > etaTimeDate && item.eta !== '00:00') {
              earlyLate = 'early';
              etaEarlyLateMessage = 'Currently your delivery is estimated to arrive earlier that the allocated '
                  + 'timeslot, if this causes any issues please contact your local Agrovista representative.';
            } else if (lateTimeDate < etaTimeDate) {
              earlyLate = 'late';
              etaEarlyLateMessage = 'Currently your delivery is estimated to arrive later that the allocated '
                  + 'timeslot, if this causes any issues please contact your local Agrovista representative.';
            }
          }
        }

        const productRows = item.epoD_Order_Lines.length > 0
            && item.epoD_Order_Lines.map((line, j) => {
              let lineStatus = '';
              let lineColour = '';
              switch (line.line_Status) {
                case 'C':
                  lineStatus = 'Delivered';
                  lineColour = 'delivered';
                  break;
                case 'N':
                  lineStatus = 'Out for Delivery';
                  lineColour = 'intransit';
                  break;
                case 'R':
                  lineStatus = 'Failed';
                  lineColour = 'failed';
                  break;
                case 'X':
                  lineStatus = 'Partially Delivered';
                  lineColour = 'partiallydelivered';
                  break;
                default:
                  lineStatus = 'Unknown';
                  lineColour = 'unknown';
                  break;
              }

              return (
                <tr key={j}>
                  <td>{line.part_No}</td>
                  <td>{line.part_Desc}</td>
                  <td>{line.planned_Quantity}</td>
                  <td>{line.actual_Quantity}</td>
                  <td>{lineStatus}</td>
                </tr>
              );
            }, this);

        return (
          <div className="drop-container" key={i}>
            <article className="card">
              <div className="card-body">
                {etaEarlyLateMessage.length > 0
                  && orderStatus === 'Out for Delivery' ? (
                    <p className={`earlylatemessage ${earlyLate}`}>
                      <i>{etaEarlyLateMessage}</i>
                    </p>
                  ) : (
                    ''
                  )}
                <div className="row">
                  <div className="card-header-info-left">
                    <b>Order No: </b>
                    {item.order_No}
                  </div>
                  <div className="card-header-info-right">
                    <b>Address: </b>
                    {item.address_Line_1}, {item.postcode}
                  </div>
                </div>
                <div className="row">
                  <div className="card-header-info-left">
                    <b>Agronomist: </b>
                    {item.agronomist}
                  </div>
                  <div className="card-header-info-right">
                    <b>Depot: </b>
                    {item.site_Desc}
                  </div>
                </div>
                <div className="row">
                  <div className="card-header-info-left">
                    <b>Driver: </b>
                    {item.driver_Desc}
                  </div>
                  <div className="card-header-info-right">
                    <b>Vehicle: </b>
                    {item.vehicle_Desc}
                  </div>
                </div>
                <article className="card main-card-panel">
                  <div className={`card-body row ${cardColour}`}>
                    {orderStatus === 'Delivered'
                      || orderStatus === 'Partially Delivered' ? (
                        <div className="col">
                          <strong>Actual Delivery Time </strong> <br />
                          {item.ata}
                        </div>
                      ) : (
                        <div className="col">
                          <strong>Timeslot </strong> <br />
                          {item.etA_Timeslot}
                        </div>
                      )}
                    <div className="col">
                      <strong>Customer </strong> <br />
                      {item.customer_Name}
                    </div>
                    <div className="col">
                      <strong>Status </strong> <br />
                      {orderStatus}
                    </div>
                  </div>
                </article>
              </div>
            </article>
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#collapseOne${i}`}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Products
                    </button>
                  </h2>
                </div>
                <div
                  id={`collapseOne${i}`}
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#deliverynoitifactions-accordion"
                >
                  <div className="card-body product-table-display">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Part No</th>
                          <th>Description</th>
                          <th>Qty to Deliver</th>
                          <th>Qty Delivered</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>{productRows}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              {orderStatus === 'Delivered'
                || orderStatus === 'Partially Delivered' ? (
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          id={item.epoD_Site_ID}
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={`#collapseTwo${i}`}
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={(e) => {
                            GetImagePODs(e.target.id);
                          }}
                        >
                          Proof of Delivery
                        </button>
                      </h2>
                    </div>
                    <div
                      id={`collapseTwo${i}`}
                      className="collapse image-pod-collection"
                      aria-labelledby="headingTwo"
                      data-parent="#deliverynoitifactions-accordion"
                    >
                      <div className="card-body">
                        <div className="images-pod-container">
                          {imagePODPanels}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
            </div>
          </div>
        );
      }
    }, this);

  const HandleDateChange = async () => {
    setDropChanged(true);
    setPartiallyDeliveredChecked(true);
    setDeliveredChecked(true);
    setOutForDeliveryChecked(true);
    setFailedChecked(true);

    setSelectedDrop('all drops');
  };

  const HandleDeliveryNotificationChange = async (e) => {
    setDropChanged(true);
    setPartiallyDeliveredChecked(true);
    setDeliveredChecked(true);
    setOutForDeliveryChecked(true);
    setFailedChecked(true);
    if (e.target.value === 'all drops') {
      setSelectedDrop(e.target.value);
    } else {
      for (let i = 0; i < DeliveryNotifications.length; i++) {
        if (DeliveryNotifications[i].epoD_Site_ID == e.target.value) {
          setSelectedDrop(DeliveryNotifications[i].epoD_Site_ID);
        }
      }
    }
  };

  return (
    <div
      id="deliverynoitifactions-accordion"
      className="deliverynotificationspanel"
    >
      {isLoading ? <Loader /> : ''}
      <form className="deliverynotification-form">
        {deliveryDate.toDateString() === todaysDate.toDateString() ? (
          <p>
            <b>Next Update (Seconds): </b>
            {seconds}
          </p>
        ) : (
          ''
        )}
        <div className="form-group deliverynotification-filters">
          <label>
            <b className="delivery-select-title">
              <i>Delivery Date</i>
            </b>
          </label>
          <div className="input-group date delivery-date-picker">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={deliveryDate}
              onFocus={(e) => {
                e.target.blur();
              }}
              onChange={(date) => {
                setSeconds(60);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                const year = date.getFullYear();
                if (month < 10) {
                  month = `0${month}`;
                }
                if (day < 10) {
                  day = `0${day}`;
                }
                const today = `${year}-${month}-${day}`;

                GetDeliveryNotifications(today);
                setDeliveryDate(date);
                HandleDateChange();
                setIsLoading(true);
              }}
            />
          </div>
          {selectedDrop === 'all drops' ? (
            <div className="delivery-filters">
              <label>
                <b className="delivery-select-title">
                  <i>Delivery Filters</i>
                </b>
              </label>{' '}
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={deliveredChecked}
                  onChange={(e) => {
                    setDeliveredChecked(e.target.checked);
                  }}
                />
                Delivered
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={outForDeliveryChecked}
                  onChange={(e) => {
                    setOutForDeliveryChecked(e.target.checked);
                  }}
                />
                Out for Delivery
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={failedChecked}
                  onChange={(e) => {
                    setFailedChecked(e.target.checked);
                  }}
                />
                Failed
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={partiallyDeliveredChecked}
                  onChange={(e) => {
                    setPartiallyDeliveredChecked(e.target.checked);
                  }}
                />
                Partially Delivered
              </label>
            </div>
          ) : (
            ''
          )}
          <div>
            <label>
              <b className="delivery-select-title">
                <i>Customer</i>
              </b>
            </label>
            <br />

            <div className="form-group input-with-button">
              <div className="input-group mb-3">
                <select
                  className="deliverynotification-filters custom-select"
                  onChange={(e) => {
                    HandleDeliveryNotificationChange(e);
                  }}
                  value={selectedDrop}
                >
                  <option value="all drops">All Drops</option>
                  {deliveryNotificationList}
                </select>
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="all-drops-button"
                    value="all drops"
                    onClick={(e) => {
                      HandleDeliveryNotificationChange(e);
                    }}
                  >
                    All Drops
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {dropsList}
      </form>
    </div>
  );
}

export default DeliveryNotificationsPanel;
