import React, { useState } from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';

function NoBoundaryDetectedModal() {
  const [postCode, setPostCode] = useState('');
  const [invalidPostcode, setInvalidPostcode] = useState('');
  const { mapGlobal, fieldBoundaryDrawingManager } = useSoilHealthContext();

  const handleManualSearch = async () => {
    fieldBoundaryDrawingManager.setMap(mapGlobal);
    fieldBoundaryDrawingManager.setDrawingMode('polygon');
    setInvalidPostcode('');
    document.getElementById('field-boundary-detection-close').click();
  };

  const handlePostcodeSearch = async () => {
    const patt = /^(((([A-Za-z][A-Za-z]{0,1})[0-9][A-Za-z0-9]{0,1}) {0,}[0-9])[A-Za-z]{2})$/;
    if (patt.test(postCode)) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: postCode }, (results, status) => {
        if (status == 'OK') {
          mapGlobal.setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });

          mapGlobal.setZoom(17);

          fieldBoundaryDrawingManager.setMap(mapGlobal);
          fieldBoundaryDrawingManager.setDrawingMode('polygon');

          setInvalidPostcode('');
        } else {
          alert(
            `Geocode was not successful for the following reason: ${status}`,
          );
        }
      });

      setPostCode('');
      document.getElementById('field-boundary-detection-close').click();
    } else {
      setInvalidPostcode('The postcode must follow a UK format');
    }
  };

  return (
    <div
      className="modal fade"
      id="field-boundary-detection"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              No Field Boundary Detected
            </h5>
            <button
              id="field-boundary-detection-close"
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <p>
              No field boundary has been detected for the selected field. Please
              search for the location and draw the field boundary.
            </p>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="validationTooltip01">Postcode</label>
                <div className="form-validation">
                  <i>{invalidPostcode}</i>
                </div>
                <div className="input-group">
                  <input
                    id="validationTooltip01"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    aria-label="IFS Customer No"
                    aria-describedby="button-addon"
                    pattern="^(((([A-Za-z][A-Za-z]{0,1})[0-9][A-Za-z0-9]{0,1}) {0,}[0-9])[A-Za-z]{2})$"
                    title="The postcode must follow a UK format"
                    value={postCode}
                    onChange={(e) => {
                      setPostCode(e.target.value);
                    }}
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon"
                      onClick={() => {
                        handlePostcodeSearch();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon"
                      onClick={() => {
                        handleManualSearch();
                      }}
                    >
                      Manually Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary modal-close-button"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoBoundaryDetectedModal;
