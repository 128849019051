import React from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { ClearAllFieldLabels } from '../Util/PrecisionToolBox';
import { useFarmData } from './FarmData-Context';

function DeleteMeasureModal() {
  const { measureGlobal, mapGlobal } = useSoilHealthContext();
  const { DeleteMeasure } = useFarmData();

  return (
    <div
      className="modal fade"
      id="delete-measure-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="deletemeasuremodal"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deletemeasuremodal">
              Delete Measure
            </h5>
          </div>
          <div id="delete-marker-modal-body" className="modal-body">
            <p>Are you sure you want to delete the selected measure?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                mapGlobal.setOptions({ disableDoubleClickZoom: false });
              }}
            >
              Cancel
            </button>
            <a
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                ClearAllFieldLabels();
                DeleteMeasure(measureGlobal.id);
                mapGlobal.setOptions({ disableDoubleClickZoom: false });
              }}
            >
              Delete
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteMeasureModal;
