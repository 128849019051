import React, { useState } from 'react';
import { useFarmData } from './FarmData-Context';

function AddHomePageLinkModal() {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [imageFile, setImageFile] = useState('');
  const { UploadHomePageLink } = useFarmData();

  return (
    <div
      className="modal fade"
      id="upload-home-page-link"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form
            id="add-field-form"
            onSubmit={(e) => {
              e.preventDefault();
              try {
                const decodedUrl = decodeURIComponent(websiteUrl);
                UploadHomePageLink(decodedUrl, imageFile);
                setWebsiteUrl('');
                setImageFile('');
                document.getElementById('homepage-image-upload').value = '';
                document.getElementById('add-home-page-link-close').click();
              } catch (err) {
                alert('There was an issue with the URL format.');
              }
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add Home Page Link
              </h5>
              <button
                id="add-home-page-link-close"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationTooltip01">Website Url</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    autoComplete="off"
                    value={websiteUrl}
                    pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                    title="The url must following the following format 'https://{website url}'"
                    onChange={(e) => {
                      setWebsiteUrl(e.target.value);
                    }}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
              <div>
                <div className="form-group">
                  <input
                    type="file"
                    className="form-control-file"
                    id="homepage-image-upload"
                    onChange={(e) => {
                      const { files } = e.target;
                      if (files[0].type === 'image/png') {
                        const _URL = window.URL || window.webkitURL;
                        const img = new Image();
                        const objectUrl = _URL.createObjectURL(files[0]);
                        img.onload = function () {
                          if (this.width !== 500 || this.height !== 500) {
                            alert('the height and width must equal 500px.');
                          } else {
                            setImageFile(files[0]);
                          }
                          _URL.revokeObjectURL(objectUrl);
                        };
                        img.src = objectUrl;
                      } else {
                        alert('the format must be png');
                      }
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-close-button"
                data-dismiss="modal"
              >
                Close
              </button>
              <button id="test" type="submit" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddHomePageLinkModal;
