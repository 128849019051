import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/Components/SoilHealthPanel/ViewReportButton.css';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { useAuth0 } from '../../Auth/Auth0-Context';
import { useFarmData } from '../FarmData-Context';

function DeleteReportButton({ reportName, sectionTitle, showDeleteModalCallback }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentSectionTitle, setCurrentSectionTitle] = useState();
  const {
    CurrentUser,
    CurrentFarm,
    CurrentField,
    DeleteSoilHealthDocument,
  } = useFarmData();
  const { user } = useAuth0();

  const handleDeleteButtonClick = () => setShowConfirmationModal(true);

  const showModalCallback = () => setShowConfirmationModal(false);

  const handleDeleteConfirmationClick = ({ currentTarget: { id } }) => {
    setShowConfirmationModal(false);
    showDeleteModalCallback();
    DeleteSoilHealthDocument({
      farm: CurrentFarm,
      reportType: currentSectionTitle,
      reportName: id,
      field: CurrentField !== 'all fields' ? CurrentField : null,
      selectedUser: user !== CurrentUser ? CurrentUser : null,
    });
  };

  useEffect(() => {
    setCurrentSectionTitle(sectionTitle);
  }, []);

  return (
    <>
      <Button onClick={handleDeleteButtonClick} className="view__report__button" variant="outline-warning">
        <FontAwesomeIcon icon={faFilePdf} />
        <div>{reportName}</div>
      </Button>
      <ConfirmationModal
        confirmationType="delete"
        itemName={reportName}
        onConfirmClick={handleDeleteConfirmationClick}
        showModalCallback={showModalCallback}
        showModal={showConfirmationModal}
      />
    </>
  );
}

export default DeleteReportButton;
