import React, { Component, createContext, useContext } from 'react';

export const IFSContext = createContext();
export const useIFS = () => useContext(IFSContext);

class IFSProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IFSCustomers: [],
      IFSUsers: [],
      IFSIsLoading: false,
      IFSMessage: '',
    };

    this.GetIFSCustomers = this.GetIFSCustomers.bind(this);
    this.GetIFSUsers = this.GetIFSUsers.bind(this);
    this.SetIFSMessageState = this.SetIFSMessageState.bind(this);
  }

  async GetIFSCustomers(customerid = null, name = null, pagenumber = 0) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        IFSIsLoading: true,
      });

      let firstParam = true;

      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/ifscustomers`;

      if (customerid !== null) {
        if (firstParam) {
          url += `?customerid=${customerid}`;
          firstParam = false;
        } else {
          url += `&customerid=${customerid}`;
        }
      }

      if (name !== null) {
        if (firstParam) {
          url += `?name=${name}`;
          firstParam = false;
        } else {
          url += `&name=${name}`;
        }
      }

      if (firstParam) {
        url += `?page=${pagenumber}`;
        firstParam = false;
      } else {
        url += `&page=${pagenumber}`;
      }

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                IFSIsLoading: false,
              });
            } else {
              response.json().then((ifsdata) => {
                currentComponent.setState({
                  IFSCustomers: ifsdata,
                  IFSIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              IFSMessage: 'you do not have permission to access IFS customers.',
              IFSIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                IFSMessage: error.message,
                IFSIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            IFSMessage: error,
            IFSIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async GetIFSUsers(personcode = null, name = null, pagenumber = 0) {
    const currentComponent = this;
    async function fetchData(props) {
      const token = await props.token;
      currentComponent.setState({
        IFSIsLoading: true,
      });
      let firstParam = true;

      let url = `${process.env.REACT_APP_AZURE_API_DOMAIN}api/v1/ifsusers`;

      if (personcode !== null) {
        if (firstParam) {
          url += `?personcode=${personcode}`;
          firstParam = false;
        } else {
          url += `&personcode=${personcode}`;
        }
      }

      if (name !== null) {
        if (firstParam) {
          url += `?name=${name}`;
          firstParam = false;
        } else {
          url += `&name=${name}`;
        }
      }

      if (firstParam) {
        url += `?page=${pagenumber}`;
        firstParam = false;
      } else {
        url += `&page=${pagenumber}`;
      }

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              currentComponent.setState({
                IFSIsLoading: false,
              });
            } else {
              response.json().then((ifsdata) => {
                currentComponent.setState({
                  IFSUsers: ifsdata,
                  IFSIsLoading: false,
                });
              });
            }
          } else if (response.status === 401 || response.status === 403) {
            currentComponent.setState({
              IFSMessage: 'you do not have permission to access IFS users.',
              IFSIsLoading: false,
            });

            document.getElementById('error-message-modal-trigger').click();
          } else {
            response.json().then((error) => {
              currentComponent.setState({
                IFSMessage: error.message,
                IFSIsLoading: false,
              });

              document.getElementById('error-message-modal-trigger').click();
            });
          }
        })
        .catch((error) => {
          currentComponent.setState({
            IFSMessage: error,
            IFSIsLoading: false,
          });

          document.getElementById('error-message-modal-trigger').click();
        });
    }

    fetchData(this.props);
  }

  async SetIFSMessageState(message) {
    this.setState({ IFSMessage: message });
  }

  render() {
    const { children } = this.props;
    const {
      IFSCustomers, IFSUsers, IFSMessage, IFSIsLoading,
    } = this.state;
    const configObject = {
      IFSCustomers,
      IFSUsers,
      IFSMessage,
      IFSIsLoading,
      SetIFSMessageState: (...p) => this.SetIFSMessageState(...p),
      GetIFSUsers: (...p) => this.GetIFSUsers(...p),
      GetIFSCustomers: (...p) => this.GetIFSCustomers(...p),
    };
    return (
      <IFSContext.Provider value={configObject}>{children}</IFSContext.Provider>
    );
  }
}

export default IFSProvider;
