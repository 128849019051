export const reportTypeOptionItems = [
  {
    key: '',
    name: 'Select Report Type...',
  },
  {
    key: 'Leaf Tissue Reports',
    name: 'Leaf Tissue Reports',
  },
  {
    key: 'Soil Health Reports',
    name: 'Soil Health Reports',
  },
  {
    key: 'Nutrient Budgets',
    name: 'Nutrient Budgets',
  },
  {
    key: 'Soil Management Plans',
    name: 'Soil Management Plans',
  },
  {
    key: 'Nutrient Management Plans',
    name: 'Nutrient Management Plans',
  },
  {
    key: 'Field Variation Reports',
    name: 'Field Variation Reports',
  },
  {
    key: 'PCN Reports',
    name: 'PCN Reports',
  },
  {
    key: 'Precision Nutrient Mapping Reports',
    name: 'Precision Nutrient Mapping Reports',
  },
  {
    key: 'Grain Analysis Reports',
    name: 'Grain Analysis Reports',
  },
];
