import React, { useEffect, useState } from 'react';
import PDFDisplay from '../Components/Common/PDFDisplay';
import { useFarmData } from '../Components/FarmData-Context';
import { GenerateReportsList } from '../Util/ExtractReports';
import Loader from '../Components/Loader';

function SoilHealthGlossary() {
  const { GetSoilHealthGlossary, SoilHealthGlossary, isLoading } = useFarmData();
  const [report, setReport] = useState(undefined);

  useEffect(async () => {
    if (!SoilHealthGlossary) {
      GetSoilHealthGlossary();

      return;
    }

    const reports = { reportsList: SoilHealthGlossary };
    const currentGlossary = await GenerateReportsList({ reports });
    setReport(currentGlossary);
  }, [SoilHealthGlossary]);

  return (
    <div className="soil__health__page">
      {(isLoading || !report) && <Loader />}
      {report && <PDFDisplay pdfFileURL={report[0].url} />}
    </div>
  );
}

export default SoilHealthGlossary;
