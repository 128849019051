import React, { useEffect } from 'react';
import '../Styles/DeliveryNotificationsMap.css';
import { useDeliveryNotificationsContext } from '../Pages/DeliveryNotifications';
import DeliveryNotificationsMapLegend from './DeliveryNotificationsMapLegend';

let deliveryNotificationsMap;

function DeliveryNotificationsMap() {
  const { SetDeliveryMapState } = useDeliveryNotificationsContext();

  useEffect(() => {
    if (document.getElementById('googlemapscript') === null) {
      const googleMapScript = document.createElement('script');
      googleMapScript.id = 'googlemapscript';
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_API_KEY
      }&libraries=drawing,geometry`;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener('load', () => {
        deliveryNotificationsMap = new window.google.maps.Map(
          document.getElementById('deliverynotificationsmap'),
          {
            center: { lat: 52.349206954080564, lng: 0.923879995915898 },
            zoom: 16,
            maxZoom: 16,
            minZoom: 7,
            fullscreenControl: false,
            streetViewControl: false,
          },
        );

        const styles = {
          default: [],
          hide: [
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }],
            },
          ],
        };

        deliveryNotificationsMap.setOptions({ styles: styles.hide });

        SetDeliveryMapState(deliveryNotificationsMap);
      });
    } else if (
      document.getElementById('deliverynotificationsmap').childNodes.length
        === 0
      || deliveryNotificationsMap == undefined
    ) {
      deliveryNotificationsMap = new window.google.maps.Map(
        document.getElementById('deliverynotificationsmap'),
        {
          center: { lat: 52.349206954080564, lng: 0.923879995915898 },
          zoom: 16,
          maxZoom: 16,
          minZoom: 7,
          fullscreenControl: false,
          streetViewControl: false,
        },
      );

      const styles = {
        default: [],
        hide: [
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      };

      deliveryNotificationsMap.setOptions({ styles: styles.hide });

      SetDeliveryMapState(deliveryNotificationsMap);
    }
  });

  return (
    <>
      <div id="deliverynotificationsmap" />
      <DeliveryNotificationsMapLegend />
    </>
  );
}

export default DeliveryNotificationsMap;
