import React from 'react';
import '../Styles/SoilHealthMapLegend.css';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { RateColours } from './SoilHealthMap';
import { useFarmData } from './FarmData-Context';
import { ExtractGeoJsonProperties } from '../Util/ExtractGeoJsonProperties';

function SoilHealthMapLegend({ selectedAnalysisType, selectedYear }) {
  const { mapGlobal, rateKeyGlobal } = useSoilHealthContext();
  const { FieldAnalysis } = useFarmData();
  let rateKeyList = rateKeyGlobal;
  let unitOfMeasure = '';

  try {
    let featureRankArray = [];
    let propertiesListArray = [
      {
        properties: '',
      },
    ];
    const ratesListArray = [];

    if (
      FieldAnalysis.length > 0
      && document.getElementById('analysis-select') !== null
    ) {
      const selectedAnalysis = document.getElementById('analysis-select').value;

      for (let i = 0; i < FieldAnalysis.length; i++) {
        if (
          FieldAnalysis[i].name === selectedAnalysis
          && FieldAnalysis[i].year === selectedYear
        ) {
          propertiesListArray = ExtractGeoJsonProperties(FieldAnalysis[i]);
        }
      }

      for (let i = 0; i < propertiesListArray.length; i++) {
        for (let j = 0; j < propertiesListArray[i].properties.length; j++) {
          if (
            propertiesListArray[i].properties[j].name
              === selectedAnalysisType
            && propertiesListArray[i].properties[j].analysis === selectedAnalysis
          ) {
            ratesListArray[i] = {
              rate: i,
              featureId: propertiesListArray[i].featureId,
              value: propertiesListArray[i].properties[j].value,
              type: propertiesListArray[i].properties[j].type,
              unitofmeasure: propertiesListArray[i].properties[j].unitofmeasure,
            };
          }
        }
      }
    }

    mapGlobal.data.forEach((feature) => {
      const featureRank = {
        propertyValue: '',
        propertyColour: '',
      };

      featureRank.propertyValue = feature.getProperty(selectedAnalysisType);
      featureRank.propertyColour = RateColours(
        featureRank.propertyValue,
        mapGlobal,
        ratesListArray,
      );

      featureRankArray.push(featureRank);
    });

    featureRankArray.sort((a, b) => {
      if (
        typeof a.propertyValue === 'number'
        && typeof a.propertyValue === 'number'
      ) {
        return a.propertyValue - b.propertyValue;
      }
      return -1;
    });

    featureRankArray.reverse();

    const consolidatedRankArray = [];

    for (let i = 0; i < featureRankArray.length; i++) {
      if (
        i > 0
        && featureRankArray[i].propertyValue
          != featureRankArray[i - 1].propertyValue
      ) {
        consolidatedRankArray.push(featureRankArray[i]);
      } else if (i === 0) {
        consolidatedRankArray.push(featureRankArray[i]);
      }
    }
    featureRankArray = consolidatedRankArray;

    rateKeyList = featureRankArray.length > 0
      && featureRankArray.map((item, i) => {
        return (
          <div key={i}>
            <div
              style={{ backgroundColor: item.propertyColour }}
              className="ratekey-colours"
            />
            <div>{item.propertyValue}</div>
          </div>
        );
      }, this);

    for (let i = 0; FieldAnalysis.length; i++) {
      if (
        FieldAnalysis[i].name
        === document.getElementById('analysis-select').value
      ) {
        unitOfMeasure = FieldAnalysis[i].unitofmeasure;
      }
    }
  } catch (error) {}

  return (
    <div id="info-box">
      <div id="ratekey-title">
        <b>
          <i>
            {rateKeyList.length > 0 ? `Rate Key (${unitOfMeasure})` : ''}
          </i>
        </b>
      </div>
      {rateKeyList}
    </div>
  );
}

export default SoilHealthMapLegend;
