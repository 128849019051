import React, { useState, useEffect } from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { useFarmData } from '../Components/FarmData-Context';
import { HideMarkers, ShowMarkers } from '../Components/SoilHealthMap';
import '../Styles/PrecisionToolBox.css';
import polyLabel from 'polylabel';
import * as turf from '@turf/turf';

// variables for functions below to hold data in memory for the
// duration of the session.
let locationMarker;
let stayOnLocation;
let watchedPosition;
let currentLat;
let currentLng;
let fieldInfoWindow;
const allFieldInfoWindow = [];
const allMeasuresInfoWindow = [];
let measureInfoWindow;
let measuredrawingManager;
let measuredrawingManagerLengthHandle;
let measuredrawingManagerAreaHandle;
let createZonesdrawingManager;
let createZonesdrawingManagerHandle;
let markerdrawingManager;
let measuredStartLatLong;
let startMarker;
let measuredEndLatLong;
let endMarker;
let poly;
let calculateAreaPolygon;
let measureAreaInfoWindow;
let zonesArray = [];
let polygons = [];
let fieldPolygon;

// function to add text to the map, usun the parameters a div is placed
// within a bounds of a location. Primarily used for fields/measure labels.
export function AddTextToMap(map, latlng, bounds, customTxt, className) {
  // function used as a constructor for the label.
  function TxtOverlay(pos, bounds, txt, cls, map) {
    // Now initialize all properties.
    this.pos = pos;
    this.bounds = bounds;
    this.txt_ = txt;
    this.cls_ = cls;
    this.map_ = map;

    // We define a property to hold the image's div.
    this.div_ = null;

    // Explicitly call setMap() on this overlay
    this.setMap(map);
  }

  // inherit various methods too TxtOverlay to Add, Remove and
  // toggle display of the labels.
  TxtOverlay.prototype = new window.google.maps.OverlayView();

  TxtOverlay.prototype.onAdd = function () {
    // Note: an overlay's receipt of onAdd() indicates that
    // the map's panes are now available for attaching
    // the overlay to the map via the DOM.

    // Create the DIV and set some basic attributes.
    const div = document.createElement('DIV');
    div.className = this.cls_;
    div.innerHTML = this.txt_;

    // Set the overlay's div_ property to this DIV
    this.div_ = div;
    const overlayProjection = this.getProjection();
    const position = overlayProjection.fromLatLngToDivPixel(this.pos);
    div.style.left = `${position.x}px`;
    div.style.top = `${position.y}px`;

    // Add an overlay to a map via one of the map's panes.
    const panes = this.getPanes();
    panes.floatPane.appendChild(div);

    // if its a field area label, hide the label on entering
    // the div.
    if (className === 'fieldarealabel') {
      div.addEventListener(
        'mouseenter',
        (e) => {
          div.style.visibility = 'hidden';
        },
        false,
      );
    }
  };

  TxtOverlay.prototype.draw = function () {
    const overlayProjection = this.getProjection();

    // Retrieve the southwest and northeast coordinates of this overlay
    // in latlngs and convert them to pixels coordinates.
    const position = overlayProjection.fromLatLngToDivPixel(this.pos);
    const div = this.div_;
    const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
    const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

    // Resize the image's div to fit the indicated dimensions.
    if (div != null) {
      const fontSize = (sw.y - ne.y) / 25;
      div.style.position = 'absolute';
      div.style.left = `${position.x}px`;
      div.style.top = `${position.y}px`;

      // If the label is a field label hide it when the labels get too small,
      // otherwise set a minimum pixel size for the label.
      if (className === 'fieldarealabel' || className === 'allfieldarealabel') {
        if (fontSize < 5) {
          div.style.visibility = 'hidden';
        } else {
          div.style.visibility = 'visible';
          div.style.fontSize = `${fontSize}px`;
        }
      } else if (fontSize < 15) {
        div.style.fontSize = '15px';
      } else {
        div.style.fontSize = `${fontSize}px`;
      }
    }
  };

  // Removing and toggle the text overlay.
  TxtOverlay.prototype.onRemove = function () {
    if (this.div_) {
      this.div_.parentNode.removeChild(this.div_);
      this.div_ = null;
    }
  };

  TxtOverlay.prototype.hide = function () {
    if (this.div_) {
      this.div_.style.visibility = 'hidden';
    }
  };

  TxtOverlay.prototype.show = function () {
    if (this.div_) {
      this.div_.style.visibility = 'visible';
    }
  };

  TxtOverlay.prototype.toggle = function () {
    if (this.div_) {
      if (this.div_.style.visibility == 'hidden') {
        this.show();
      } else {
        this.hide();
      }
    }
  };

  TxtOverlay.prototype.toggleDOM = function () {
    if (this.getMap()) {
      this.setMap(null);
    } else {
      this.setMap(this.map_);
    }
  };

  // Push the label to the relevant variable based on the class name
  // assigned.
  if (
    className === 'allfieldarealabel'
    || className === 'overlaymeasurelabel'
  ) {
    const fieldInfo = new TxtOverlay(latlng, bounds, customTxt, className, map);
    allFieldInfoWindow.push(fieldInfo);
  } else if (className === 'fieldarealabel') {
    fieldInfoWindow = new TxtOverlay(latlng, bounds, customTxt, className, map);
  } else if (className === 'measurelengthlabel') {
    measureInfoWindow = new TxtOverlay(
      latlng,
      bounds,
      customTxt,
      className,
      map,
    );

    allMeasuresInfoWindow.push(measureInfoWindow);
  } else if (className === 'measurearealabel') {
    measureAreaInfoWindow = new TxtOverlay(
      latlng,
      bounds,
      customTxt,
      className,
      map,
    );
  }
}

// Function to toggle all field labels held in the variable.
export function ToggleFieldLabels() {
  if (fieldInfoWindow != undefined) {
    fieldInfoWindow.toggleDOM();
  }
}

// Function to clear all labels held in the relevant variables.
export function ClearAllFieldLabels() {
  if (fieldInfoWindow != undefined) {
    fieldInfoWindow.onRemove();
  }

  for (let i = 0; i < allMeasuresInfoWindow.length; i++) {
    allMeasuresInfoWindow[i].onRemove();
  }

  for (let i = 0; i < allFieldInfoWindow.length; i++) {
    allFieldInfoWindow[i].onRemove();
  }
}

// Set the polygon parameter to the relevant variable.
export function SetFieldPolygon(polygon) {
  fieldPolygon = polygon;
}

// Get the polygon from then relevant variable.
export function GetFieldPolygon() {
  return fieldPolygon;
}

// Calculate the area of a polygon and set the label on the map.
export function CalculateArea(map, polygon, classname) {
  if (window.google.maps.geometry) {
    function attachPolygonInfoWindow(polygon) {
      // check the class name and apply the logic using the if condition. the label
      // text varies based on what class it belongs too.
      if (classname === 'measurearealabel') {
        if (measureAreaInfoWindow != undefined) {
          measureAreaInfoWindow.toggleDOM();
        }

        const bounds = new window.google.maps.LatLngBounds();

        const polygonPath = polygon.getPath();
        const polygonPathArray = polygonPath.getArray();
        var myPolygon = [];

        for (let i = 0; i < polygonPathArray.length; i++) {
          bounds.extend(polygonPathArray[i]);
          myPolygon[i] = [polygonPathArray[i].lat(), polygonPathArray[i].lng()];
        }

        var center = polyLabel([myPolygon]);
        var latLng = new window.google.maps.LatLng(center[0], center[1]);
        const contentString = `<i>${polygonAreaHA.toFixed(2)}ha</i>`;
        AddTextToMap(map, latLng, bounds, contentString, classname);
      } else {
        if (fieldInfoWindow != undefined) {
          fieldInfoWindow.onRemove();
        }

        const bounds = new window.google.maps.LatLngBounds();

        const polygonPath = polygon.getPath();
        const polygonPathArray = polygonPath.getArray();
        var myPolygon = [];

        for (let i = 0; i < polygonPathArray.length; i++) {
          bounds.extend(polygonPathArray[i]);
          myPolygon[i] = [polygonPathArray[i].lat(), polygonPathArray[i].lng()];
        }

        var center = polyLabel([myPolygon]);
        var latLng = new window.google.maps.LatLng(center[0], center[1]);
        if (polygon.fieldname !== undefined) {
          const contentString = `${'<p><b>Field:</b> '
            + '<i>'}${
            polygon.fieldname
          }</i>`
            + '</br>'
            + '<b>Field Area (Ha):</b> '
            + `<i>${
              polygonAreaHA.toFixed(2)
            }</i></p>`;

          AddTextToMap(map, latLng, bounds, contentString, classname);
        } else if (polygon.label !== undefined) {
          const contentString = `${'<p><b>Label:</b> '
            + '<i>'}${
            polygon.label
          }</i>`
            + '</br>'
            + '<b>Area (Ha):</b> '
            + `<i>${
              polygonAreaHA.toFixed(2)
            }</i></p>`;

          AddTextToMap(map, latLng, bounds, contentString, classname);
        } else {
          const contentString = `<b>Area (Ha):</b><i>${polygonAreaHA.toFixed(2)}</i>`;
          AddTextToMap(map, latLng, bounds, contentString, classname);
        }

        // Set hover events to labels to dont fall into all fields label or overlay
        // classes.
        if (
          classname !== 'allfieldarealabel'
          && classname !== 'overlaymeasurelabel'
        ) {
          window.google.maps.event.addListener(
            polygon,
            'mouseover',
            (e) => {
              fieldInfoWindow.hide();
            },
          );

          window.google.maps.event.addListener(
            polygon,
            'mouseout',
            (e) => {
              fieldInfoWindow.show();
            },
          );

          window.google.maps.event.addListener(polygon, 'click', (e) => {
            fieldInfoWindow.toggle();
          });
        }
      }
    }

    // convert area to hectares and call the info window function.
    let polygonAreaHA = window.google.maps.geometry.spherical.computeArea(polygon.getPath())
      / 10000;
    attachPolygonInfoWindow(polygon);

    return polygonAreaHA.toFixed(2);
  }
}

// Function to calculate length when called.
export function CalculateLength(map, latLngA, latLngB, label = null) {
  if (window.google.maps.geometry) {
    // Get the distance between the points in meters
    const distanceMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
      latLngA,
      latLngB,
    );

    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(latLngA);
    bounds.extend(latLngB);

    // Add the text to the map.
    if (label !== null) {
      AddTextToMap(
        map,
        bounds.getCenter(),
        bounds,
        `<p>${label}<br />${distanceMeters.toFixed(2)}m</p>`,
        'measurelengthlabel',
      );
    } else {
      AddTextToMap(
        map,
        bounds.getCenter(),
        bounds,
        `<p>${distanceMeters.toFixed(2)}m</p>`,
        'measurelengthlabel',
      );
    }

    return window.google.maps.geometry.spherical.computeDistanceBetween(
      latLngA,
      latLngB,
    );
  }
}

// Function to track the users location when called.
export function TrackLocationListener(map) {
  stayOnLocation = false;

  if (navigator.geolocation) {
    // Get the users location.
    const options = {
      enableHighAccuracy: true,
    };

    navigator.geolocation.getCurrentPosition((position) => {
      currentLat = position.coords.latitude;
      currentLng = position.coords.longitude;
    });
    navigator.geolocation.clearWatch(watchedPosition);
    watchedPosition = navigator.geolocation.watchPosition(
      ShowPosition,
      TrackingError,
      options,
    );

    // When the button is clicked watched the users location keeping the
    // map centred on the position.
    document
      .getElementById('tracklocationbutton')
      .addEventListener('click', () => {
        if (navigator.geolocation) {
          const lat = currentLat;
          const lng = currentLng;

          const myLatLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          };

          if (stayOnLocation) {
            stayOnLocation = false;

            if (locationMarker !== undefined) {
              locationMarker.setMap(null);
            }

            document.getElementById('tracklocationbutton').textContent = 'Track Location';

            document
              .getElementById('tracklocationbutton')
              .classList.remove('selected');
          } else {
            stayOnLocation = true;

            const icon = {
              url: 'Images/User-Location.png',
              scaledSize: new window.google.maps.Size(30, 30), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(15, 15), // anchor
            };

            if (locationMarker !== undefined) {
              locationMarker.setMap(null);
            }

            locationMarker = new window.google.maps.Marker({
              position: myLatLng,
              map,
              title: 'My Location',
              icon,
            });

            map.setCenter(myLatLng);

            document.getElementById('tracklocationbutton').textContent = 'Tracking Location';

            document
              .getElementById('tracklocationbutton')
              .classList.add('selected');
          }
        } else {
          alert('Geolocation is not supported by this browser.');
        }
      });
  } else {
    alert('Geolocation is not supported by this browser.');
  }

  function ShowPosition(position) {
    const myLatLng = {
      lat: parseFloat(position.coords.latitude),
      lng: parseFloat(position.coords.longitude),
    };

    if (stayOnLocation) {
      let i = 0;

      if (position.coords.accuracy < 20) {
        map.panTo(myLatLng);
        moveMarker();
      }

      // function to ensure the movement of the marker glides across the map
      // instead of a marker that jumps around the page when moved. The function
      // is recursively called to achieve the effect.
      function moveMarker() {
        const deltaLatDiff = (myLatLng.lat - parseFloat(locationMarker.getPosition().lat())) / 100;
        const deltaLongDiff = (myLatLng.lng - parseFloat(locationMarker.getPosition().lng())) / 100;

        const deltaLat = locationMarker.getPosition().lat() + deltaLatDiff;
        const deltaLong = locationMarker.getPosition().lng() + deltaLongDiff;

        const myDeltLatLng = {
          lat: parseFloat(deltaLat),
          lng: parseFloat(deltaLong),
        };

        locationMarker.setPosition(myDeltLatLng);

        if (i != 100) {
          i++;
          setTimeout(moveMarker, 10);
        }
      }
    }
  }

  function TrackingError(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
}

// Function to attach the measure drawing manager to the map.
export function AttachMeasureListener(map) {
  const icon = {
    url: 'Images/Utility-Marker-Icon.png',
    scaledSize: new window.google.maps.Size(15, 15), // scaled size
    origin: new window.google.maps.Point(0, 0), // origin
    anchor: new window.google.maps.Point(7.5, 7.5), // anchor
  };

  measuredrawingManager = new window.google.maps.drawing.DrawingManager({
    drawingControl: false,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: ['marker', 'polygon', 'polyline'],
    },
    polygonOptions: {
      editable: true,
      draggable: true,
    },
    markerOptions: {
      icon,
      draggable: true,
    },
  });

  measuredrawingManager.setMap(map);
}

// Function to attach the marker drawing manager to the map.
export function AttachMarkerListener(map) {
  const icon = {
    url: 'Images/Utility-Marker-Icon.png',
    scaledSize: new window.google.maps.Size(30, 30), // scaled size
    origin: new window.google.maps.Point(0, 0), // origin
    anchor: new window.google.maps.Point(15, 15), // anchor
  };

  markerdrawingManager = new window.google.maps.drawing.DrawingManager({
    drawingControl: false,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: ['marker'],
    },
    markerOptions: {
      icon,
      draggable: true,
    },
  });

  markerdrawingManager.setMap(map);

  return markerdrawingManager;
}

// Function to clear zones from the map.
export function UpdateFieldsArray() {
  for (let j = 0; j < zonesArray.length; j++) {
    zonesArray[j].setMap(null);
  }
  polygons = [];
}

// Function to attach the create zones drawing manager to the map.
export function AttachCreateZonesListener(map) {
  createZonesdrawingManager = new window.google.maps.drawing.DrawingManager({
    drawingControl: false,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: ['polyline'],
    },
    polylineOptions: {
      editable: true,
      zIndex: 20,
    },
  });

  createZonesdrawingManager.setMap(map);

  return createZonesdrawingManager;
}

// Function to create zones ones a line has been drawn to split the zones.
export function GetPolyZonesArray(polygonprep, drawnGeometry) {
  const newPolygons = [];

  // if its the first line drawn, apply the polygon cut logic to the
  // initial polygon and add cut zones to the polgons array. Otherwise
  // loop through the polygons applying the logic.
  if (polygons.length === 0) {
    const cutPolygon = PolygonCut(polygonprep, drawnGeometry, 't');
    if (cutPolygon != null) {
      turf.geomEach(cutPolygon, (geometry) => {
        newPolygons.push(geometry);
      });
    }
  } else {
    polygons.forEach((polygon, index) => {
      const cutPolygon = PolygonCut(polygon, drawnGeometry, 't');

      // if the line cuts the polygon add the two new polygons to
      // the array, otherwise keep the original polygon.
      if (cutPolygon != null) {
        turf.geomEach(cutPolygon, (geometry) => {
          newPolygons.push(geometry);
        });
      } else {
        newPolygons.push(polygon);
      }
    });
  }

  polygons = newPolygons;
  return polygons;
}

export function PolygonCut(polygon, line, idPrefix) {
  // Variables declared that are required for the functions logic
  // below.
  const THICK_LINE_UNITS = 'kilometers';
  const THICK_LINE_WIDTH = 0.001;
  let i;
  let j;
  let id;
  let intersectPoints;
  let lineCoords;
  let forCut;
  let forSelect;
  let thickLineString;
  let thickLinePolygon;
  let clipped;
  let polyg;
  let intersect;
  let polyCoords = [];
  let cutPolyGeoms = [];
  const cutFeatures = [];
  const offsetLine = [];
  let retVal = null;

  // Make sure the shapes passed in are a polygon or line.
  if (
    (polygon.type != 'Polygon' && polygon.type != 'MultiPolygon')
    || line.type != 'LineString'
  ) {
    return retVal;
  }

  // The id prefix passed in must contain a value
  if (typeof idPrefix === 'undefined') {
    idPrefix = '';
  }

  lineCoords = turf.getCoords(line);

  // This logic has been commented out, but contains future logic
  // to how we will achieve the ability to cut a zone from the middle
  // of a polygon without needing to intersect at two lines!

  /* if(lineCoords[0][0] === lineCoords[lineCoords.length - 1][0] &&
     lineCoords[0][1] === lineCoords[lineCoords.length - 1][1]) {

      for(let i = 0; i < lineCoords.length; i++) {
        if(!turf.booleanWithin(turf.point(lineCoords[i]), polygon)) {
          return;
        }
      }

      polyg = turf.polygon([lineCoords]);
      clipped = turf.difference(polygon, polyg);

      cutPolyGeoms = [];
      let clippedCoords = [];
      for (i = 0; i < clipped.geometry.coordinates.length; i++) {
        for(j = 0; j < clipped.geometry.coordinates[i].length; j++) {

          clippedCoords.push([clipped.geometry.coordinates[i][j][0],
                              clipped.geometry.coordinates[i][j][1]]);
        }
      }

      let multPolyg = turf.multiPolygon([clippedCoords], { Feature_Id: 7 });
      var flatten = turf.flatten(multPolyg);
      //cutPolyGeoms.push([turf.getCoords(flatten.features[0].geometry.coordinates)]);
      cutPolyGeoms.push(polyg.geometry.coordinates);

      cutPolyGeoms.forEach(function (geometry, index) {
        id = idPrefix + (i + 1) + "." + (index + 1);
        cutFeatures.push(turf.polygon(geometry, { Feature_Id: id }));
      });

      let coordsHolder = flatten.features[0].geometry.coordinates;
      coordsHolder[coordsHolder.length] = flatten.features[0].geometry.coordinates[0];
      flatten.features[0].geometry.coordinates = [];
      flatten.features[0].geometry.coordinates[0] = coordsHolder;

      cutFeatures.push(flatten.features[0]);

      if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);
    } */

  // Make sure the line does not cross over itself, if it does return out
  // the function.
  const lineIntersectSelf = turf.kinks(line);
  if (lineIntersectSelf.features.length > 0) {
    document.getElementById('lineintersects-message-modal-trigger').click();
    return retVal;
  }

  // Make sure the polygon and line intersects.
  intersectPoints = turf.lineIntersect(polygon, line);
  if (intersectPoints.features.length == 0) {
    document.getElementById('lineintersects-message-modal-trigger').click();
    return retVal;
  }

  // The first and last point on the line must be outside the polygon.
  if (
    turf.booleanWithin(turf.point(lineCoords[0]), polygon)
    || turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
  ) {
    return retVal;
  }

  // Create two line offsets and moved + the other -
  offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS,
  });
  offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS,
  });

  for (i = 0; i <= 1; i++) {
    // Variables 'forCut' & 'forSelect' select one half of the split polygon
    // on the first loop then switch for the second loop. The for loop only
    // executes twice.
    forCut = i;
    forSelect = (i + 1) % 2;
    polyCoords = [];

    // Push the line, offset line and finally the initial line coordinate to
    // create a thin polygon to use for cutting the polygon.
    for (j = 0; j < line.coordinates.length; j++) {
      polyCoords.push(line.coordinates[j]);
    }

    for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
      polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
    }

    polyCoords.push(line.coordinates[0]);

    // Take the coordinates, convert to linestring then to polygon and get the
    // difference between that and the polygon.
    thickLineString = turf.lineString(polyCoords);
    thickLinePolygon = turf.lineToPolygon(thickLineString);
    clipped = turf.difference(polygon, thickLinePolygon);

    // The 'clipped' variable contains two polygons from the split, the offsetline
    // only intersects one of the polygons so one polygon is added to 'cutFeatures'
    // the process is reversed in the second loop to add the other polygon.
    cutPolyGeoms = [];
    for (j = 0; j < clipped.geometry.coordinates.length; j++) {
      polyg = turf.polygon(clipped.geometry.coordinates[j]);
      intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
      if (intersect.features.length > 0) {
        cutPolyGeoms.push(polyg.geometry.coordinates);
      }
    }

    cutPolyGeoms.forEach((geometry, index) => {
      id = `${idPrefix + (i + 1)}.${index + 1}`;
      cutFeatures.push(turf.polygon(geometry, { Feature_Id: id }));
    });
  }

  if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);

  return retVal;
}

// Function to measure length and drag to update length measurements when called.
export function MeasureLength(map) {
  if (startMarker !== undefined) {
    startMarker.setMap(null);
  }

  if (endMarker !== undefined) {
    endMarker.setMap(null);
  }

  if (poly !== undefined) {
    poly.setMap(null);
  }

  if (measureInfoWindow !== undefined) {
    measureInfoWindow.onRemove();
  }

  measuredrawingManager.setDrawingMode('marker');

  window.google.maps.event.removeListener(measuredrawingManagerLengthHandle);

  measuredrawingManagerLengthHandle = window.google.maps.event.addListener(
    measuredrawingManager,
    'markercomplete',
    (event) => {
      if (measuredStartLatLong === undefined) {
        startMarker = event;
        measuredStartLatLong = new window.google.maps.LatLng(
          event.position.lat(),
          event.position.lng(),
        );
      } else if (measuredEndLatLong === undefined) {
        endMarker = event;
        measuredEndLatLong = new window.google.maps.LatLng(
          event.position.lat(),
          event.position.lng(),
        );
        CalculateLength(map, measuredStartLatLong, measuredEndLatLong);
        measuredStartLatLong = undefined;
        measuredEndLatLong = undefined;
        measuredrawingManager.setDrawingMode(null);

        window.google.maps.event.addListener(startMarker, 'drag', update);
        window.google.maps.event.addListener(endMarker, 'drag', update);
        poly = new window.google.maps.Polyline({
          strokeColor: '#004289',
          strokeOpacity: 1.0,
          strokeWeight: 3,
          map,
        });
        update();
      }

      function update() {
        ClearAllFieldLabels();
        CalculateLength(
          map,
          startMarker.getPosition(),
          endMarker.getPosition(),
        );
        const path = [startMarker.getPosition(), endMarker.getPosition()];
        poly.setPath(path);
      }
    },
  );
}

// Function to clear all measurements when called.
export function ClearMeasurements() {
  if (calculateAreaPolygon !== undefined) {
    calculateAreaPolygon.setMap(null);
  }

  if (measureAreaInfoWindow !== undefined) {
    measureAreaInfoWindow.onRemove();
  }

  if (startMarker !== undefined) {
    startMarker.setMap(null);
    startMarker = undefined;
  }

  if (endMarker !== undefined) {
    endMarker.setMap(null);
    endMarker = undefined;
  }

  if (poly !== undefined) {
    poly.setMap(null);
  }

  for (let i = 0; i < allMeasuresInfoWindow.length; i++) {
    allMeasuresInfoWindow[i].onRemove();
  }
}

// Function to measure area when called.
export function MeasureArea(map) {
  if (calculateAreaPolygon !== undefined) {
    calculateAreaPolygon.setMap(null);
  }

  if (measureAreaInfoWindow !== undefined) {
    measureAreaInfoWindow.onRemove();
  }

  measuredrawingManager.setDrawingMode('polygon');

  window.google.maps.event.removeListener(measuredrawingManagerAreaHandle);

  measuredrawingManagerAreaHandle = window.google.maps.event.addListener(
    measuredrawingManager,
    'polygoncomplete',
    (event) => {
      calculateAreaPolygon = event;
      CalculateArea(map, event, 'measurearealabel');
      measuredrawingManager.setDrawingMode(null);

      calculateAreaPolygon.setOptions({
        zIndex: 20,
        fillColor: '#e9aa00',
        strokeColor: '#e9aa00',
      });

      window.google.maps.event.addListener(
        calculateAreaPolygon,
        'drag',
        update,
      );

      calculateAreaPolygon.getPaths().forEach((path, index) => {
        window.google.maps.event.addListener(path, 'insert_at', () => {
          update();
        });

        window.google.maps.event.addListener(path, 'remove_at', () => {
          update();
        });
        window.google.maps.event.addListener(path, 'set_at', () => {
          update();
        });
      });

      update();

      function update() {
        CalculateArea(map, calculateAreaPolygon, 'measurearealabel');
      }
    },
  );
}

// Main component that renders the toolbox in the application, the component is added in the map
// component js file.
function PrecisionToolBox({ selectedField }) {
  const [initial, setInitial] = useState(true);
  const [currentOverlay, setCurrentOverlay] = useState('');
  const [mappedMeasures, setMappedMeasures] = useState([]);
  const {
    mapGlobal,
    SetPolygonZonesState,
    SetFieldPolygonGlobalState,
    SetMarkerState,
    SetMeasureState,
    SetMeasureClickState,
    measureClick,
    editBoundaryClick,
    markerClick,
    createZonesClick,
    trackLocationClick,
    SetMarkerClickState,
    selectedAnalysis,
    SetCreateZonesClickState,
    SetEditBoundaryClickState,
    SetCurrentMeasurementsState,
  } = useSoilHealthContext();
  const { Fields, Measures } = useFarmData();

  useEffect(() => {
    // Make sure the map has been loaded before executing the logic.
    if (mapGlobal !== undefined && mapGlobal.length !== 0 && window.google) {
      // If this is the initial load attach the listeners to the buttons.
      if (initial) {
        setInitial(false);
        AttachCreateZonesListener(mapGlobal);
        TrackLocationListener(mapGlobal);
        AttachMeasureListener(mapGlobal);
        const markerDrawingManager = AttachMarkerListener(mapGlobal);

        window.google.maps.event.addListener(
          markerDrawingManager,
          'overlaycomplete',
          function (event) {
            if (event.type === 'marker') {
              SetMarkerState(event.overlay);
              document.getElementById('info-box-modal-trigger').click();
              this.setDrawingMode(null);
            }
          },
        );
      }

      // set overlays on map
      if (document.getElementById('overlayselect')) {
        if (currentOverlay !== document.getElementById('overlayselect').value) {
          setCurrentOverlay(document.getElementById('overlayselect').value);
        }
      } else {
        setCurrentOverlay('');
      }

      const myMappedMeasures = [];

      // Clear the measures and labels on the map.
      ClearAllFieldLabels();
      for (let i = 0; i < mappedMeasures.length; i++) {
        mappedMeasures[i].setMap(null);
      }

      // For each measure,, if the selected overlay matches the measure
      // category add it the myMappedMeasures with delete functionality
      // added to the double click.
      for (let i = 0; i < Measures.length; i++) {
        if (
          currentOverlay === 'viewall'
          || Measures[i].category === currentOverlay
        ) {
          if (Measures[i].measuretype === 'Length') {
            const startLatLng = {
              lat: parseFloat(Measures[i].startmarkerlat),
              lng: parseFloat(Measures[i].startmarkerlng),
            };

            const icon = {
              url: 'Images/Utility-Marker-Icon.png',
              scaledSize: new window.google.maps.Size(15, 15), // scaled size
              origin: new window.google.maps.Point(0, 0), // origin
              anchor: new window.google.maps.Point(7.5, 7.5), // anchor
            };

            const startMarker = new window.google.maps.Marker({
              position: startLatLng,
              map: mapGlobal,
              icon,
            });

            startMarker.set('id', Measures[i].measureid);

            const endLatLng = {
              lat: parseFloat(Measures[i].endmarkerlat),
              lng: parseFloat(Measures[i].endmarkerlng),
            };

            const endMarker = new window.google.maps.Marker({
              position: endLatLng,
              map: mapGlobal,
              icon,
            });

            endMarker.set('id', Measures[i].measureid);

            const polyLine = new window.google.maps.Polyline({
              strokeColor: '#004289',
              strokeOpacity: 1.0,
              strokeWeight: 3,
              map: mapGlobal,
            });

            CalculateLength(
              mapGlobal,
              startMarker.getPosition(),
              endMarker.getPosition(),
              Measures[i].label,
            );
            const path = [startMarker.getPosition(), endMarker.getPosition()];
            polyLine.setPath(path);

            window.google.maps.event.addListener(
              startMarker,
              'dblclick',
              (e) => {
                SetMeasureState(startMarker);
                document.getElementById('delete-measure-modal-trigger').click();
              },
            );

            window.google.maps.event.addListener(
              endMarker,
              'dblclick',
              (e) => {
                SetMeasureState(endMarker);
                document.getElementById('delete-measure-modal-trigger').click();
              },
            );

            window.google.maps.event.addListener(
              polyLine,
              'dblclick',
              (e) => {
                SetMeasureState(polyLine);
                document.getElementById('delete-measure-modal-trigger').click();
              },
            );

            myMappedMeasures.push(startMarker);
            myMappedMeasures.push(endMarker);
            myMappedMeasures.push(polyLine);
          } else {
            const measurePolygon = JSON.parse(Measures[i].polygonpath);
            const zoneCoords = [];

            for (let j = 0; j < measurePolygon.coordinates.length; j++) {
              const latLong = measurePolygon.coordinates[j];
              zoneCoords.push({ lat: latLong[0], lng: latLong[1] });
            }

            var mappedMeasure = new window.google.maps.Polygon({
              map: mapGlobal,
              paths: zoneCoords,
              editable: false,
            });

            mappedMeasure.setOptions({
              zIndex: 20,
              fillColor: '#e9aa00',
              strokeColor: '#e9aa00',
            });

            mappedMeasure.set('id', Measures[i].measureid);
            mappedMeasure.set('label', Measures[i].label);

            window.google.maps.event.addListener(
              mappedMeasure,
              'dblclick',
              (e) => {
                mapGlobal.setOptions({ disableDoubleClickZoom: true });
                SetMeasureState(mappedMeasure);
                document.getElementById('delete-measure-modal-trigger').click();
              },
            );

            CalculateArea(mapGlobal, mappedMeasure, 'overlaymeasurelabel');

            myMappedMeasures.push(mappedMeasure);
          }
        }
      }

      setMappedMeasures(myMappedMeasures);

      // Remove and re-add the overlay complete event listener to the zones
      // polyline.
      window.google.maps.event.removeListener(createZonesdrawingManagerHandle);

      createZonesdrawingManagerHandle = window.google.maps.event.addListener(
        createZonesdrawingManager,
        'overlaycomplete',
        (event) => {
          // If its a polyline, apply the logic to cut the polygon and the array
          // of zones to the polygon state.
          if (event.type === 'polyline') {
            const colours = [
              '#fc0303',
              '#fcb103',
              '#f0fc03',
              '#6bfc03',
              '#03d7fc',
              '#0b03fc',
              '#d203fc',
              '#948e91',
            ];
            const fieldCoords = [];
            const lineCoords = [];
            for (let i = 0; i < Fields.length; i++) {
              if (
                Fields[i].key === document.getElementById('field-select').value
              ) {
                let fieldBoundary = JSON.parse(Fields[i].fieldCoords);

                if (typeof fieldBoundary === 'string') {
                  if (fieldBoundary.length > 0) {
                    fieldBoundary = JSON.parse(fieldBoundary);
                  } else {
                    fieldBoundary = null;
                  }
                }

                if (fieldBoundary !== null) {
                  const polylinePath = event.overlay.getPath();
                  const polylinePathArray = polylinePath.getArray();

                  for (
                    let j = 0;
                    j < fieldBoundary.coordinates[0].length;
                    j++
                  ) {
                    const latLong = fieldBoundary.coordinates[0][j];
                    fieldCoords.push([latLong[0], latLong[1]]);
                  }

                  for (let i = 0; i < polylinePathArray.length; i++) {
                    lineCoords.push([
                      polylinePathArray[i].lng(),
                      polylinePathArray[i].lat(),
                    ]);
                  }

                  const polygonPrep = {
                    coordinates: [fieldBoundary.coordinates[0]],
                    type: 'Polygon',
                  };

                  const polylinePrep = {
                    coordinates: lineCoords,
                    type: 'LineString',
                  };

                  const polygonList = GetPolyZonesArray(
                    polygonPrep,
                    polylinePrep,
                  );

                  for (var j = 0; j < zonesArray.length; j++) {
                    zonesArray[j].setMap(null);
                  }

                  zonesArray = [];
                  let coloursSelect = 0;

                  for (var j = 0; j < polygonList.length; j++) {
                    const zoneCoords = [];
                    for (
                      let k = 0;
                      k < polygonList[j].coordinates[0].length;
                      k++
                    ) {
                      const latLong = polygonList[j].coordinates[0][k];
                      zoneCoords.push({ lat: latLong[1], lng: latLong[0] });
                    }

                    const zone = new window.google.maps.Polygon({
                      map: mapGlobal,
                      paths: zoneCoords,
                      editable: false,
                    });

                    zone.setOptions({
                      zIndex: 20,
                      fillColor: colours[coloursSelect],
                      strokeColor: colours[coloursSelect],
                    });

                    coloursSelect++;

                    if (coloursSelect > 7) {
                      coloursSelect = 0;
                    }

                    zonesArray.push(zone);
                  }

                  SetPolygonZonesState(polygonList);
                  event.overlay.setMap(null);
                }
              }
            }
          }
        },
      );
    }
  }, [mapGlobal, Fields, currentOverlay, Measures]);

  // Function to group overlay categories so they only
  // appear once in the list.
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const allCategories = [];

  for (let i = 0; i < Measures.length; i++) {
    allCategories.push(Measures[i].category);
  }

  const uniqueCategories = allCategories.filter(onlyUnique);

  // Create overlay list of options.
  const measuresOverlayList = uniqueCategories.length > 0
    && uniqueCategories.map((item, i) => {
      return (
        <option key={i} value={item}>
          {item}
        </option>
      );
    }, this);

  // Return the component that renders the tool box, each button renders based on the state
  // of the application.
  return (
    <div id="maptoolboxdiv">
      <div id="buttoncontainer">
        {selectedField === 'all fields' || selectedAnalysis !== '' ? (
          ''
        ) : (
          <>
            {!editBoundaryClick
            && !createZonesClick
            && !markerClick
            && !measureClick
            && !trackLocationClick ? (
              <>
                <button
                  id="editboundarybutton"
                  className="maptoolbox-button"
                  onClick={(e) => {
                    const fieldPolygon = GetFieldPolygon();
                    setCurrentOverlay('');

                    if (
                      fieldPolygon === undefined
                      || fieldPolygon.map === null
                    ) {
                      document
                        .getElementById('field-boundary-detection-trigger')
                        .click();
                    } else {
                      fieldPolygon.setEditable(true);
                      SetEditBoundaryClickState(true);
                    }

                    HideMarkers();
                    ToggleFieldLabels();
                  }}
                >
                  Edit/Draw Boundary
                </button>
                <button
                  id="attachzonesbutton"
                  className="maptoolbox-button"
                  onClick={(e) => {
                    createZonesdrawingManager.setDrawingMode('polyline');
                    SetCreateZonesClickState(true);
                    HideMarkers();
                    ToggleFieldLabels();
                    setCurrentOverlay('');
                  }}
                >
                  Create Zones
                </button>
                <button
                  id="markerbutton"
                  className="maptoolbox-button"
                  onClick={(e) => {
                    markerdrawingManager.setDrawingMode('marker');
                    SetMarkerClickState(true);
                  }}
                >
                  Marker
                </button>
                <button
                  id="measurebutton"
                  className="maptoolbox-button"
                  onClick={(e) => {
                    document
                      .getElementById('select-measure-modal-trigger')
                      .click();
                    SetMeasureClickState(true);
                    HideMarkers();
                    ToggleFieldLabels();
                  }}
                >
                  Measure
                </button>
              </>
              ) : (
                ''
              )}
            {editBoundaryClick ? (
              <>
                <button
                  id="clearsaveboundarybutton"
                  className="maptoolbox-button"
                  onClick={(e) => {
                    const fieldPolygon = GetFieldPolygon();
                    SetFieldPolygonGlobalState(fieldPolygon);
                    fieldPolygon.setEditable(false);
                    SetEditBoundaryClickState(false);
                    document
                      .getElementById('save-field-boundary-trigger')
                      .click();

                    ShowMarkers();
                    ToggleFieldLabels();
                  }}
                >
                  Clear/Save Boundary
                </button>
              </>
            ) : (
              ''
            )}
            {createZonesClick ? (
              <button
                id="saveclearzonesbutton"
                className="maptoolbox-button"
                onClick={(e) => {
                  document.getElementById('save-zones-trigger').click();
                  createZonesdrawingManager.setDrawingMode(null);
                  SetCreateZonesClickState(false);
                  ShowMarkers();
                  ToggleFieldLabels();
                }}
              >
                Save/Clear Zones
              </button>
            ) : (
              ''
            )}
            {markerClick ? (
              <button
                id="saveclearmarkerbutton"
                className="maptoolbox-button"
                onClick={(e) => {
                  markerdrawingManager.setDrawingMode(null);
                  SetMarkerClickState(false);
                }}
              >
                Save/Clear Marker
              </button>
            ) : (
              ''
            )}
            {measureClick ? (
              <button
                id="saveclearmeasurebutton"
                className="maptoolbox-button"
                onClick={(e) => {
                  let measureType = '';
                  let startMarkerLat = '';
                  let startMarkerLng = '';
                  let endMarkerLat = '';
                  let endMarkerLng = '';
                  let currentMeasure = '';
                  let unitOfMeasure = '';
                  const areaPath = [];

                  if (startMarker !== undefined && endMarker !== undefined) {
                    measureType = 'Length';
                    startMarkerLat = startMarker.getPosition().lat();
                    startMarkerLng = startMarker.getPosition().lng();
                    endMarkerLat = endMarker.getPosition().lat();
                    endMarkerLng = endMarker.getPosition().lng();
                    const measureIndex = measureInfoWindow.txt_.indexOf('m');
                    currentMeasure = measureInfoWindow.txt_.substr(
                      3,
                      measureIndex - 3,
                    );
                    unitOfMeasure = measureInfoWindow.txt_.substr(
                      measureIndex,
                      1,
                    );
                  } else {
                    measureType = 'Area';
                    const polygonPath = calculateAreaPolygon.getPath();
                    const polygonPathArray = polygonPath.getArray();

                    for (let i = 0; i < polygonPathArray.length; i++) {
                      areaPath[i] = [
                        polygonPathArray[i].lat(),
                        polygonPathArray[i].lng(),
                      ];
                    }

                    const measureIndex = measureAreaInfoWindow.txt_.indexOf('h');
                    currentMeasure = measureAreaInfoWindow.txt_.substr(
                      3,
                      measureIndex - 3,
                    );

                    unitOfMeasure = measureAreaInfoWindow.txt_.substr(
                      measureIndex,
                      2,
                    );
                  }

                  const polygonPath = {
                    coordinates: areaPath,
                  };

                  const currentMeasurements = {
                    Measure_Type: measureType,
                    Farm_ID: document.getElementById('farm-select').value,
                    Field_ID: document.getElementById('field-select').value,
                    Label: '',
                    Category: '',
                    Measure_Year: '',
                    Measure: currentMeasure,
                    Unit_Of_Measure: unitOfMeasure,
                    Start_Marker_Lat: startMarkerLat.toString(),
                    Start_Marker_Lng: startMarkerLng.toString(),
                    End_Marker_Lat: endMarkerLat.toString(),
                    End_Marker_Lng: endMarkerLng.toString(),
                    Polygon_Path: JSON.stringify(polygonPath),
                  };

                  SetCurrentMeasurementsState(currentMeasurements);
                  document.getElementById('save-measure-modal-trigger').click();
                  measuredrawingManager.setDrawingMode(null);
                  SetMeasureClickState(false);
                  ShowMarkers();
                  ToggleFieldLabels();
                }}
              >
                Save/Clear Measures
              </button>
            ) : (
              ''
            )}
          </>
        )}
        <button id="tracklocationbutton" className="maptoolbox-button">
          Track Location
        </button>
      </div>
      <div>
        {selectedField === 'all fields'
        || selectedAnalysis !== ''
        || editBoundaryClick
        || createZonesClick
        || markerClick
        || measureClick
        || trackLocationClick ? (
            ''
          ) : (
            <>
              <select
                id="overlayselect"
                className="custom-select"
                onChange={(e) => {
                  setCurrentOverlay(e.target.value);
                }}
              >
                <option value="none">Select an overlay...</option>
                <option value="viewall">View all</option>
                {measuresOverlayList}
              </select>
            </>
          )}
      </div>
      <a
        id="lineintersects-message-modal-trigger"
        data-target="#lineintersects-message-modal"
        data-toggle="modal"
      />
    </div>
  );
}

export default PrecisionToolBox;
