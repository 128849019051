import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import {
  startCase, replace, find, every, isEmpty,
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/Components/SoilHealthPanel/SoilHealthPanelReports.css';
import UploadReportButton from './UploadReportButton';
import DeleteReportButtonModalTrigger from './DeleteReportButtonModalTrigger';
import DownloadReportButton from './DownloadReportButton';
import ReportBody from '../Common/ReportBody';
import DeleteSoilHealthReportsModal from '../../Modals/DeleteSoilHealthReportsModal';
import { GenerateReportsList, DownloadSectionReports } from '../../Util/ExtractReports';
import { findSoilHealthReportSection } from '../../Util/FindSoilHealthReportSection';
import { useAuth0 } from '../../Auth/Auth0-Context';
import { useFarmData } from '../FarmData-Context';
import { sectionsLoaded } from '../../Models/SectionsLoaded';

function areAllReportsLoaded({ farm, SoilHealthReports, totalSectionsLength = 9 }) {
  const selectFarmsReports = find(SoilHealthReports, ['farm', farm]);

  return selectFarmsReports && selectFarmsReports.reportTypeArray.length === totalSectionsLength;
}

function SoilHealthPanelReports({ sectionTitle }) {
  const [reportList, setReportList] = useState([]);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [soilHealthReportsLoader, setSoilHealthReportsLoader] = useState(false);
  const { user } = useAuth0();
  const {
    CurrentUser,
    GetSoilHealthDocs,
    CurrentFarm,
    CurrentField,
    CurrentValidFromDate,
    CurrentValidToDate,
    SoilHealthReports,
    TriggerSoilHealthReportsUpdate,
    SetDisableFieldsState,
    SetIsLoadingState,
  } = useFarmData();

  const handleBinButtonClick = () => setShowDeleteReportModal(true);

  const showModalDeleteReportCallback = () => setShowDeleteReportModal(false);

  const handleDownloadButtonClick = async () => {
    const farm = CurrentFarm;
    const field = CurrentField !== 'all fields' ? CurrentField : null;
    const selectedUser = user !== CurrentUser ? CurrentUser : null;

    const soilHealthDocuments = await GetSoilHealthDocs({
      farm,
      reportType: startCase(sectionTitle),
      validFrom: CurrentValidFromDate,
      validTo: CurrentValidToDate,
      reportName: null,
      field,
      selectedUser,
      documentSummary: false,
    });

    await DownloadSectionReports({
      reports: soilHealthDocuments,
    });

    SetIsLoadingState(false);
  };

  useEffect(async () => {
    const farm = CurrentFarm;
    const field = CurrentField !== 'all fields' ? CurrentField : null;
    const selectedUser = user !== CurrentUser ? CurrentUser : null;

    if (TriggerSoilHealthReportsUpdate && farm) {
      sectionsLoaded[sectionTitle] = false;
      setSoilHealthReportsLoader(true);
      await GetSoilHealthDocs({
        farm,
        reportType: startCase(sectionTitle),
        validFrom: CurrentValidFromDate,
        validTo: CurrentValidToDate,
        reportName: null,
        field,
        selectedUser,
        documentSummary: true,
      });
    }

    const updatedReportsList = findSoilHealthReportSection({
      reports: SoilHealthReports, section: sectionTitle, farm, field, selectedUser,
    });

    if (updatedReportsList || areAllReportsLoaded({ farm, SoilHealthReports })) {
      setReportList(updatedReportsList.reportsList);
      setSoilHealthReportsLoader(false);
      sectionsLoaded[sectionTitle] = true;
    }

    if (!isEmpty(sectionsLoaded) && every(sectionsLoaded, (values, key) => sectionsLoaded[key])) {
      SetDisableFieldsState(false);
    }
  }, [CurrentFarm, CurrentField, CurrentUser, CurrentValidFromDate, SoilHealthReports]);

  return (
    <Card className="soil__health__panel__reports" id={replace(sectionTitle, new RegExp('\\s', 'g'), '__')}>
      <Card.Header>
        <div className="section__title__group left__grouping_titles float-left">
          <FontAwesomeIcon icon={faFileLines} style={{ color: '#e9aa00' }} />
          <span className="section__title">{' '}{startCase(sectionTitle)}</span>
        </div>
        <div className="section__title__group float-right">
          <UploadReportButton />
          <DownloadReportButton reportName={startCase(sectionTitle)} onDownloadButtonClick={handleDownloadButtonClick} />
          <DeleteReportButtonModalTrigger onDeleteIconClick={handleBinButtonClick} />
        </div>
      </Card.Header>
      <Card.Body>
        <ReportBody reportList={reportList} isLoading={soilHealthReportsLoader} sectionTitle={sectionTitle} />
      </Card.Body>
      <DeleteSoilHealthReportsModal
        sectionTitle={sectionTitle}
        showModalCallback={showModalDeleteReportCallback}
        showModal={showDeleteReportModal}
      />
    </Card>
  );
}

export default SoilHealthPanelReports;
