import { apiResponse } from '../Models/APIResponse';

export async function FormatResponseMessage(response) {
  const responseCopy = response.clone();
  const responseJSONCopy = response.clone();
  const apiResponseCopy = { ...apiResponse };

  apiResponseCopy.responseStatus = response.status;
  apiResponseCopy.responseBlob = await responseCopy.blob().then((blob) => {
    apiResponseCopy.responseType = blob.type;
    return apiResponseCopy.responseType === 'application/zip' ? blob : null;
  });

  if (apiResponseCopy.responseType && apiResponseCopy.responseType.includes('application/json')) {
    apiResponseCopy.responseMessage = await responseJSONCopy.json().then((result) => result);
  }
  if (apiResponseCopy.responseType && apiResponseCopy.responseType.includes('text/plain')) {
    apiResponseCopy.responseMessage = await response.text().then((result) => result);
  }
  return apiResponseCopy;
}
