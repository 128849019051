import React from 'react';
import ViewReportButton from '../SoilHealthPanel/ViewReportButton';
import DeleteReportButton from '../SoilHealthPanel/DeleteReportButton';
import Loader from '../Loader';
import '../../Styles/Common/ReportBody.css';

function ReportBody({
  reportList, isLoading, sectionTitle, deleteReportBody = false, showModalCallback,
}) {
  if (isLoading) {
    return (
      <Loader className="report__body__loader" />
    );
  }

  if (!reportList || reportList.length === 0) {
    return (
      <p>There are no reports for this section...</p>
    );
  }

  if (deleteReportBody) {
    return (
      <>
        <p>
          {reportList.map((report) => <DeleteReportButton key={report} reportName={report} sectionTitle={sectionTitle} showDeleteModalCallback={showModalCallback} />)}
        </p>
        <footer className="blockquote-footer">
          click the report to delete
        </footer>
      </>
    );
  }

  return (
    <>
      <div>
        {reportList.map((report) => <ViewReportButton key={report} reportName={report} sectionTitle={sectionTitle} />)}
      </div>
      <footer className="blockquote-footer">
        click the report to view
      </footer>
    </>
  );
}

export default ReportBody;
