import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { forEach } from 'lodash';
import { useFarmData } from '../Components/FarmData-Context';
import { reportTypeOptionItems } from '../Models/ReportTypeOptionItems';
import Options from '../Components/Common/Options';
import FarmList from '../Components/FarmList';
import FieldList from '../Components/FieldList';
import SelectFormGroupHelper from '../Components/Common/SelectFormGroupHelper';
import '../Styles/Modals/UploadSoilHealthReportModal.css';
import { httpStatusCodes } from '../Models/HttpStatusCodes';
import UserSearchFormGroup from '../Components/Common/UserSearchFormGroup';
import { FormatDate } from '../Util/FormatDate';
import { validateFile } from '../Util/ValidateFile';

function UploadSoilHealthReportModal() {
  const [showExpirationHelp, setShowExpirationHelp] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    Farms = [],
    UpdateFarmFieldData,
    CurrentUser,
    CurrentFarm,
    CurrentField,
    SetShowErrorModalState,
    ShowUploadSoilHealthReportModal,
    SetUploadSoilHealthReportModalState,
    SetSoilHealthReportsState,
    SetTriggerSoilHealthReportsUpdateState,
    SetDisableFieldsState,
    SetCurrentFieldState,
    SetCurrentFarmState,
    SetFarmMessageState,
    PostSoilHealthDocs,
  } = useFarmData();

  const handleFarmChange = ({ target: { value } }) => {
    SetSoilHealthReportsState([]);
    UpdateFarmFieldData(value, CurrentUser);
    SetCurrentFieldState('all fields');
    SetCurrentFarmState(value);
    SetTriggerSoilHealthReportsUpdateState(true);
    SetDisableFieldsState(true);
  };

  const handleFieldChange = ({ target: { value } }) => {
    SetSoilHealthReportsState([]);
    SetCurrentFieldState(value);
    SetTriggerSoilHealthReportsUpdateState(true);
    SetDisableFieldsState(true);
  };

  const handleExpirationDateChange = (date) => {
    const selectedDate = FormatDate({ date });

    setExpirationDate(date);
    setFormattedDate(selectedDate);
  };

  const handleReportTypeChange = ({ target: { value } }) => setSelectedReportType(value);

  const handleFileInputChange = (e) => {
    const { files } = e.target;

    forEach(files, (file) => {
      if (!validateFile({ file, fileType: 'application/pdf' })) {
        SetFarmMessageState("The files must all be PDF files and must not have a '.' in the file name.");
        SetShowErrorModalState(true);
        e.target.value = '';
      }
    });

    setSelectedFiles(files);
  };

  const handleSubmit = async () => {
    SetUploadSoilHealthReportModalState(false);
    const apiCalls = [];

    forEach(selectedFiles, (selectedFile) => {
      const reportName = selectedFile.name.split('.')[0];

      apiCalls.push(PostSoilHealthDocs({
        file: selectedFile,
        farm: CurrentFarm,
        reportType: selectedReportType,
        reportName,
        expirationDate: formattedDate,
        field: CurrentField,
        selectedUser: CurrentUser,
      }));
    });

    const response = await Promise.all(apiCalls);

    if (response.Status === httpStatusCodes.badRequest) {
      SetFarmMessageState(
        `The was an error uploading the files. ${response.responseMessage}`,
      );
      SetShowErrorModalState(true);
    }

    setSelectedFiles([]);
    setExpirationDate(null);
    setFormattedDate(null);
  };

  const handleClose = () => {
    setSelectedFiles([]);
    SetUploadSoilHealthReportModalState(false);
    setExpirationDate(null);
    setFormattedDate(null);
  };

  return (
    <Modal
      show={ShowUploadSoilHealthReportModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserSearchFormGroup />
          <SelectFormGroupHelper
            label="Farm"
            value={CurrentFarm}
            onChange={handleFarmChange}
            options={<FarmList />}
          />
          <SelectFormGroupHelper
            label="Field"
            value={CurrentField}
            onChange={handleFieldChange}
            options={<FieldList />}
          />
          <Form.Group>
            <Form.Label>Expiration Date <i id="optional__expiration__form__label">*optional</i></Form.Label>
            <i
              id="expiration__help__label"
              onClick={() => setShowExpirationHelp(!showExpirationHelp)}
            >
              (<u>what's this?</u>)
            </i><br />
            <Collapse in={showExpirationHelp}>
              <div id="expiration__help__text">
                <p>The expiration date will dictate when the report is relevant to be displayed in the portal. </p>
                <p><b>Example expiration dates:</b></p>
                <ul>
                  <li>
                    <i>
                      Soilvita / grid mapping - valid for 4 years from sampling so
                      expiration date = 4 years in the future from sample date.
                    </i>
                  </li>
                  <li>
                    <i>
                      Leaf tissue analysis - valid for duration of the crop - so expiration date = expected harvest
                      date of that crop
                    </i>
                  </li>
                  <li>
                    <i>Field variation report - always relevant for the field so leave expiration date blank</i>
                  </li>
                </ul>
              </div>
            </Collapse>
            <DatePicker
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              selected={expirationDate}
              onChange={handleExpirationDateChange}
            />
          </Form.Group>
          <SelectFormGroupHelper
            label="Report Type"
            value={selectedReportType}
            onChange={handleReportTypeChange}
            options={<Options items={reportTypeOptionItems} />}
          />
          <Form.Group controlId="pdfreportfiles" className="mb-3">
            <Form.Label>Report files</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileInputChange}
              multiple
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Submit</Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UploadSoilHealthReportModal;
