import React from 'react';
import { useSoilHealthContext } from '../Pages/PrecisionMapping';
import { useFarmData } from './FarmData-Context';

function SaveFieldBoundary() {
  const { fieldBoundaryDrawingManager, fieldPolygonGlobal } = useSoilHealthContext();
  const { UpdateField } = useFarmData();

  return (
    <div
      className="modal fade"
      id="save-field-boundary"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="savefieldboundarymodal"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">Confirm</div>
          <div className="modal-body">
            Are you sure you want to save the field boundary?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => {
                fieldBoundaryDrawingManager.setMap(null);
                fieldPolygonGlobal.setMap(null);
              }}
            >
              Cancel
            </button>
            <a
              className="btn btn-success btn-ok"
              data-dismiss="modal"
              onClick={(e) => {
                fieldBoundaryDrawingManager.setMap(null);

                const pathLength = fieldPolygonGlobal.getPath().getLength();

                const fieldBoundary = {
                  type: 'Polygon',
                  coordinates: [],
                };

                const coords = [];
                const fieldCoords = [];

                for (let i = 0; i < pathLength; i++) {
                  coords.push([
                    fieldPolygonGlobal.getPath().getAt(i).lng(),
                    fieldPolygonGlobal.getPath().getAt(i).lat(),
                  ]);

                  fieldCoords.push({
                    lat: fieldPolygonGlobal.getPath().getAt(i).lat(),
                    lng: fieldPolygonGlobal.getPath().getAt(i).lng(),
                  });
                }

                fieldBoundary.coordinates[0] = coords;

                const field = {
                  Field_ID: document.getElementById('field-select').value,
                  Boundary_GeoJson: JSON.stringify(fieldBoundary),
                };

                fieldPolygonGlobal.setMap(null);
                UpdateField(field);
              }}
            >
              Save
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveFieldBoundary;
